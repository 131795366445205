import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Inject, Input, NgZone, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CommonFileService } from '../../services/file.service';
import { CommonAuthenticationService } from '../../services/auth.service';
import { BlockingType, RegisterEditField } from 'common-lib';
import { SmartImageFrontend } from '../../classes';
import { CommonModule } from '@angular/common';

// Define component with selector, template, styles, and providers
@Component({
    selector: 'image-carousel',
    templateUrl: 'image-carousel.component.html',
    styleUrls: ['image-carousel.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImageCarouselComponent),
            multi: true,
        },
    ],
    host: { '[class.supportRemarks]': 'supportRemarks' },
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule],
})
export class ImageCarouselComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
    // Define input properties for the component
    @Input() public isUploadable: boolean = false;
    @Input() public isDeletable: boolean = false;
    @Input() public isRotateble: boolean = false;
    @Input() public supportRemarks: boolean = false;
    @Input() public supportZoom: boolean = false;
    @Input() public formControlName: string;

    // Define output event emitter for delete event
    @Output() delete: EventEmitter<string> = new EventEmitter<string>();

    // Declare private properties for the component
    public value: SmartImageFrontend[] = [];
    public activeImage: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
    private onChange = (_: any) => {};
    private onTouched = () => {};
    public disabled: boolean = false;
    public fullScreen: boolean = false;
    public zoomModeEnabled: boolean = false;
    public isBusy: boolean = false;
    public imageRemarks = new FormControl({ value: '', disabled: this.supportRemarks });
    @ViewChild('zoomWindow', { read: ElementRef, static: false }) private zoomWindow: ElementRef<HTMLDivElement>;
    @ViewChild('zoomedImage', { read: ElementRef, static: false }) private zoomedImage: ElementRef<HTMLDivElement>;
    @ViewChild('target', { read: ElementRef, static: true }) private target: ElementRef<HTMLDivElement>;
    @ViewChild('slide', { read: ElementRef, static: true }) public slide: ElementRef<HTMLDivElement>;
    private unlisten: Function;
    private subscriptions: Subscription[] = [];
    private isSizeMeasured: boolean = false;

    // Define constructor with necessary services
    constructor(
        @Inject('FileService') public fileService: CommonFileService,
        @Inject('AuthenticationService') public authService: CommonAuthenticationService,
        private renderer: Renderer2,
        private ngZone: NgZone
    ) {}
    ngAfterViewInit(): void {
        // Run outside Angular to improve performance
        this.ngZone.runOutsideAngular(() => {
            this.unlisten = this.renderer.listen(this.slide.nativeElement, 'mousemove', (e) => this.zoomMoveHandler(e));
        });
    }

    // ngOnInit lifecycle hook
    ngOnInit(): void {
        // Subscribe to delete event and set activeImage to null
        this.subscriptions.push(this.delete.subscribe(() => this.activeImage.next(null)));

        // Subscribe to activeImage changes and set imageRemarks value
        this.subscriptions.push(
            this.activeImage.subscribe((index) => {
                if (Number.isInteger(index)) {
                    this.imageRemarks.setValue(this.value[index as number].remark.value as string, { emitEvent: false });
                    this.isSizeMeasured = false;
                }
            })
        ); // Subscribe to imageRemarks value changes and update the remark
        this.subscriptions.push(
            this.imageRemarks.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value: string | null) => {
                if (value) {
                    this.value[this.activeImage.value as number].remark.registerEdit(value as string, this.authService.currentUser.getModel(true));
                    this.onTouched();
                }
                this.onChange(this.value);
            })
        );
    }

    // ngOnDestroy lifecycle hook
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this.subscriptions.forEach((s) => s.unsubscribe());

        // Remove the event listener
        this.unlisten();
    }

    // Implement ControlValueAccessor methods
    async writeValue(data: any): Promise<void> {
        // Handle null data
        if (!data) {
            this.activeImage.next(null);
            return;
        }

        if (data.value) {
            // Ensure data is an array
            if (!Array.isArray(data.value)) data = [data.value];
            else data = data.value;
        }

        // Ensure data is an array
        if (!Array.isArray(data)) data = [data];

        // Download images
        await Promise.all(data.map((i: SmartImageFrontend) => i.download(this.fileService)));

        // Set value and update activeImage
        this.value = data;
        if (this.value.length === 0) {
            this.activeImage.next(null);
            return;
        }
        if (this.activeImage.value === null) {
            this.activeImage.next(0);
            return;
        }
        if (this.activeImage.value > this.value.length - 1) {
            this.activeImage.next(this.value.length - 1);
            return;
        }
    }

    registerOnChange(fn: (_: any) => {}): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.disabled ? this.imageRemarks.disable() : this.imageRemarks.enable();
    }

    // Private methods for component logic
    public addImage(event: any): void {
        // Set isBusy and check if the component supports uploading
        this.isBusy = true;
        if (!this.isUploadable) return;

        const file = event.target.files[0];

        // Read the uploaded file and create a new SmartImageFrontend instance
        const reader = new FileReader();
        reader.onload = async () => {
            let url: string = '';

            // Check if the uploaded file is a PDF
            if (file.type === 'application/pdf') {
                url = `url(${(await firstValueFrom(this.fileService.convertPdfToImg(reader.result as string))).result})`;
            } else if (file.type.startsWith('image/')) {
                url = `url(${reader.result})`;
            } else {
                return;
            }

            const smartImage: SmartImageFrontend = new SmartImageFrontend();
            smartImage.model = {
                imageID: '',
                imageUrl: url,
                mimeType: file.type,
                remark: new RegisterEditField(BlockingType.AFTER_DAY_WITH_COMMIT, this.formControlName, ''),
            };

            // Update value, call onChange, set activeImage, and reset isBusy
            this.value.push(smartImage);
            this.onTouched();
            this.onChange(this.value);
            this.activeImage.next(this.value.length - 1);
            this.isBusy = false;
        };
        reader.readAsDataURL(event.target.files[0]);
    }

    public nextImage(): void {
        // Update activeImage if not the last image
        if (this.activeImage.value === this.value.length - 1) return;
        this.isSizeMeasured = false;
        this.onTouched();
        this.activeImage.next((this.activeImage.value as number) + 1);
    }

    public prevImage(): void {
        // Update activeImage if not the first image
        if (this.activeImage.value === 0) return;
        this.isSizeMeasured = false;
        this.onTouched();
        this.activeImage.next((this.activeImage.value as number) - 1);
    }

    public async rotate(smartImage: SmartImageFrontend): Promise<void> {
        // Rotate image and call onChange
        this.isBusy = true; // Rotate image and call onChange
        await smartImage.rotate90deg();
        this.onChange(this.value);
        this.isBusy = false;
    }

    private zoomMoveHandler(e: any): void {
        // Check if the component supports zooming
        if (!this.supportZoom) return;

        // Measure the size of the zoom window if not already measured
        if (!this.isSizeMeasured) {
            this.isSizeMeasured = true;
            this.renderer.setStyle(this.zoomWindow.nativeElement, 'width', (this.slide.nativeElement.clientWidth / 3) * 8 + 'px');
            this.renderer.setStyle(this.zoomWindow.nativeElement, 'height', (this.slide.nativeElement.clientHeight / 5) * 8 + 'px');
        }

        // Calculate the position of the target and the zoomed image
        const targetWidth: number = this.target.nativeElement.clientWidth + 2,
            targetHeight: number = this.target.nativeElement.clientHeight + 2,
            slideWidth: number = this.slide.nativeElement.clientWidth,
            slideHeight: number = this.slide.nativeElement.clientHeight;
        let layerX: number, layerY: number;

        if (e.srcElement.className.includes('target')) {
            const bounds = this.slide.nativeElement.getBoundingClientRect();
            layerX = e.clientX - bounds.left;
            layerY = e.clientY - bounds.top;
            if (layerX > slideWidth || layerY > slideHeight) {
                this.ngZone.run(() => {
                    this.zoomModeEnabled = false;
                });
                return;
            }
        } else if (e.srcElement.className.includes('slide')) {
            layerX = e.layerX;
            layerY = e.layerY;
        } else {
            return;
        }

        // Update the position of the target and the zoomed image
        let targetLeft: number = layerX - targetWidth / 2,
            targetTop: number = layerY - targetHeight / 2;
        targetLeft = targetLeft < 0 ? 0 : slideWidth < targetLeft + targetWidth ? slideWidth - targetWidth : targetLeft;
        targetTop = targetTop < 0 ? 0 : slideHeight < targetTop + targetHeight ? slideHeight - targetHeight : targetTop;

        this.renderer.setStyle(this.target.nativeElement, 'left', targetLeft + 'px');
        this.renderer.setStyle(this.target.nativeElement, 'top', targetTop + 'px');

        const leftPers: number = (targetLeft * 100) / targetWidth,
            topPers: number = (targetTop * 100) / targetHeight;

        this.renderer.setStyle(this.zoomedImage.nativeElement, 'left', '-' + (this.zoomWindow.nativeElement.clientWidth * leftPers) / 100 + 'px');
        this.renderer.setStyle(this.zoomedImage.nativeElement, 'top', '-' + (this.zoomWindow.nativeElement.clientHeight * topPers) / 100 + 'px');
    }
}
