import { Observable, Subject } from 'rxjs';
import { IOSocket } from './ioSocket';
import { Manager } from 'socket.io-client';
import { checkSocketObservable, checkSocketPromise } from '../decorators/checkSocket.decorator';

export class UnsecuredSocket extends IOSocket {
    private readonly chars: string;
    private readonly lookup: Uint8Array;
    constructor(manager: Manager, socketError: Subject<Error>) {
        super(manager, '/unsecured', socketError);
        this.chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
        this.lookup = new Uint8Array(256);
        for (let i = 0; i < this.chars.length; i++) {
            this.lookup[this.chars.charCodeAt(i)] = i;
        }
    }
    public async addHendlers(resolvable: () => void): Promise<void> {}
    @checkSocketPromise()
    public async registerKey(idORtoken: string): Promise<void> {
        const publicKey: any = await new Promise<any>((res) => {
            this._socket.emit('register-key', idORtoken, (publicKey: any) => {
                res(publicKey);
            });
        });
        publicKey.challenge = this.decode(publicKey.challenge);
        publicKey.user.id = this.decode(publicKey.user.id);
        const credential = await navigator.credentials.create({ publicKey });
        const credentialResponce = this.publicKeyCredentialToJSON(credential);
        await this.sendWebAuthResponse(credentialResponce);
    }
    public publicKeyCredentialToJSON(pubKeyCred: any): any {
        if (pubKeyCred instanceof Array) {
            let arr = [];
            for (let i of pubKeyCred) arr.push(this.publicKeyCredentialToJSON(i));
            return arr;
        }
        if (pubKeyCred instanceof ArrayBuffer) {
            return this.encode(pubKeyCred as any);
        }
        if (pubKeyCred instanceof Object) {
            let obj: {
                [key: string]: string;
            } = {};
            for (let key in pubKeyCred) {
                obj[key] = this.publicKeyCredentialToJSON(pubKeyCred[key]);
            }
            return obj;
        }
        return pubKeyCred;
    }
    public encode(arraybuffer: ArrayBuffer): string {
        let bytes: Uint8Array = new Uint8Array(arraybuffer),
            i: number,
            len: number = bytes.length,
            base64url: string = '';

        for (i = 0; i < len; i += 3) {
            base64url += this.chars[bytes[i] >> 2];
            base64url += this.chars[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
            base64url += this.chars[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
            base64url += this.chars[bytes[i + 2] & 63];
        }

        if (len % 3 === 2) {
            base64url = base64url.substring(0, base64url.length - 1);
        } else if (len % 3 === 1) {
            base64url = base64url.substring(0, base64url.length - 2);
        }

        return base64url;
    }
    public decode(base64string: string): ArrayBuffer {
        let bufferLength: number = base64string.length * 0.75,
            len: number = base64string.length,
            i: number,
            p: number = 0,
            encoded1,
            encoded2,
            encoded3,
            encoded4;

        let bytes = new Uint8Array(bufferLength);

        for (i = 0; i < len; i += 4) {
            encoded1 = this.lookup[base64string.charCodeAt(i)];
            encoded2 = this.lookup[base64string.charCodeAt(i + 1)];
            encoded3 = this.lookup[base64string.charCodeAt(i + 2)];
            encoded4 = this.lookup[base64string.charCodeAt(i + 3)];

            bytes[p++] = (encoded1 << 2) | (encoded2 >> 4);
            bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
            bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63);
        }

        return bytes.buffer;
    }
    @checkSocketPromise()
    public sendWebAuthResponse(encodedpublicKeyCredential: any): Promise<string> {
        return new Promise<string>((res) => {
            this._socket.emit('web-auth-response', encodedpublicKeyCredential, (token: string) => {
                res(token);
            });
        });
    }
    @checkSocketPromise()
    public async loginWithU2F(username: string, password: string): Promise<PublicKeyCredentialRequestOptions> {
        return new Promise<PublicKeyCredentialRequestOptions>((res) => {
            this._socket.emit('login-with-u2f', username, password, res);
        });
    }
    @checkSocketPromise()
    public async checkSmsVerificationCode(username: string, code: string): Promise<string> {
        return new Promise<string>((res) => {
            this._socket.emit('verify-sms-code', username, code, res);
        });
    }
    @checkSocketPromise()
    public async loginWithSms(username: string, password: string): Promise<any> {
        return new Promise<any>((res) => {
            this._socket.emit('login-with-sms', username, password, res);
        });
    }
    @checkSocketPromise()
    public async sendInitialInvitation(): Promise<any> {
        return new Promise<any>((res) => {
            this._socket.emit('invite-admin', res);
        });
    }
    @checkSocketObservable()
    public isUserExists(username: string): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            this._socket.emit('is-user-exists', username, (exists: boolean) => {
                observer.next(exists);
                observer.complete();
            });
        });
    }
    @checkSocketPromise()
    public async sendSelfInitialInvitation(email: string): Promise<any> {
        return new Promise<any>((res) => {
            this._socket.emit('invite-self', email, res);
        });
    }
    @checkSocketPromise()
    public async restorePassword(username: string): Promise<any> {
        return new Promise<any>((res) => {
            this._socket.emit('restore-password', username, res);
        });
    }
    @checkSocketPromise()
    public async isRestoreTokenValid(token: string): Promise<any> {
        return new Promise<any>((res) => {
            this._socket.emit('is-restore-token-valid', token, res);
        });
    }
    @checkSocketPromise()
    public async updatePassword(restoreToken: string, newPassword: string): Promise<string> {
        return new Promise<string>((res) => {
            this._socket.emit('update-password', restoreToken, newPassword, res);
        });
    }
}
