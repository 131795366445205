import { Subject } from 'rxjs';
import { ROLE } from 'common-lib';
import { IOSocket } from './ioSocket';
import { Manager } from 'socket.io-client';
import { checkSocketPromise } from '../decorators/checkSocket.decorator';

export class AuthSocket extends IOSocket {
    constructor(manager: Manager, socketError: Subject<Error>) {
        super(manager, '/auth', socketError);
    }
    public async addHendlers(resolvable: () => void): Promise<void> {}
    @checkSocketPromise()
    public async logout(): Promise<void> {
        this._socket.emit('logout');
    }
    @checkSocketPromise()
    public async inviteUser(target: { email: string; role: ROLE }): Promise<void> {
        return new Promise<void>((res) => {
            this._socket.emit('inviteUser', target, res);
        });
    }
    @checkSocketPromise()
    public async findInvitationCredentialsByToken(): Promise<{ email: string; role: ROLE }> {
        return new Promise<{ email: string; role: ROLE }>((res) => {
            this._socket.emit('get-credentials', (credentials: { email: string; role: ROLE }) => {
                res(credentials);
            });
        });
    }
    @checkSocketPromise()
    public async getKeysCount(): Promise<number> {
        return new Promise<number>((res) => {
            this._socket.emit('get-keys-count', (count: number) => {
                res(count);
            });
        });
    }
}
