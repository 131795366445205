import { BlockingType } from '../enum/blockingType.enum';
import { IRegisterEditField } from '../interfaces/register-edit-field.interface';
import { Edit } from './edit.model';
import { IUser } from '../interfaces/user';
/**
 * A class for describing object that holds any data in DB with tracking changes
 * All argumants in constructor are optional on order to let user create an empty inctance of this class
 */
export class RegisterEditField<T> implements IRegisterEditField<T> {
    type: BlockingType | undefined; //according to requirements data could be changed later with 2 options: only before 24 hours after creation or anytime
    name: string; // name of data (what is stored)
    value: T | undefined; // data itself
    lastModified: number | undefined; // when data was modified last time
    edits: Edit<T>[]; // an array of objects that holds all data modifications
    constructor(type?: BlockingType, name: string = '', initialValue?: T, lastModified?: number) {
        this.type = type;
        this.name = name;
        this.value = initialValue;
        this.lastModified = lastModified;
        this.edits = [];
    }
    public getModel(): IRegisterEditField<T> {
        let model: IRegisterEditField<T> = {
            type: this.type,
            name: this.name,
            value: this.value,
            lastModified: this.lastModified,
            edits: this.edits,
        };
        return model;
    }
    public setModel(model: IRegisterEditField<T>) {
        this.type = model.type;
        this.name = model.name;
        this.value = model.value;
        this.lastModified = model.lastModified;
        this.edits = model.edits.map((m) => new Edit<T>(m));
        return this;
    }
    // method that returns if there is some data is presented in this exact instance
    public isEmpty(): boolean {
        if (Array.isArray(this.value)) {
            // if value is array than it is empty if array length is 0
            return this.value.length === 0;
        } else if (typeof this.value === 'number') {
            // if value is number than it is not empty since any number could not be default value
            return false;
        } else {
            // in any other cases value means that this class is not empty
            return !this.value;
        }
    }
    public registerEdit(newValue: T, editor: IUser<any>): RegisterEditField<T> {
        if (!this.isEqual(this.value, newValue)) {
            const edit: Edit<T> = new Edit<T>({
                date: Date.now(),
                editorId: editor._id as string,
                editor: editor.roleProperties.firstName + ' ' + editor.roleProperties.lastName,
                fieldName: this.name as string,
                previousValue: this.value as T,
                currentValue: newValue,
            });
            this.edits.push(edit);
            this.value = newValue;
            this.lastModified = Date.now();
        }
        return this;
    }
    private isEqual(x: any, y: any): boolean {
        const type1 = typeof x,
            type2 = typeof y;
        if (type1 !== type2) {
            return false;
        } else if (x === null || y === null) {
            return x === y;
        } else if (type1 === 'object') {
            return this.compareObjects(x, y);
        } else {
            return x === y;
        }
    }
    private compareObjects(obj1: any, obj2: any): boolean {
        // Loop through properties in object 1
        for (const p in obj1) {
            if (obj2) {
                // Check property exists on both objects
                if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {
                    return false;
                }

                switch (typeof obj1[p]) {
                    // Deep compare objects
                    case 'object':
                        if (!this.compareObjects(obj1[p], obj2[p])) {
                            return false;
                        }
                        break;
                    // Compare function code
                    case 'function':
                        if (typeof obj2[p] == 'undefined' || (p != 'compare' && obj1[p].toString() != obj2[p].toString())) {
                            return false;
                        }
                        break;
                    // Compare values
                    default:
                        if (obj1[p] != obj2[p]) {
                            return false;
                        }
                }
            }
        }

        // Check object 2 for any extra properties
        for (const p in obj2) {
            if (obj1 && typeof obj1[p] == 'undefined') {
                return false;
            }
        }
        return true;
    }
}
