import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ExaminationRepresentativeFrontend } from 'marketplace/frontend/src/app/classes/examinationRepresentativeFrontend';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';
import { TestClassProvider, ROLE, SORTING, IExaminationRepresentative, IBulbiPointRoleProperties, ICustomerRoleProperties, IUser } from 'common-lib';
import { IExaminationsSearchTerms } from '../../../../../common/interfaces/examinationsSearchTerms';
import { PaginatorComponent } from '../paginator/paginator.component';

@Component({
    selector: 'representatives',
    templateUrl: 'representatives.component.html',
    styleUrls: ['representatives.component.scss'],
})
export class RepresentativesComponent implements OnInit, OnDestroy {
    public _examinations: Subject<ExaminationRepresentativeFrontend[]>;
    private _subscriptions: Array<Subscription>;
    public _searchQuery: BehaviorSubject<IExaminationsSearchTerms>;
    private _customers: BehaviorSubject<IUser<ICustomerRoleProperties>[]>;
    private _HUBs: BehaviorSubject<IUser<IBulbiPointRoleProperties>[]>;
    public _HUBsOnline: string[] = [];
    public SORTING: typeof SORTING = SORTING;
    public customersSelect: FormControl;
    public hubsSelect: FormControl;
    @ViewChild(PaginatorComponent) public paginatorComponent: PaginatorComponent;
    constructor(public communicationService: ServerCommunicationService, private testClassProvider: TestClassProvider) {
        this._examinations = new Subject<ExaminationRepresentativeFrontend[]>();
        this._subscriptions = [];
        this._searchQuery = new BehaviorSubject<IExaminationsSearchTerms>({
            itemsPerPage: this.communicationService.itemsPerPage,
            page: 1,
            orderBy: 'createdAt',
            orderDirection: SORTING.DESC,
            customerID: undefined,
            sourceID: undefined,
            createdAt: {
                startRange: null,
                endRange: null,
            },
        });
        this._customers = new BehaviorSubject<IUser<ICustomerRoleProperties>[]>([]);
        this._HUBs = new BehaviorSubject<IUser<IBulbiPointRoleProperties>[]>([]);
        this.customersSelect = new FormControl([]);
        this.hubsSelect = new FormControl([]);
    }
    async ngOnInit(): Promise<void> {
        this._customers.next(await this.communicationService.sockets.UserSocket.getUsers(ROLE.CUSTOMER));
        this._HUBs.next(await this.communicationService.sockets.UserSocket.getUsers(ROLE.REMOTEPOINT));
        this._subscriptions.push(
            this.communicationService.sockets.RemotePointSocket.pointsOnline.subscribe((data) => {
                this._HUBsOnline = data;
            })
        );
        this._subscriptions.push(
            this._searchQuery.subscribe(() => {
                this.paginatorComponent.page.next(1);
            })
        );
        this._subscriptions.push(
            this.paginatorComponent.page
                .pipe(
                    switchMap((page) => {
                        const terms: IExaminationsSearchTerms = this._searchQuery.value;
                        terms.page = page;
                        return this.communicationService.sockets.ExaminationSocket.search(terms);
                    }),
                    map((searchResult: { filteredExams: IExaminationRepresentative[]; totalCnt: number }) => {
                        this.paginatorComponent.totalItemsCnt = searchResult.totalCnt;
                        const exams: ExaminationRepresentativeFrontend[] = searchResult.filteredExams.map((model) => {
                            const exam: ExaminationRepresentativeFrontend = new ExaminationRepresentativeFrontend();
                            exam.model = model;
                            const customer: IUser<ICustomerRoleProperties> = this._customers.value.find((c) => c._id === exam.customerID)!;
                            exam.customerName = customer.roleProperties.firstName + ' ' + customer.roleProperties.lastName;
                            const source: IUser<IBulbiPointRoleProperties> = this._HUBs.value.find((m) => m._id === exam.sourceID)!;
                            exam.sourceName = source.username;
                            return exam;
                        });
                        return exams;
                    })
                )
                .subscribe((filteredExams: ExaminationRepresentativeFrontend[]) => {
                    this._examinations.next(filteredExams);
                })
        );
        this._subscriptions.push(
            this.customersSelect.valueChanges.subscribe((v) => {
                const searchQuery: IExaminationsSearchTerms = this._searchQuery.value;
                searchQuery.customerID = this._customers.value.find((m) => m.roleProperties.firstName + ' ' + m.roleProperties.lastName === v)?._id as string;
                this._searchQuery.next(searchQuery);
            })
        );
        this._subscriptions.push(
            this.hubsSelect.valueChanges.subscribe((v) => {
                const searchQuery: IExaminationsSearchTerms = this._searchQuery.value;
                searchQuery.sourceID = this._HUBs.value.find((m) => m.username === v)?._id as string;
                this._searchQuery.next(searchQuery);
            })
        );
        this._subscriptions.push(
            this.communicationService.sockets.ExaminationSocket.updateExaminationsRequestSource.subscribe(() => {
                this.paginatorComponent.page.next(this.paginatorComponent.page.value);
            })
        );
    }
    ngOnDestroy(): void {
        this._subscriptions.forEach((s) => s.unsubscribe());
    }
    public checkOverflow(element: any): boolean {
        if (element.offsetWidth < element.scrollWidth) {
            return true;
        } else {
            return false;
        }
    }
    public orderBy(columnName: string): void {
        const searchQuery: IExaminationsSearchTerms = this._searchQuery.value;
        searchQuery.orderBy = columnName;
        searchQuery.orderDirection = searchQuery.orderDirection === SORTING.DESC ? SORTING.ASC : SORTING.DESC;
        this._searchQuery.next(searchQuery);
    }
    public get customersList(): string[] {
        return [''].concat(this._customers.value.map((m) => m.roleProperties.firstName + ' ' + m.roleProperties.lastName));
    }
    public get hubsList(): string[] {
        return [''].concat(this._HUBs.value.map((m) => m.username));
    }
    public converter(exam: ExaminationRepresentativeFrontend, istooltip: boolean): string {
        return exam.completedTests
            .map((t, i) => {
                const name = this.testClassProvider.testsProperties.find((testclass) => testclass.device === t.device && testclass.type === t.type)?.name;
                if (istooltip) return `${name || t.type} (${t.count})` + (i % 3 === 1 ? '\n\r' : '\t\t');
                else return `${name || t.type} (${t.count}), `;
            })
            .sort()
            .join('');
    }
}
