import { Subject } from 'rxjs';
import { HTTP_STATUS, ROLE, IBulbiPointRoleProperties, ISocketResponce, IUser } from 'common-lib';
import { IOSocket } from './ioSocket';
import { Manager } from 'socket.io-client';
import { checkSocketPromise } from '../decorators/checkSocket.decorator';

export class UserSocket extends IOSocket {
    constructor(manager: Manager, socketError: Subject<Error>) {
        super(manager, '/user', socketError);
    }
    public async addHendlers(resolvable: () => void): Promise<void> {}
    @checkSocketPromise()
    public findByID<T>(id: string): Promise<IUser<T>> {
        return new Promise<IUser<T>>((res) => {
            this._socket.emit('findOne', id, (responce: IUser<T>) => {
                res(responce);
            });
        });
    }
    @checkSocketPromise()
    public update<T>(model: IUser<T>): Promise<IUser<T>> {
        return new Promise<IUser<T>>((res) => {
            this._socket.emit('updateOne', model, (responce: ISocketResponce) => {
                if (responce.status === HTTP_STATUS.SUCCESS) {
                    res(responce.data);
                }
            });
        });
    }
    @checkSocketPromise()
    public create<T>(model: IUser<T>): Promise<IUser<T>> {
        return new Promise<IUser<T>>((res) => {
            this._socket.emit('create', model, (responce: ISocketResponce) => {
                if (responce.status === HTTP_STATUS.SUCCESS) {
                    res(responce.data);
                }
            });
        });
    }
    @checkSocketPromise()
    public getUsers<T>(role: ROLE): Promise<IUser<T>[]> {
        return new Promise<IUser<T>[]>((res) => {
            this._socket.emit('users', role, (responce: IUser<T>[]) => {
                res(responce);
            });
        });
    }
    @checkSocketPromise()
    public getPoints(customerID: string): Promise<IUser<IBulbiPointRoleProperties>[]> {
        return new Promise<IUser<IBulbiPointRoleProperties>[]>((res) => {
            this._socket.emit('getPoints', customerID, (responce: IUser<IBulbiPointRoleProperties>[]) => {
                res(responce);
            });
        });
    }
    @checkSocketPromise()
    public getPointVersion(pointID: string): Promise<string> {
        return new Promise<string>((res) => {
            this._socket.emit('getPointVersion', pointID, res);
        });
    }
}
