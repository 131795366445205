import { DEVICE } from '../../../enum/device';
import { TEST_TYPE } from '../../../enum/test-type';
import { TEST_STAGE } from '../../../enum/testStage.enum';

export class SaccadeBulbicamTest {
    public readonly type: TEST_TYPE = TEST_TYPE.SACCADES;
    public readonly device: DEVICE = DEVICE.HAPLO;
    public readonly name: string = 'Saccade Test (VF)';
    public stage: TEST_STAGE;
}
