import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CalibrationTestComponent } from '../calibration-test-component';
import { CALIBRATION_COMMAND, CALIBRATION_TEST, ICalibrationResult, IPatternCalibration } from 'common-lib';
import { PatternCalibrationFrontend } from '../../../../classes/calibration-tests/pattern.model';
import { CommonBulbicamService } from '../../../../services/bulbiCam.service';
import { CommonSocketService } from '../../../../services/socket.service';
import { CommonConfigService } from '../../../../services/config.service';
import { CommonFileService } from '../../../../services/file.service';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ImageCarouselComponent } from '../../../image-carousel/image-carousel.component';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';

@Component({
    selector: 'pattern-test',
    templateUrl: 'pattern-test.component.html',
    styleUrls: ['./pattern-test.component.scss', '../reusable-styles.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, ImageCarouselComponent, ReactiveFormsModule],
})
export class PatternTestComponent extends CalibrationTestComponent implements OnInit {
    results: (ICalibrationResult<PatternCalibrationFrontend> & { isTemp?: boolean })[] = [];
    selectedIndex = -1;
    isTestRunning = false;
    isSavingResults = false;
    image: FormControl;

    constructor(
        @Inject('BulbicamService') private bulbicamService: CommonBulbicamService,
        @Inject('SocketService') private socketService: CommonSocketService,
        @Inject('ConfigService') public configService: CommonConfigService,
        @Inject('FileService') public fileService: CommonFileService,
        private route: ActivatedRoute
    ) {
        super(CALIBRATION_TEST.PATTERN_TEST);
        this.image = new FormControl();
    }

    ngOnInit(): void {
        const resolvedData: {
            calibrationResults: ICalibrationResult<IPatternCalibration>[];
        } = this.route.snapshot.data['calibrationResults'];
        this.handleCalibrationTestsResults(resolvedData.calibrationResults);
    }

    get isUnsavedResultExists(): boolean {
        return Boolean(this.results.find((r) => r.isTemp));
    }

    async messageHandler(data: any): Promise<void> {
        if (data.message_type === CALIBRATION_COMMAND.DATA_PACKAGE) {
            const patternCalibrationResult = new PatternCalibrationFrontend(this.fileService, this.bulbicamService);
            patternCalibrationResult.setModel({
                patternImage: data.smartImage,
                osAngle: data.OS_angle,
                osCenterX: data.OS_center_x,
                osCenterY: data.OS_center_y,
                odAngle: data.OD_angle,
                odCenterX: data.OD_center_x,
                odCenterY: data.OD_center_y,
            });

            this.results.unshift({
                test: this.calibrationType,
                createdAt: +new Date(),
                results: patternCalibrationResult,
                isTemp: true,
            });

            this.image.setValue(this.results[0]?.results?.patternImage);
        }

        if (data.message_type === CALIBRATION_COMMAND.STOP) {
            this.isTestRunning = false;
            this.selectedIndex = 0;
            this.socketService.socket?.off(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);
        }
    }

    private handleCalibrationTestsResults(calibrationResults: ICalibrationResult<IPatternCalibration>[]): void {
        this.results = calibrationResults
            .map((result) => {
                const patternCalibrationResult = new PatternCalibrationFrontend(this.fileService, this.bulbicamService);
                patternCalibrationResult.setModel(result.results!);
                result.results = patternCalibrationResult;
                return result as ICalibrationResult<PatternCalibrationFrontend>;
            })
            .sort((a, b) => b.createdAt - a.createdAt);
    }

    async deleteInputResults() {
        await firstValueFrom(this.bulbicamService.deleteCalibrationResult(this.results[this.selectedIndex].test, this.results[this.selectedIndex].createdAt));

        const calibrationResults = await firstValueFrom(this.bulbicamService.getCalibrationResults<IPatternCalibration>([this.calibrationType]));
        this.handleCalibrationTestsResults(calibrationResults);

        if (this.results[this.selectedIndex]) {
            this.selectActive(this.selectedIndex);
        } else if (this.results[this.selectedIndex - 1]) {
            this.selectActive(this.selectedIndex - 1);
        } else {
            this.selectActive(-1);
            this.image.setValue(null);
        }
    }

    toggleTest(): void {
        if (this.isTestRunning) {
            firstValueFrom(
                this.bulbicamService.sendCalibrationCommand({
                    test: this.calibrationType,
                    command: CALIBRATION_COMMAND.STOP,
                })
            );
        } else {
            if (this.isUnsavedResultExists) this.results.shift();
            this.isTestRunning = true;
            this.socketService.socket?.on(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);
            firstValueFrom(
                this.bulbicamService.sendCalibrationCommand({
                    test: this.calibrationType,
                    command: CALIBRATION_COMMAND.START,
                })
            );
        }
    }

    override async saveResults(): Promise<void> {
        this.isSavingResults = true;
        try {
            await this.results[0]!.results!.saveModel();
            this.isSavingResults = false;
            this.results[0].isTemp = false;
        } catch (e) {
            console.error(e);
            this.isSavingResults = false;
            return;
        }

        try {
            const allTests = await firstValueFrom(this.bulbicamService.getCalibrationResults<IPatternCalibration>([this.calibrationType]));
            this.handleCalibrationTestsResults(allTests);
        } catch (e) {
            console.error(e);
            return;
        }
    }

    public async selectActive(index: number): Promise<void> {
        this.selectedIndex = index;
        if (index < 0) return;
        await this.results[index].results!.patternImage.download(this.fileService);
        this.image.setValue(this.results[index].results!.patternImage);
    }

    customOnDestroy(): void {}
}
