import { concatMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CALIBRATION_TEST, IGridFsFileMetadata, ILensDistortionCalibration, IPixelsPerMillimeterResult, LensDistortionCalibration } from 'common-lib';
import { CommonBulbicamService, CommonFileService } from '../../services';
import { SmartImageFrontend } from '../smartImageFrontend.class';

export class LensDistortionCalibrationFrontend extends LensDistortionCalibration {
    public override lensImage: SmartImageFrontend;
    public override pixelsPerMillimeterResult: IPixelsPerMillimeterResult[];

    constructor(private fileService: CommonFileService, private bulbicamService: CommonBulbicamService) {
        super();
        this.lensImage = new SmartImageFrontend();
    }

    public async saveModel(): Promise<Observable<{ test: CALIBRATION_TEST; createdAt: number }[]>> {
        const metadata: IGridFsFileMetadata = {
            patientID: '',
            examinationID: '',
            testType: this.type,
            mimeType: 'text/plain',
            compressed: false,
        };
        await this.lensImage.saveToDb(metadata, this.fileService);

        return this.bulbicamService
            .saveCalibrationResult({
                test: this.type,
                createdAt: +new Date(),
                results: this.getModel(),
            })
            .pipe(
                concatMap((_) => {
                    return this.bulbicamService.getCalibrationResults<ILensDistortionCalibration>([this.type]);
                })
            );
    }
}
