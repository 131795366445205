<div class="test-container scrollable">
    <div class="test-header">{{ name }}</div>
    <div class="test-main">
        <div class="test-main-calibrate">
            <div class="test-main-header">Calibrate</div>
            <div class="test-main-calibrate-content">
                <button
                    *ngFor="let button of buttons"
                    [ngClass]="{ 'active-button': button.isActive, 'disabled-button': button.disabled }"
                    class="test-main-calibrate-content-button"
                    [disabled]="!configService.camState || button.disabled"
                    (click)="selectButton(button.id)"
                >
                    {{ button.text }}
                </button>
                <button *ngIf="isButtonSelected" class="test-main-calibrate-content-button-stop" (click)="stopTest()">Stop test</button>
            </div>
        </div>
        <div class="test-main-history">
            <div class="test-main-header">Calibration History</div>
            <div class="test-main-history-content">
                <div
                    class="test-main-history-content-row"
                    *ngFor="let row of infoList; let ind = index"
                    [ngClass]="{ 'odd-row': ind % 2, selected: ind === selectedIndex }"
                    (click)="selectActive(ind)"
                >
                    <div>{{ row.createdAt | date : 'yyyy.MM.dd hh:mm' }}</div>
                    <div>{{ row?.results?.distance }}m</div>
                </div>
            </div>
        </div>
    </div>

    <div class="screen-test-buttons">
        <div class="screen-test-buttons-action">
            <button type="button" class="normalButton primaryButton" [disabled]="!isButtonSelected" (click)="saveResults()">Save Calibration Date</button>
            <button type="button" class="normalButton primaryButton" [disabled]="(!selectedIndex && selectedIndex !== 0) || selectedIndex < 0" (click)="deleteInputResults()">
                Delete
            </button>
        </div>
    </div>
    <div class="test-buttons">
        <span></span>
        <button type="button" class="normalButton secondaryButton" (click)="configService.cancelReturnFromCalibrationTest()">{{ 'Cancel / Return' }}</button>
        <button type="button" class="normalButton primaryButton" (click)="configService.gotoNextCalibrationTest(calibrationType)">{{ 'Next test' }}</button>
    </div>
</div>
