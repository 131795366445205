import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';
import { IScreenshotFeedback } from 'common-lib';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'issues-list',
    templateUrl: 'issues-list.component.html',
    styleUrls: ['issues-list.component.scss'],
})
export class IssuesListComponent implements OnInit, OnDestroy {
    public issues: IScreenshotFeedback[] = [];
    private subscriptions: Array<Subscription> = [];
    @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;
    private readonly pageSize: number = 30; // Number of items to load per page
    public allItemsLoaded: boolean = false;
    public isLoading: boolean = false; // Flag to indicate loading state
    public selectedIssueID: string | null = null;
    private needToBeScrolledToSelected: boolean = false;

    constructor(private communicationService: ServerCommunicationService, private route: ActivatedRoute, private ref: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.selectedIssueID = this.route.snapshot.queryParamMap.get('selected');
        if (this.selectedIssueID) {
            this.needToBeScrolledToSelected = true;
        }
        this.loadMoreItems(this.selectedIssueID);
    }

    private async loadMoreItems(selectedIssueID?: string | null): Promise<void> {
        this.isLoading = true;
        do {
            const newIssues = await this.communicationService.sockets.IssuesSocket.getNextPage(this.issues.length, this.pageSize);
            this.issues.push(...newIssues);
            if (newIssues.length < this.pageSize) {
                this.allItemsLoaded = true;
            }
            this.isLoading = selectedIssueID ? !newIssues.some((issue) => issue._id === selectedIssueID) : false;
        } while (this.isLoading);
        if (this.needToBeScrolledToSelected) {
            this.ref.detectChanges();
            const parent = this.scrollContainer.nativeElement;
            const childElements = parent.querySelectorAll('.row');
            const selectedRowElement: any = Array.from(childElements).find((el: any) => el.classList.contains('selected'));
            if (selectedRowElement) {
                selectedRowElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }
    }

    public async onScroll(): Promise<void> {
        const element = this.scrollContainer.nativeElement;
        const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
        if (atBottom && !this.isLoading && !this.allItemsLoaded) {
            this.needToBeScrolledToSelected = false;
            await this.loadMoreItems();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
