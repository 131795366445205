import {CALIBRATION_TEST} from '../../enum/calibration.command.enum';
import {ISensorCalibration, ISensorValue} from '../../interfaces/calibration/sensorCalibration.interface';
import {CommonCalibrationTest} from './common.calibration.test.model';

export class SensorCalibration implements CommonCalibrationTest, ISensorCalibration {
    calibrations: ISensorValue[];
    odRearHalfThreshold?: { xValue: number; yValue: number } | undefined;
    odMidHalfThreshold?: { xValue: number; yValue: number } | undefined;
    odFrontHalfThreshold?: { xValue: number; yValue: number } | undefined;
    osRearHalfThreshold?: { xValue: number; yValue: number } | undefined;
    osMidHalfThreshold?: { xValue: number; yValue: number } | undefined;
    osFrontHalfThreshold?: { xValue: number; yValue: number } | undefined;
    osSampleSize?: number | undefined;
    odSampleSize?: number | undefined;

    type: CALIBRATION_TEST = CALIBRATION_TEST.SENSOR_TEST;

    constructor() {
        this.calibrations = [];
    }

    public setModel(model: ISensorCalibration): void {
        this.calibrations = model.calibrations;
        this.odRearHalfThreshold = model.odRearHalfThreshold;
        this.odMidHalfThreshold = model.odMidHalfThreshold;
        this.odFrontHalfThreshold = model.odFrontHalfThreshold;
        this.osRearHalfThreshold = model.osRearHalfThreshold;
        this.osMidHalfThreshold = model.osMidHalfThreshold;
        this.osFrontHalfThreshold = model.osFrontHalfThreshold;
        this.osSampleSize = model.osSampleSize;
        this.odSampleSize = model.odSampleSize;
    }

    public getModel(): ISensorCalibration {
        return {
            calibrations: this.calibrations,
            odRearHalfThreshold: this.odRearHalfThreshold,
            odMidHalfThreshold: this.odMidHalfThreshold,
            odFrontHalfThreshold: this.odFrontHalfThreshold,
            osRearHalfThreshold: this.osRearHalfThreshold,
            osMidHalfThreshold: this.osMidHalfThreshold,
            osFrontHalfThreshold: this.osFrontHalfThreshold,
            osSampleSize: this.osSampleSize,
            odSampleSize: this.odSampleSize,
        };
    }
}
