// Import necessary modules and classes
import axios from 'axios';

class EmailService {
    public async sendEmail(toAddress: string, toName: string, subject: string, content: string) {
        try {
            const apiUrl = 'https://wsx.sp247.net/email/v1/send';

            const emailData = [
                {
                    fromAddress: 'noreply@linkmobility.com',
                    fromName: 'Bulbitech AS',
                    toAddress: toAddress,
                    toName: toName,
                    data: `Subject: ${subject}\r\n\r\n${content}`,
                    refId: `ref${Date.now().toString()}`,
                    platformId: 'EMAILC',
                    platformPartnerId: 247,
                    gateIds: ['JBBdg3Rb'],
                },
            ];

            const auth = {
                username: 'J8AAy3fF',
                password: '$6?Ma@nO8$E*X9us',
            };

            const headers = {
                'Content-Type': 'application/json',
            };

            try {
                const response = await axios.post(apiUrl, emailData, { headers, auth });
                // console.log('Email sent successfully:', response.data);
                // console.log(apiUrl, emailData, auth, headers);
            } catch (error: any) {
                console.error('Error sending email:', error.response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }
}

// Create an instance of the EmailService class
export const emailService: EmailService = new EmailService();
