import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';
import { ROLE } from 'common-lib';

@Component({
    selector: 'menu',
    templateUrl: 'menu.component.html',
    styleUrls: ['menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
    @Output() homeLink = new EventEmitter<string>();
    private subscriptions: Array<Subscription> = [];
    constructor(public communicationService: ServerCommunicationService) {}
    ngOnInit() {
        this.subscriptions.push(
            this.communicationService.userSource.subscribe((currentUser) => {
                setTimeout(() => {
                    switch (currentUser.role) {
                        case ROLE.ADMIN:
                            this.homeLink.emit('dashboard-admin');
                            break;
                        case ROLE.DOCTOR:
                            this.homeLink.emit('dashboard-doctor');
                            break;
                        case ROLE.MANAGER:
                            this.homeLink.emit('dashboard-manager');
                            break;
                        case ROLE.PRODUCTION:
                            this.homeLink.emit('dashboard-production');
                            break;
                        case ROLE.CUSTOMER:
                            this.homeLink.emit('dashboard-customer');
                            break;
                        default:
                            this.homeLink.emit('404');
                            break;
                    }
                }, 0);
            })
        );
    }
    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
