import { IEdit } from '../interfaces/edit.interface';

// Defining the class Edit, which implements the interface IEdit
export class Edit<T> implements IEdit<T> {
    // Declaring properties of the Edit class
    date: number; // Date (timestamp) of the edit
    editorId: string; // ID of the editor
    editor: string; // Editor's firstName and lastName
    fieldName: string; // Name of the field being edited
    previousValue: T; // Previous value of the field
    currentValue: T; // Current value of the field

    // Constructor for the Edit class
    constructor(model?: IEdit<T>) {
        if (model) {
            // If a model is provided, assign its values to the Edit class properties
            this.date = model.date;
            this.editorId = model.editorId;
            this.fieldName = model.fieldName;
            this.previousValue = model.previousValue;
            this.currentValue = model.currentValue;
        }
    }

    // Getter for the model property
    public get model(): IEdit<T> {
        // Return a new object with the same values as the Edit class properties
        return { ...this };
    }
}
