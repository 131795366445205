import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';
import { CommonSystemParams, IBulbiPointRoleProperties, IUser, UPGRADE_FEATURES } from 'common-lib';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'points-list',
    templateUrl: 'points-list.component.html',
    styleUrls: ['points-list.component.scss'],
    host: { class: 'scrollable' },
})
export class PointsListComponent implements OnInit, OnDestroy {
    public points: Array<IUser<IBulbiPointRoleProperties & CommonSystemParams & { lastSessionStarted: number; isOnline: boolean }>>;
    private subscriptions: Array<Subscription> = [];

    constructor(private communicationService: ServerCommunicationService, private route: ActivatedRoute) {}

    async ngOnInit(): Promise<void> {
        this.points = this.route.snapshot.data['customizedPoints'];
        this.subscriptions.push(
            this.communicationService.sockets.RemotePointSocket.pointsOnline.subscribe((data) => {
                this.points.forEach(async (p) => {
                    p.roleProperties.isOnline = data.includes(p._id as string) ? true : false;
                });
            })
        );
    }

    public getFeatureName(feature: string): string {
        return UPGRADE_FEATURES[feature as keyof typeof UPGRADE_FEATURES];
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
