import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';
import { IDoctorExaminationsSearchTerms } from '../../../../../common/interfaces/doctorExaminationsSearchTerms';

@Component({
    selector: 'doctor-paginator',
    templateUrl: 'doctor-paginator.component.html',
    styleUrls: ['doctor-paginator.component.scss'],
})
export class DoctorPaginatorComponent implements OnInit, AfterViewInit, OnDestroy {
    public pagesCnt: number;
    public readonly itemsPerPage: number = 8;
    public page: BehaviorSubject<number>;
    public totalItemsCnt: BehaviorSubject<number>;
    private subscriptions: Subscription[] = [];
    constructor(private serverCommunicationService: ServerCommunicationService, private ref: ChangeDetectorRef) {
        this.ref.detach();
        this.page = new BehaviorSubject<number>(0);
        this.totalItemsCnt = new BehaviorSubject<number>(0);
    }
    ngAfterViewInit(): void {
        this.ref.detectChanges();
    }
    ngOnInit(): void {
        this.subscriptions.push(
            this.page.subscribe((p) => {
                this.updatePageInLocalStorage(p);
            })
        );
        this.subscriptions.push(
            this.totalItemsCnt.subscribe((itemsCnt) => {
                if (Number.isInteger(itemsCnt)) {
                    this.pagesCnt = Math.ceil(itemsCnt / this.itemsPerPage);
                    this.ref.detectChanges();
                }
            })
        );
        // here at this point we have to collect saved page value from localStorage if exist and put to page subject
        const searchQueryString: string | null = localStorage.getItem('searchQuery');
        if (searchQueryString) {
            const searchQuery: IDoctorExaminationsSearchTerms = JSON.parse(searchQueryString);
            this.page.next(searchQuery.page);
        } else {
            this.page.next(1);
        }
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
    public start(): void {
        if (this.page.value !== 1) {
            this.page.next(1);
            this.ref.detectChanges();
        }
    }
    public prev(): void {
        if (this.page.value !== 1) {
            this.page.next(this.page.value - 1);
            this.ref.detectChanges();
        }
    }
    public next(): void {
        if (this.page.value < this.pagesCnt) {
            this.page.next(this.page.value + 1);
            this.ref.detectChanges();
        }
    }
    public end(): void {
        if (this.page.value < this.pagesCnt) {
            this.page.next(this.pagesCnt);
            this.ref.detectChanges();
        }
    }
    private updatePageInLocalStorage(page: number): void {
        if (!Number.isInteger(page)) return;
        const searchQuery: IDoctorExaminationsSearchTerms = JSON.parse(localStorage.getItem('searchQuery') || '') || { page: page };
        if (searchQuery.page !== page) {
            searchQuery.page = page;
            localStorage.setItem('searchQuery', JSON.stringify(searchQuery));
        }
    }
}
