import { ISmartImage } from '../interfaces/smart-image.interface';
import { RegisterEditField } from './registerEditField.class';
import { BlockingType } from '../enum';
import { IGridFsFileMetadata } from '../interfaces';
/**
 * An abstract class that describes the way HUB stores images.
 * The class is abstract in order to hold the same functionality for backend and frontend implementations of such entities.
 * Because of mongo db has size limitation (not more than 16mb) for single object all data that has bigger size is stored in DB using gridfs approach.
 */
export abstract class SmartImage implements ISmartImage {
    imageID: string; // ID of file (GridFS)
    imageUrl: string | undefined; // raw image data restored from mongo gridfs
    mimeType: string; // mime type
    remark: RegisterEditField<string> = new RegisterEditField<string>(); // any metadata that corresponds to image
    constructor() {
        // Initialize remark property to new instance of RegisterEditField with empty string value and BlockingType.AFTER_DAY_WITH_COMMIT blocking type
        this.remark = new RegisterEditField<string>(BlockingType.AFTER_DAY_WITH_COMMIT, '');
    }
    public loadBuffer(buffer: Buffer, imageFormat: string): void {
        this.mimeType = imageFormat;
        this.imageUrl = `url(data:${imageFormat};base64,${buffer.toString('base64')})`;
    }
    public get model(): ISmartImage {
        let model: ISmartImage = {
            imageID: this.imageID,
            imageUrl: this.imageID ? undefined : this.imageUrl, // if ID exist then image is already in DB, else image is just uploaded to front and need to be saved to DB
            mimeType: this.mimeType,
            remark: this.remark.getModel(),
        };
        return model;
    }
    public set model(model: ISmartImage) {
        this.imageID = model.imageID;
        this.imageUrl = model.imageUrl;
        this.mimeType = model.mimeType || '';
        this.remark.setModel(model.remark);
    }
    // method that returns if there is an image already loaded in this inctance or not
    public isEmpty(): boolean {
        // if there is nor imageID (reference to ID in DB) neither imageURL (raw image data) then class is empty
        return !this.imageID && !this.imageUrl;
    }
    public abstract saveToDb(metadata: IGridFsFileMetadata, provider?: any): Promise<SmartImage>;
}
