<div class="table scrollable" #scrollContainer (scroll)="onScroll()">
    <div class="row header">
        <div class="item"><p>#</p></div>
        <div class="item"><p>Created&nbsp;at</p></div>
        <div class="item"><p>Author</p></div>
        <div class="item"><p>Description</p></div>
        <div class="item"><p>HUB&nbsp;version</p></div>
        <div class="item"><p>CAM&nbsp;version</p></div>
    </div>
    @for (issue of issues; track issue._id; let index = $index) {
    <div class="row" [class.selected]="issue._id === selectedIssueID" [routerLink]="['../../issue/', issue._id]">
        <div class="cell">
            <p>{{ index + 1 }}</p>
        </div>
        <div class="cell">
            <p>{{ issue.createdAt | date : 'dd.MM.yy H:mm:ss' }}</p>
        </div>
        <div class="cell">
            <p>{{ issue.user.roleProperties.firstName + '&nbsp;' + issue.user.roleProperties.lastName }}</p>
        </div>
        <div class="cell">
            <p>{{ issue.issueDescription }}</p>
        </div>
        <div class="cell">
            <p>{{ issue.HUBversion }}</p>
        </div>
        <div class="cell">
            <p>{{ issue.CAMversion }}</p>
        </div>
    </div>
    }
</div>
@if (isLoading) {
<div class="loading"><h2>Loading...</h2></div>
}
