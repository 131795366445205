// Import required dependencies
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { ServerCommunicationService } from './serverCommunication.service';
import { Compressor, IGridFsFileMetadata, ITest } from 'common-lib';
import { CommonFileService } from 'angular-staff';

@Injectable()
export class FileService extends Compressor implements CommonFileService {
    // Store fileID and file_content to avoid multiple requests to the backend for the same data
    private fileStorage: Map<string, string>;

    constructor(private http: HttpClient, private scs: ServerCommunicationService) {
        super();
        this.fileStorage = new Map<string, string>();
    }
    convertPdfToImg(pdfData: string): Observable<{ result: string }> {
        throw new Error('Method not implemented.');
    }

    public createUrl(path: string): string {
        // return `${this.configService.backendUrl}${path}`;
        return '';
    }

    public updateFile(id: string, file: string): Observable<{ newID: string }> {
        const url = this.createUrl('/url/update');
        const payload = { id, file };
        return this.http.post<{ newID: string }>(url, payload);
    }

    public async saveFile(fileData: string, fileName: string, metadata: IGridFsFileMetadata): Promise<string> {
        const url = this.createUrl('/url/save');
        const payload = { fileName, fileData, metadata };

        try {
            const response: any = await this.http.post(url, payload).toPromise();
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    // Get file content by file ID and store it in the fileStorage map
    public async getFileContentByFileID(fileId: string): Promise<string> {
        if (this.fileStorage.has(fileId)) {
            return this.fileStorage.get(fileId) as string;
        } else {
            try {
                const rawScreenshot = await this.scs.sockets.IssuesSocket.getRawScreenshot(fileId);
                this.fileStorage.set(fileId, rawScreenshot);
                return rawScreenshot;
            } catch (err) {
                console.log(err);
                return '';
            }
        }
    }
    // Get files content by an array of file IDs
    public async getFilesContentByFilesID(filesId: string[]): Promise<Record<string, string>[]> {
        // Separate already downloaded and not yet downloaded file IDs
        const existingFiles: Record<string, string>[] = [];
        const absentFiles: string[] = [];

        filesId.forEach((id) => {
            if (this.fileStorage.has(id)) {
                const obj: Record<string, string> = { [id]: this.fileStorage.get(id) as string };
                existingFiles.push(obj);
            } else {
                absentFiles.push(id);
            }
        });
        // If there are files not downloaded yet, request their content from the backend
        if (absentFiles.length > 0) {
            const url = this.createUrl('/url/file');
            try {
                const response = await firstValueFrom(this.http.post<Record<string, string>[]>(url, { fileId: absentFiles }));
                response.forEach((data) => {
                    for (const key in data) {
                        if (data.hasOwnProperty(key)) {
                            const imageUrl: string = data[key];
                            // add to storage
                            this.fileStorage.set(key, imageUrl);
                            // add to prepared result
                            existingFiles.push(data);
                        }
                    }
                });
                return existingFiles;
            } catch (err) {
                console.log(err);
                return [];
            }
        }
        // If all files were downloaded before, just return them
        return existingFiles;
    }
    deleteFile(fileInfo: any): Observable<ITest> {
        throw new Error('Method not implemented.');
    }
    getLogo(): Promise<HTMLImageElement> {
        throw new Error('Method not implemented.');
    }
}
