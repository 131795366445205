<div class="tableHead">
    <div><h5>Username</h5></div>
    <div><h5>First name</h5></div>
    <div><h5>Last name</h5></div>
    <div><h5>Email</h5></div>
    <div><h5>Phone</h5></div>
</div>
<div *ngFor="let customer of customers" class="evenHighlited" [routerLink]="['/dashboard-admin/customers/', customer._id]">
    <div>
        <h5>{{ customer.username }}</h5>
    </div>
    <div>
        <h5>{{ customer.roleProperties.firstName }}</h5>
    </div>
    <div>
        <h5>{{ customer.roleProperties.lastName }}</h5>
    </div>
    <div>
        <h5>{{ customer.roleProperties.email }}</h5>
    </div>
    <div>
        <h5>{{ customer.roleProperties.phone }}</h5>
    </div>
</div>
