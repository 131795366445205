<h2>{{ title }}</h2>
<div>
    <div>
        <button type="button" class="toggleButton" routerLinkActive="active" [routerLink]="['/dashboard-admin/invite-user']">
            {{ 'invite user' }}
        </button>
        <button type="button" class="toggleButton" routerLinkActive="active" [routerLink]="['/dashboard-admin/remote-point-details']">
            {{ 'add bulbi point' }}
        </button>
        <button type="button" class="toggleButton" (click)="refresh()">
            {{ 'refresh repres' }}
        </button>
        <button type="button" class="toggleButton" routerLinkActive="active" [routerLink]="['/dashboard-admin/customers/all']">
            {{ 'Customers list' }}
        </button>
        <button type="button" class="toggleButton" routerLinkActive="active" [routerLink]="['/dashboard-admin/points/all']">
            {{ 'Points list' }}
        </button>
        <button type="button" class="toggleButton" routerLinkActive="active" [routerLink]="['/dashboard-admin/representatives/all']">
            {{ 'Representatives overview' }}
        </button>
        <button type="button" class="toggleButton" routerLinkActive="active" [routerLink]="['/dashboard-admin/dummy-data-overview']">
            {{ 'Dummy data overview' }}
        </button>

        <div class="sticker"></div>
    </div>
    <router-outlet></router-outlet>
</div>
