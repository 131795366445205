/**
 * Enum that described user roles in HUB
 */
export enum ROLE {
    DISABLED = 'DISABLED', // this option is for user records in DB that should be blocked by admin by any reasone
    ADMIN = 'ADMIN', // is role for users who provide service of HUB application
    STAFF = 'STAFF', // person who perform's measurements
    DOCTOR = 'DOCTOR', // users who should interpret measurements performed by tech staffs
    REMOTEPOINT = 'REMOTEPOINT', // HUB as user to access marketplace
    CUSTOMER = 'CUSTOMER', // users who bought HUB+CAM (not doctors) for, for example, statistics investigation
    MANAGER = 'MANAGER', // people who working in our company and needs access to marketplace for, for example, statiscics.
    PRODUCTION = 'PRODUCTION', // this role is unpersolalized and using for CAM calibration and synchronization technical data with Marketplace
}
