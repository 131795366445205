import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';
import { ROLE, allowedEmailDomains } from 'common-lib';
import { Observable, map } from 'rxjs';

@Component({
    selector: 'self-invite',
    templateUrl: 'self-invite.component.html',
    styleUrls: ['self-invite.component.scss'],
})
export class SelfInviteComponent implements OnInit, OnDestroy {
    public readonly ROLE: typeof ROLE = ROLE;
    public form: FormGroup;
    constructor(private router: Router, private formBuilder: FormBuilder, private location: Location, private communicationService: ServerCommunicationService) {}
    ngOnInit(): void {
        this.communicationService.sockets.UnsecuredSocket.connect();
        this.form = this.formBuilder.group({
            email: [
                '',
                [
                    Validators.required,
                    Validators.email,
                    (control: AbstractControl): ValidationErrors | null => {
                        const email: string = control.value;
                        if (email) {
                            const isValidDomain = allowedEmailDomains.filter((dn) => dn.includes('bulbitech')).some((domain) => email.endsWith(domain));
                            if (!isValidDomain) {
                                return { domainMismatch: 'Email must end with "@bulbitech.com"' };
                            }
                        }
                        return null;
                    },
                ],
                [
                    (control: AbstractControl): Observable<ValidationErrors | null> => {
                        const email: string = control.value;
                        return this.communicationService.sockets.UnsecuredSocket.isUserExists(email).pipe(
                            map((isUserExists: boolean) => {
                                return isUserExists ? { usernameExists: true } : null;
                            })
                        );
                    },
                ],
            ],
        });
    }
    ngOnDestroy(): void {
        this.communicationService.sockets.UnsecuredSocket.close();
    }
    public async sendInvitation() {
        try {
            const rawFormValue: { email: string } = this.form.getRawValue();
            const error = await this.communicationService.sockets.UnsecuredSocket.sendSelfInitialInvitation(rawFormValue.email);
            if (error) {
                alert(error);
            } else {
                this.router.navigate(['/']);
            }
        } catch (error) {
            console.log(error);
        }
    }
    public goBack(): void {
        this.location.back();
    }
}
