<div class="metadata" [formGroup]="form">
    <div class="inputs">
        <div class="inputWrapper">
            <label>BCU ID</label>
            <input class="normalInput" type="text" formControlName="BCUID" />
        </div>
        <div class="inputWrapper">
            <label>HUB version</label>
            <input class="normalInput" type="text" formControlName="HUBversion" />
        </div>
        <div class="inputWrapper">
            <label>CAM version</label>
            <input class="normalInput" type="text" formControlName="CAMversion" />
        </div>
        <div class="inputWrapper">
            <label>User</label>
            <input class="normalInput" type="text" formControlName="user" />
        </div>
        <div class="textareaWrapper">
            <label>Issue description:</label>
            <textarea class="normalTextarea" formControlName="issueDescription"></textarea>
        </div>
        <div class="protocolWrapper">
            <div class="file" *ngFor="let protocol of issue.protocol; let i = index">
                <a (click)="saveProtocol(i)">
                    {{ protocol.filename }}
                </a>
            </div>
        </div>
    </div>
    <div class="screenshots">
        <image-carousel formControlName="images" [isDeletable]="false" [supportRemarks]="true"></image-carousel>
    </div>
    <div class="logs">
        <div class="textareaWrapper">
            <label>Logs</label>
            <textarea class="normalTextarea" formControlName="logs"></textarea>
        </div>
    </div>
</div>
<div class="backButton">
    <button class="normalButton primaryButton" (click)="goBack()">{{ 'go back' }}</button>
</div>
