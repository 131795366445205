import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import * as d3 from 'd3';
import { CalibrationTestComponent } from '../calibration-test-component';
import { CommonBulbicamService } from '../../../../services/bulbiCam.service';
import { CommonConfigService } from '../../../../services/config.service';
import { CALIBRATION_COMMAND, CALIBRATION_TEST, ICalibrationResult, ILuxCalibration, OCULUS } from 'common-lib';
import { LuxCalibrationFrontend } from '../../../../classes/calibration-tests/lux-calibration.model';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';

interface RGB {
    r: number;
    g: number;
    b: number;
}

interface RGBCalibration {
    od: RGB[];
    os: RGB[];
}

interface ITableData {
    RGB: number;
    odInterLux: number | string;
    osInterLux: number | string;
    odEnteredLux: number | string;
    osEnteredLux: number | string;
}

interface IPolynom {
    A: number;
    B: number;
    C: number;
}

enum State {
    Init = 'init',
    Checked = 'checked',
    Start = 'start',
    Stop = 'stop',
}

const rgbLowOD: { A: number; B: number; C: number } = {
    A: 0.006,
    B: -0.0355,
    C: 5.73,
};

const rgbHighOD: { A: number; B: number; C: number } = {
    A: 0.0108,
    B: -0.94,
    C: 45,
};

const rgbOnScreenOD: number = 516;

const rgbLowOS: { A: number; B: number; C: number } = {
    A: 0.0079,
    B: -0.0786,
    C: 5.85,
};

const rgbHighOS: { A: number; B: number; C: number } = {
    A: 0.0101,
    B: -0.516,
    C: 25.6,
};

const rgbOnScreenOS: number = 551;
const N = 9;

const scaleCoefs = { od: 5.973207636, os: 6.346086537 };
const redColor = '#EB5757';
const yellowColor = '#F2C94C';
const blueColor = '#2D9CDB';

@Component({
    selector: 'lux-calibration',
    templateUrl: 'lux-calibration.component.html',
    styleUrls: ['lux-calibration.component.scss', '../reusable-styles.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, ReactiveFormsModule],
})
export class LuxCalibrationComponent extends CalibrationTestComponent implements OnInit {
    @ViewChild('svgChart') svgChart: ElementRef;
    @ViewChildren('luxValueOD') luxInputsOD: QueryList<ElementRef>;
    @ViewChildren('luxValueOS') luxInputsOS: QueryList<ElementRef>;
    results: ICalibrationResult<LuxCalibrationFrontend>[] = [];
    override calibrationType: CALIBRATION_TEST;

    tableData: ITableData[] = [];

    rgbValues: RGBCalibration = {
        od: [{ r: 0, g: 0, b: 0 }],
        os: [{ r: 0, g: 0, b: 0 }],
    };

    startValue = {
        BackgroundOS: 0,
        BackgroundOD: 0,
    };

    luxtableRGBValues: number[] = [];
    luxtableValuesOD: {
        rgb: number;
        lux: number;
    }[] = [];
    luxtableValuesOS: {
        rgb: number;
        lux: number;
    }[] = [];

    currentState: State = State.Init;
    isTestRunning = false;
    isTableOpen = false;

    margin = 20;

    svg: any;
    svgInnerOS: any;
    svgInnerOD: any;

    yScale: any;
    xScaleOS: any;
    xScaleOD: any;
    yAxisOD: any;
    xAxisOD: any;
    yAxisOS: any;
    xAxisOS: any;

    activeElement: number = null!;

    form: FormGroup = this.fb.group({
        eyePairOD: this.fb.array([]),
        eyePairOS: this.fb.array([]),
    });

    activePairIndex: number = 0;
    activePairTarget: number = 0; // od = 0, os = 1

    constructor(
        @Inject('BulbicamService') private bulbicamService: CommonBulbicamService,
        @Inject('ConfigService') public configService: CommonConfigService,
        private route: ActivatedRoute,
        private fb: FormBuilder
    ) {
        super(CALIBRATION_TEST.LUX_CALIBRATION);
        for (let i = 0; i < N; i++) {
            const eyeFormOD = this.fb.group({
                lux: null,
            });
            const eyeFormOS = this.fb.group({
                lux: null,
            });

            this.eyePairOD.push(eyeFormOD);
            this.eyePairOS.push(eyeFormOS);
        }
        for (let i = 1; i < N; i++) {
            if (i === 1) {
                this.rgbValues.od[i] = { r: 31, g: 31, b: 31 };
                this.rgbValues.os[i] = { r: 31, g: 31, b: 31 };
            } else if (i > 1 && i <= 8) {
                const rgbValue = this.rgbValues.od[i - 1].r;
                this.rgbValues.od[i] = { r: rgbValue + 32, g: rgbValue + 32, b: rgbValue + 32 };
                this.rgbValues.os[i] = { r: rgbValue + 32, g: rgbValue + 32, b: rgbValue + 32 };
            } else if (i === N) {
                this.rgbValues.od[i] = { r: 0, g: 0, b: 0 };
                this.rgbValues.os[i] = { r: 0, g: 0, b: 0 };
            }
        }

        this.luxtableRGBValues = Array.from(Array(128).keys()).map((d) => d * 2);
        this.luxtableRGBValues.push(255);
    }
    ngOnInit(): void {
        const resolvedData: {
            calibrationResults: ICalibrationResult<ILuxCalibration>[];
        } = this.route.snapshot.data['calibrationResults'];
        this.handleCalibrationTestsResults(resolvedData.calibrationResults);
    }

    get stateList(): typeof State {
        return State;
    }

    protected messageHandler(): Promise<void> {
        throw new Error('Method not implemented.');
    }

    private handleCalibrationTestsResults(calibrationResults: ICalibrationResult<ILuxCalibration>[]): void {
        this.results = calibrationResults
            .map((result) => {
                const luxCalibrationResult = new LuxCalibrationFrontend(this.bulbicamService);
                luxCalibrationResult.setModel(result.results!);
                result.results = luxCalibrationResult;
                return result as ICalibrationResult<LuxCalibrationFrontend>;
            })
            .sort((a, b) => b.createdAt - a.createdAt);
    }

    getOnscreenLuxValue(ofScreenValue: number, oculus: OCULUS): number {
        if (oculus === OCULUS.OD) {
            return ofScreenValue * scaleCoefs.od;
        } else if (oculus === OCULUS.OS) {
            return ofScreenValue * scaleCoefs.os;
        }

        return 0;
    }

    move(direction: number = 1) {
        this.resizeChart();
        this.drawDots();
        this.activePairIndex = this.activePairIndex + direction;
        if (this.activePairIndex === N) {
            this.activePairIndex = 0;
            this.activePairTarget = (this.activePairTarget + 1) % 2;
        } else if (this.activePairIndex === -1) {
            this.activePairIndex = N - 1;
            this.activePairTarget = (this.activePairTarget + 1) % 2;
        }
        this.sendSettings();
        if (this.activePairTarget === 0) {
            this.luxInputsOD.find((_, index) => index === this.activePairIndex)?.nativeElement.focus();
        } else {
            this.luxInputsOS.find((_, index) => index === this.activePairIndex)?.nativeElement.focus();
        }
    }

    initChart(width: number = 300, height: number): void {
        this.svg = d3.select(this.svgChart?.nativeElement).attr('id', 'svgLux').attr('width', width).attr('height', height);
        width -= 30;

        this.svgInnerOD = this.svg
            .append('g')
            .attr('width', width / 2 - this.margin)
            .style('transform', `translate(${this.margin}px, ${this.margin}px)`);
        this.svgInnerOS = this.svg
            .append('g')
            .attr('width', width / 2 - this.margin)
            .style('transform', `translate(${this.margin}px, ${this.margin}px)`);

        this.yScale = d3
            .scaleLinear()
            .domain([100, 0])
            .range([2 * this.margin, 300 - this.margin]);

        this.xScaleOS = d3
            .scaleLinear()
            .domain([0, 260])
            .range([width / 2 + 2 * this.margin, width - 2 * this.margin]);

        this.xScaleOD = d3
            .scaleLinear()
            .domain([0, 260])
            .range([2 * this.margin, width / 2 - 2 * this.margin]);

        this.yAxisOS = this.svgInnerOS
            .append('g')
            .attr('id', 'y-axis-os')
            .attr('class', 'axis-grey')
            .attr('stroke', 'grey')
            .style('transform', `translate(${2 * this.margin}px, -20px)`);

        this.xAxisOS = this.svgInnerOS
            .append('g')
            .attr('id', 'x-axis-os')
            .attr('class', 'axis-grey')
            .attr('stroke', 'grey')
            .style('transform', `translate(0px, ${height - 50 - 2 * this.margin}px)`);

        this.yAxisOD = this.svgInnerOD
            .append('g')
            .attr('id', 'y-axis-od')
            .attr('class', 'axis-grey')
            .attr('stroke', 'grey')
            .style('transform', `translate(${width / 2 + 2 * this.margin}px, -20px)`);

        this.xAxisOD = this.svgInnerOD
            .append('g')
            .attr('id', 'x-axis-od')
            .attr('class', 'axis-grey')
            .attr('stroke', 'grey')
            .style('transform', `translate(${0}px, ${height - 50 - 2 * this.margin}px)`);

        const yAxis = d3.axisLeft(this.yScale);
        const xAxisOS = d3.axisBottom(this.xScaleOS);
        const xAxisOD = d3.axisBottom(this.xScaleOD);

        this.yAxisOS.call(yAxis);
        this.xAxisOS.call(xAxisOS);
        this.yAxisOD.call(yAxis);
        this.xAxisOD.call(xAxisOD);

        const appendSvgInnerText = (target: any, text: string, scale: { dx: any; dy: any }, transform: { x: number; y: number }) => {
            target
                .append('text')
                .attr('id', 'text-label')
                .text(`${text}`)
                .attr('dx', scale.dx)
                .attr('dy', scale.dy)
                .attr('stroke', 'white')
                .attr('stroke-width', '1px')
                .attr('font-size', '12px')
                .style('transform', `translate(${transform.x}px, ${transform.y}px)`);
        };

        appendSvgInnerText(this.svgInnerOS, 'OS', { dx: this.xScaleOS(125), dy: this.yScale(100) }, { x: -25, y: -30 });
        appendSvgInnerText(this.svgInnerOD, 'OD', { dx: this.xScaleOD(125), dy: this.yScale(100) }, { x: -25, y: -30 });
        appendSvgInnerText(this.svgInnerOS, 'RGB', { dx: this.xScaleOS(270), dy: this.yScale(0) }, { x: 0, y: -10 });
        appendSvgInnerText(this.svgInnerOS, 'Lux', { dx: this.xScaleOS(-13), dy: this.yScale(55) }, { x: 5, y: -150 });
        appendSvgInnerText(this.svgInnerOD, 'RGB', { dx: this.xScaleOD(270), dy: this.yScale(0) }, { x: 0, y: -10 });
        appendSvgInnerText(this.svgInnerOD, 'Lux', { dx: this.xScaleOD(-13), dy: this.yScale(55) }, { x: 5, y: -150 });
    }

    resizeChart(range: number[] = []) {
        const results: number[] = this.eyePairValues;

        this.svg.selectAll('.circle-os').remove();
        this.svg.selectAll('.circle-od').remove();
        if (!range.length) {
            const maxResult = Math.max(...results);
            range = [maxResult === 0 ? maxResult + 15 : maxResult * scaleCoefs.os, 0];
        }

        this.yScale = d3
            .scaleLinear()
            .domain(range)
            .range([2 * this.margin, 300 - this.margin]);

        const yAxis = d3.axisLeft(this.yScale);

        this.yAxisOS.call(yAxis);

        this.yAxisOD.call(yAxis);
    }

    drawDots() {
        const results: number[] = this.eyePairValues;

        const drawDot = (target: any, i: number, eye: 'od' | 'os', dot: 'yellow' | 'blue', scale: { cx: any; cy: any }) => {
            target
                .append('circle')
                .attr('id', dot === 'yellow' ? `circle-${eye}-${i}` : `scale-circle-${eye}-${i}`)
                .attr('class', dot === 'yellow' ? `circle-${eye}` : `circle-${eye}`)
                .attr('cx', scale.cx)
                .attr('cy', scale.cy)
                .attr('r', 3)
                .attr('stroke', dot === 'yellow' ? yellowColor : blueColor)
                .attr('fill', '#03272b')
                .attr('stroke-width', '2px')
                .attr('font-size', '12px')
                .style('transform', `translate(0px, -20px)`);
        };

        for (let i = 0; i < N * 2; i++) {
            if (!results[i]) continue;
            if ((i > 0 && i < N) || i === 0) {
                drawDot(this.svgInnerOD, i, 'od', 'yellow', { cx: this.xScaleOD(this.rgbValues.od[i].r), cy: this.yScale(results[i]) });
                drawDot(this.svgInnerOD, i, 'od', 'blue', { cx: this.xScaleOD(this.rgbValues.od[i].r), cy: this.yScale(results[i] * scaleCoefs.od) });
            }

            if (i >= N && i < N * 2) {
                drawDot(this.svgInnerOS, i, 'os', 'yellow', { cx: this.xScaleOS(this.rgbValues.os[i - N].r), cy: this.yScale(results[i]) });
                drawDot(this.svgInnerOS, i, 'os', 'blue', { cx: this.xScaleOS(this.rgbValues.os[i - N].r), cy: this.yScale(results[i] * scaleCoefs.os) });
            }
        }
    }

    drawResults(luxValues: number[]) {
        const [odRawValues, osRawValues] = [[...luxValues.slice(0, N)], [...luxValues.slice(N, N * 2)]];

        if (!odRawValues[odRawValues.length - 1] || !osRawValues[osRawValues.length - 1]) {
            console.error('cannot build teoretical curve');
            return;
        }

        const odPercentage = (odRawValues[odRawValues.length - 1] * scaleCoefs.od) / rgbOnScreenOD;
        const osPercentage = (osRawValues[osRawValues.length - 1] * scaleCoefs.os) / rgbOnScreenOS;

        const rgbODValues = this.calculatePolynom(odPercentage, 'OD');
        const rgbOSValues = this.calculatePolynom(osPercentage, 'OS');

        const [odValuesTemp, osValuesTemp] = [this.rgbValues.od.slice(0, N).map((el) => el.r), this.rgbValues.os.slice(0, N).map((el) => el.r)];

        const odResults = this.calculateResultValues(rgbODValues, odValuesTemp);
        const osResults = this.calculateResultValues(rgbOSValues, osValuesTemp);

        this.luxtableValuesOD = this.calculateResultValues(rgbODValues, this.luxtableRGBValues);
        this.luxtableValuesOS = this.calculateResultValues(rgbOSValues, this.luxtableRGBValues);

        const range = [Math.max(odRawValues[odRawValues.length - 1] * scaleCoefs.od, osRawValues[osRawValues.length - 1] * scaleCoefs.os) + 15, 0];

        this.svg.selectAll('#path-od').remove();
        this.svg.selectAll('#path-os').remove();
        this.resizeChart(range);
        this.drawDots();

        this.yScale = d3
            .scaleLinear()
            .domain(range)
            .range([2 * this.margin, 300 - this.margin]);

        const yAxis = d3.axisLeft(this.yScale);

        this.yAxisOS.call(yAxis);

        this.yAxisOD.call(yAxis);

        const lineDefined = d3.line().defined(function (d) {
            return d[1] !== null;
        });

        const pointsOD: [number, number][] = odResults.map((d) => [this.xScaleOD(d.rgb), this.yScale(d.lux)]);

        const pointsOS: [number, number][] = osResults.map((d) => [this.xScaleOS(d.rgb), this.yScale(d.lux)]);

        this.svgInnerOD
            .append('path')
            .attr('id', `path-od`)
            .style('fill', 'none')
            .style('stroke', redColor)
            .style('stroke-width', '3px')
            .style('transform', `translate(0px, -20px)`)
            .attr('d', lineDefined(pointsOD));

        this.svgInnerOS
            .append('path')
            .attr('id', `path-os`)
            .style('fill', 'none')
            .style('stroke', redColor)
            .style('stroke-width', '3px')
            .style('transform', `translate(0px, -20px)`)
            .attr('d', lineDefined(pointsOS));

        this.calculateTableData();
    }

    calculateTableData() {
        this.tableData = [];

        const enteredValuesDictionary = {
            OD: this.rgbValues.od.map((rgb, i) => ({ r: rgb.r, lux: this.eyePairOD.value[i].lux as number })),
            OS: this.rgbValues.os.map((rgb, i) => ({ r: rgb.r, lux: this.eyePairOS.value[i].lux as number })),
        };

        const rgbValuesArray = Array.from(
            new Set([...this.luxtableRGBValues, ...this.eyePairValues, ...this.rgbValues.od.map((e) => e.r), ...this.rgbValues.os.map((e) => e.r)])
        ).sort((a, b) => a - b) as number[];
        for (let i = 0; i < rgbValuesArray.length; i++) {
            const rgbValue = rgbValuesArray[i];
            const odEntered = enteredValuesDictionary.OD.find((enteredValue) => enteredValue.r === rgbValue)?.lux;
            const osEntered = enteredValuesDictionary.OS.find((enteredValue) => enteredValue.r === rgbValue)?.lux;
            const odInterLux = this.luxtableValuesOD.find((lux) => lux.rgb === rgbValue)?.lux;
            const osInterLux = this.luxtableValuesOS.find((lux) => lux.rgb === rgbValue)?.lux;
            if (!odEntered && !osEntered && !odInterLux && !osInterLux) continue;

            this.tableData.push({
                RGB: rgbValue,
                odInterLux: odInterLux ?? '',
                osInterLux: osInterLux ?? '',
                odEnteredLux: odEntered ? `${odEntered} / ${odEntered * scaleCoefs.od}` : '',
                osEnteredLux: osEntered ? `${osEntered} / ${osEntered * scaleCoefs.os}` : '',
            });
        }
    }

    calculatePolynom(percentage: number, type: 'OD' | 'OS') {
        let lowRGBValues: IPolynom;

        let highRGBValues: IPolynom;

        if (type === 'OD') {
            lowRGBValues = {
                A: percentage * rgbLowOD.A,
                B: percentage * rgbLowOD.B,
                C: percentage * rgbLowOD.C,
            };

            highRGBValues = {
                A: percentage * rgbHighOD.A,
                B: percentage * rgbHighOD.B,
                C: percentage * rgbHighOD.C,
            };
        } else {
            lowRGBValues = {
                A: percentage * rgbLowOS.A,
                B: percentage * rgbLowOS.B,
                C: percentage * rgbLowOS.C,
            };

            highRGBValues = {
                A: percentage * rgbHighOS.A,
                B: percentage * rgbHighOS.B,
                C: percentage * rgbHighOS.C,
            };
        }

        return [lowRGBValues, highRGBValues];
    }

    calculateResultValues(coefs: { A: number; B: number; C: number }[], values: number[]) {
        let resultArray: {
            rgb: number;
            lux: number;
        }[] = [];

        for (let el of values) {
            if (el <= 64) {
                resultArray.push({
                    rgb: el,
                    lux: coefs[0].A * Math.pow(el, 2) + coefs[0].B * el + coefs[0].C,
                });
            } else {
                resultArray.push({
                    rgb: el,
                    lux: coefs[1].A * Math.pow(el, 2) + coefs[1].B * el + coefs[1].C,
                });
            }
        }

        return resultArray;
    }

    get eyePairValues() {
        return [
            ...this.eyePairOD.value.map((d: { lux: number | string }) => {
                if (typeof d.lux === 'string') {
                    return d.lux.replace(',', '.');
                } else {
                    return d.lux;
                }
            }),
            ...this.eyePairOS.value.map((d: { lux: number | string }) => {
                if (typeof d.lux === 'string') {
                    return d.lux.replace(',', '.');
                } else {
                    return d.lux;
                }
            }),
        ];
    }

    get eyePairOD() {
        return this.form.controls['eyePairOD'] as FormArray;
    }

    get eyePairODControls() {
        return this.eyePairOD.controls as FormGroup[];
    }

    get eyePairOS() {
        return this.form.controls['eyePairOS'] as FormArray;
    }

    get eyePairOSControls() {
        return this.eyePairOS.controls as FormGroup[];
    }

    getBGColor(rgbValue: number) {
        let hex = rgbValue.toString(16);
        hex = hex.length == 1 ? '0' + hex : hex;

        return '#' + hex + hex + hex;
    }

    getColor(rgbValue: number) {
        if (rgbValue > 128) {
            return '#03272B';
        }

        return '#FAFAFA';
    }

    sendSettings(): void {
        firstValueFrom(
            this.bulbicamService.sendCalibrationCommand({
                command: CALIBRATION_COMMAND.BACKGROUND,
                test: this.calibrationType,
                BackgroundOS: this.activePairTarget ? this.rgbValues.os[this.activePairIndex].r : 0,
                BackgroundOD: !this.activePairTarget ? this.rgbValues.od[this.activePairIndex].r : 0,
            })
        );
    }

    override async saveResults(): Promise<void> {
        let valuesArray: number[] = []; // user input
        [...this.eyePairOD.value, ...this.eyePairOS.value].forEach((el: any, i: number) => {
            valuesArray.push(+el.lux.replace(',', '.'));
        });

        this.drawResults(valuesArray);

        const results = valuesArray.map((d: number, i: number) => {
            if (i < N) {
                return {
                    order: i,
                    lux_value: d,
                    OD: this.rgbValues.od[i].r,
                    OS: this.rgbValues.os[0].r,
                };
            } else {
                return {
                    order: i,
                    lux_value: d,
                    OD: this.rgbValues.od[0].r,
                    OS: this.rgbValues.os[i - N].r,
                };
            }
        });

        const model: ILuxCalibration = {
            calibrations: results,
            minLuxValue: parseInt(Math.min(this.getOnscreenLuxValue(valuesArray[8], OCULUS.OD), this.getOnscreenLuxValue(valuesArray[17], OCULUS.OS)).toFixed(0)),
            interpolatedLuxValuesOD: this.luxtableValuesOD, // [{rgb: number, lux: number}]
            interpolatedLuxValuesOS: this.luxtableValuesOS, // [{rgb: number, lux: number}]
        };

        const luxResults = new LuxCalibrationFrontend(this.bulbicamService);
        luxResults.setModel(model);
        await luxResults.saveModel();

        const allTests = await firstValueFrom(this.bulbicamService.getCalibrationResults<ILuxCalibration>([this.calibrationType]));
        this.handleCalibrationTestsResults(allTests);

        firstValueFrom(this.bulbicamService.sendCalibrationCommand({ command: CALIBRATION_COMMAND.STOP, test: this.calibrationType }));

        this.eyePairOD.disable();
        this.eyePairOS.disable();
        this.currentState = State.Stop;
        this.isTestRunning = !this.isTestRunning;
    }

    toggleTest() {
        if (this.currentState === State.Start) {
            firstValueFrom(this.bulbicamService.sendCalibrationCommand({ command: CALIBRATION_COMMAND.STOP, test: this.calibrationType }));

            this.eyePairOD.disable();
            this.eyePairOS.disable();
            this.currentState = State.Stop;

            this.eyePairOD.controls.forEach((control: AbstractControl) => {
                control.setValue({ lux: '' });
            });
            this.eyePairOS.controls.forEach((control: AbstractControl) => {
                control.setValue({ lux: '' });
            });
        } else {
            this.activePairIndex = 0;
            this.activeElement = null!;

            firstValueFrom(
                this.bulbicamService.sendCalibrationCommand({
                    command: CALIBRATION_COMMAND.START,
                    test: this.calibrationType,
                    BackgroundOS: this.rgbValues.os[0].r,
                    BackgroundOD: this.rgbValues.od[0].r,
                })
            );

            this.clearChart();
            this.clearForm();

            if (this.currentState === State.Init) {
                const width = this.svgChart?.nativeElement?.getBoundingClientRect()?.width;
                const height = this.svgChart?.nativeElement?.getBoundingClientRect()?.height;

                this.initChart(width, height);
            }
            this.currentState = State.Start;

            this.startValue = {
                BackgroundOS: this.rgbValues.os[this.activePairIndex].r,
                BackgroundOD: this.rgbValues.od[this.activePairIndex].r,
            };

            this.eyePairOD.enable();
            this.eyePairOS.enable();

            this.currentState = State.Start;
        }
        this.isTestRunning = !this.isTestRunning;
    }

    changeField(index: number) {
        if (this.eyePairOD.disabled) {
            return;
        } else {
            this.activePairIndex = index;

            this.resizeChart();
            this.drawDots();
            this.sendSettings();

            if (this.activePairTarget === 0) {
                this.luxInputsOD.find((_, i) => i === this.activePairIndex)?.nativeElement.focus();
            } else {
                this.luxInputsOS.find((_, i) => i === this.activePairIndex)?.nativeElement.focus();
            }
        }
    }

    clearForm() {
        while (this.eyePairOD.length !== 0 && this.eyePairOS.length !== 0) {
            this.eyePairOD.removeAt(0);
            this.eyePairOS.removeAt(0);
        }
        for (let i = 0; i < N; i++) {
            const eyeFormOD = this.fb.group({
                lux: null,
            });
            const eyeFormOS = this.fb.group({
                lux: null,
            });

            this.eyePairOD.push(eyeFormOD);
            this.eyePairOS.push(eyeFormOS);
        }
    }

    setInputValues(el: ICalibrationResult<LuxCalibrationFrontend>, index: number) {
        //{ resultValues: number[]; timestamp: any }
        this.activeElement = index;
        if (this.currentState === State.Start) {
            firstValueFrom(this.bulbicamService.sendCalibrationCommand({ command: CALIBRATION_COMMAND.STOP, test: this.calibrationType }));

            this.eyePairOD.disable();
            this.eyePairOS.disable();
            this.currentState = State.Stop;
        }
        if (this.currentState === State.Init) {
            const width = this.svgChart?.nativeElement?.getBoundingClientRect()?.width;
            const height = this.svgChart?.nativeElement?.getBoundingClientRect()?.height;
            this.eyePairOD.disable();
            this.eyePairOS.disable();

            this.initChart(width, height);
        }

        this.currentState = State.Checked;

        const resultsToDraw = [];
        for (let i = 0; i < N * 2; i++) {
            const value = el?.results?.calibrations[i].lux_value || 0; //el?.resultValues?.[i]

            resultsToDraw.push(value);
            if (i < N) {
                this.eyePairOD.controls[i].setValue({ lux: value });
            } else {
                this.eyePairOS.controls[i - N].setValue({ lux: value });
            }
        }

        this.drawResults(resultsToDraw); //el.resultValues
    }

    async deleteInputResults(i: number) {
        await firstValueFrom(this.bulbicamService.deleteCalibrationResult(this.calibrationType, this.results[i].createdAt));

        const calibrationResults = await firstValueFrom(this.bulbicamService.getCalibrationResults<ILuxCalibration>([this.calibrationType]));
        this.handleCalibrationTestsResults(calibrationResults);

        if (this.activeElement === i) {
            this.activeElement = -1;

            this.clearForm();
            this.clearChart();

            this.eyePairOD.disable();
            this.eyePairOS.disable();
        } else if (this.activeElement > i) {
            this.activeElement--;
        }
    }

    clearChart() {
        if (this.currentState !== State.Init) {
            this.svg.selectAll('.circle-os')?.remove();
            this.svg.selectAll('.circle-od')?.remove();
            this.svg.selectAll('#path-od')?.remove();
            this.svg.selectAll('#path-os')?.remove();
        }
    }

    customOnDestroy(): void {}
}
