import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
    name: 'timeZoneDate',
})
export class TimeZoneDatePipe extends DatePipe implements PipeTransform {
    override transform(value: string | number | Date, format?: string | undefined, timezone?: string | undefined, locale?: string | undefined): string | null;
    override transform(value: null | undefined, format?: string | undefined, timezone?: string | undefined, locale?: string | undefined): null;
    override transform(value: string | number | Date | null | undefined, format?: string | undefined, timezone?: string | undefined, locale?: string | undefined): string | null;
    override transform(value: unknown, format?: unknown, timezone?: unknown, locale?: unknown): string | null {
        const timezoneOffset = moment(value as number)
            .tz((timezone as string) || 'Europe/Zaporozhye')
            .format('Z');
        return super.transform(value as string | number | Date, format as string, timezoneOffset);
    }
}
