import { Subject } from 'rxjs';
import { ISession } from 'common-lib';
import { IOSocket } from './ioSocket';
import { Manager } from 'socket.io-client';
import { checkSocketPromise } from '../decorators/checkSocket.decorator';

export class SessionsSocket extends IOSocket {
    constructor(manager: Manager, socketError: Subject<Error>) {
        super(manager, '/session', socketError);
    }
    public async addHendlers(resolvable: () => void): Promise<void> {}
    @checkSocketPromise()
    public getLastSession(userId: string): Promise<ISession> {
        return new Promise<ISession>((res) => {
            this._socket.emit('getLastSession', userId, res);
        });
    }
}
