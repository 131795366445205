<div *ngFor="let test of existingData">
    <h5>
        {{ test.testType }}
    </h5>
    <div *ngFor="let chart of test.createdAt">
        <h6>
            {{ chart | date: 'dd.MM.yy H:mm:ss' }}
        </h6>
        <i class="aico-noUpload" (click)="delete(test.testType, chart)"></i>
    </div>
</div>
<div>
    <input type="file" style="display: none" (change)="onFileSelected($event)" #fileUpload />
    <button class="normalButton primaryButton" (click)="fileUpload.click()" [disabled]="isButtonDisabled">Upload</button>
</div>
