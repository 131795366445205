import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';
import { IUser, ICustomerRoleProperties, ROLE } from 'common-lib';

@Component({
    selector: 'customers-list',
    templateUrl: 'customers-list.component.html',
    styleUrls: ['customers-list.component.scss'],
})
export class CustomersListComponent implements OnInit, OnDestroy {
    public customers: IUser<ICustomerRoleProperties>[];
    private subscriptions: Array<Subscription> = [];

    constructor(private communicationService: ServerCommunicationService) {}

    async ngOnInit(): Promise<void> {
        this.customers = await this.communicationService.sockets.UserSocket.getUsers<ICustomerRoleProperties>(ROLE.CUSTOMER);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
