import { ISmartImage } from '../smart-image.interface';
import { CALIBRATION_TEST, CALIBRATION_COMMAND } from '../../enum/calibration.command.enum';

export enum DIOPTERS_LIST {
    MINUS_THREE = '-3 diopters',
    ZERO = '0 diopters',
    THREE = '+3 diopters',
    SIX = '+6 diopters',
    NINE = '+9 diopters',
}

export interface ILensDistortionCalibration {
    lensImage: ISmartImage;
    pixelsPerMillimeterResult: IPixelsPerMillimeterResult[];
}

export interface IPixelsPerMillimeterResult {
    diopter: DIOPTERS_LIST | null;
    pixelsOD: number | null;
    pixelsOS: number | null;
    id: number | null;
}

export interface ILensDistortionResponce {
    testType: CALIBRATION_TEST;
    message_type: CALIBRATION_COMMAND;
    picturePath: string;
    Pixels_per_mm: number;
    smartImage: ISmartImage;
    pixelsPerMillimeter: number;
}
