export enum CALIBRATION_COMMAND {
    START = 'START',
    DATA_PACKAGE = 'DATA_PACKAGE',
    BACKGROUND = 'BACKGROUND',
    STOP = 'STOP',
}

export enum CALIBRATION_TEST {
    SCREEN_CALIBRATION = 'SCREEN_TEST',
    PATTERN_TEST = 'PATTERN_TEST',
    VPOS_TEST = 'VPOS_TEST',
    LONG_TIMING_TEST = 'LONG_TIMING_TEST',
    SHORT_TIMING_TEST = 'SHORT_TIMING_TEST',
    SENSOR_TEST = 'SENSOR_TEST',
    LUX_CALIBRATION = 'LUX_CALIBRATION',
    LENS_DISTORTION_TEST = 'DISTORTION_TEST'
}
