import { CommonCalibrationTest } from './common.calibration.test.model';
import { CALIBRATION_TEST } from '../../enum/calibration.command.enum';
import { IVPosCalibration } from '../../interfaces/calibration/vposCalibration.interface';
import { SmartImage } from '../smartImage.class';

export class VPosCalibration implements CommonCalibrationTest, IVPosCalibration {
    image: SmartImage;
    odAngle: number;
    odLength: number;
    osAngle: number;
    osLength: number;
    type: CALIBRATION_TEST = CALIBRATION_TEST.VPOS_TEST;

    public setModel(data: IVPosCalibration): void {
        this.image.model = data.image;
        this.odAngle = data.odAngle;
        this.osAngle = data.osAngle;
        this.odLength = data.odLength;
        this.osLength = data.osLength;
    }

    public getModel(): IVPosCalibration {
        return {
            image: this.image.model,
            odAngle: this.odAngle,
            osAngle: this.osAngle,
            odLength: this.odLength,
            osLength: this.osLength,
        };
    }
}
