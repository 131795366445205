<form [formGroup]="form" novalidate>
    <singleselect [label]="'role'" [list]="ROLE" formControlName="role"></singleselect>
    <div class="inputWrapper">
        <label>{{ 'email' }}*</label>
        <input class="normalInput" type="email" formControlName="email" />
    </div>
    <div>
        <button class="normalButton primaryButton" (click)="goBack()">{{ 'go back' }}</button>
        <button class="normalButton primaryButton" (click)="registerUser()" [disabled]="form.pristine || form.invalid">{{ 'send invitation' }}</button>
        <button class="normalButton primaryButton" (click)="clear()" [disabled]="form.pristine">{{ 'clear' }}</button>
    </div>
</form>
