<div *ngFor="let test of charts">
    <h5>
        {{ test.testType }}
    </h5>
    <div *ngFor="let chart of test.charts" [class.downloaded]="isDownloaded(test.testType, chart.createdAt)">
        <h6 (click)="download(test.testType, chart)">
            {{ chart.createdAt | date: 'dd.MM.yy H:mm:ss' }}
        </h6>
        <i class="aico-noUpload" (click)="delete(test.testType, chart)"></i>
    </div>
</div>
