import { CALIBRATION_TEST, TimingCalibrationTest } from 'common-lib';
import { CommonBulbicamService } from '../../services';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';

export class TimingCalibrationFrontend extends TimingCalibrationTest {
    constructor(type: CALIBRATION_TEST.LONG_TIMING_TEST | CALIBRATION_TEST.SHORT_TIMING_TEST, private bulbicamService: CommonBulbicamService) {
        super(type);
    }
    public async saveModel(): Promise<void> {
        await firstValueFrom(this.bulbicamService.saveCalibrationResult({ test: this.type, createdAt: Date.now(), results: this.getModel() }));
    }
}
