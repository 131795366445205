import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IssueComponent, RegisterKeyComponent } from 'marketplace/frontend/src/app/components';
import { PageNotFoundComponent } from 'marketplace/frontend/src/app/components/404/page-not-found.component';
import { AboutComponent } from 'marketplace/frontend/src/app/components/about/about.component';
import { authGuard } from 'marketplace/frontend/src/app/guards/auth.guard';
import { roleGuard } from 'marketplace/frontend/src/app/guards/role.guard';
import { ROLE } from 'common-lib';
import { issuePageResolver } from '../../guards/issuePageResolver';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/auth',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        loadChildren: () => import('../auth.module/auth.module').then((m) => m.AuthModule),
    },
    {
        path: 'dashboard-admin',
        canLoad: [authGuard, roleGuard],
        data: {
            expectedRole: [ROLE.ADMIN],
        },
        loadChildren: () => import('../admin.module/admin.module').then((m) => m.AdminModule),
    },
    {
        path: 'dashboard-doctor',
        canLoad: [authGuard, roleGuard],
        data: {
            expectedRole: [ROLE.DOCTOR],
        },
        loadChildren: () => import('../doctor.module/doctor.module').then((m) => m.DoctorModule),
    },
    {
        path: 'dashboard-manager',
        canLoad: [authGuard, roleGuard],
        data: {
            expectedRole: [ROLE.MANAGER],
        },
        loadChildren: () => import('../manager.module/manager.module').then((m) => m.ManagerModule),
    },
    {
        path: 'dashboard-production',
        canLoad: [authGuard, roleGuard],
        data: {
            expectedRole: [ROLE.PRODUCTION],
        },
        loadChildren: () => import('../production.module/production.module').then((m) => m.ProductionModule),
    },
    {
        path: 'dashboard-customer',
        canLoad: [authGuard, roleGuard],
        data: {
            expectedRole: [ROLE.CUSTOMER],
        },
        loadChildren: () => import('../customer.module/customer.module').then((m) => m.CustomerModule),
    },
    {
        path: 'about',
        component: AboutComponent,
        canActivate: [authGuard, roleGuard],
        data: {
            expectedRole: [ROLE.ADMIN, ROLE.DOCTOR, ROLE.MANAGER, ROLE.CUSTOMER],
        },
    },
    {
        path: 'u2f-management',
        component: RegisterKeyComponent,
        canActivate: [authGuard, roleGuard],
        data: {
            expectedRole: [ROLE.ADMIN, ROLE.DOCTOR, ROLE.MANAGER, ROLE.CUSTOMER],
        },
    },
    {
        path: 'issue/:id',
        component: IssueComponent,
        resolve: {
            issue: issuePageResolver,
        },
    },
    {
        path: '**',
        component: PageNotFoundComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
