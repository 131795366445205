<label *ngIf="label">{{ label }}</label>
<div class="value" [ngClass]="inputClass" [class.placeholder]="presentedValue === placeholder" (click)="onClick($event)">
    {{ presentedValue }}
    <i [ngClass]="'aico-triangle' + (opened ? 'Up' : 'Down')"></i>
</div>
<div class="dropdown-menu" [class.opened]="opened">
    <div class="scrollable">
        <div
            class="listItem"
            [class.preselected]="preselected === _list.indexOf(item)"
            id="{{ item }}"
            *ngFor="let item of _list; let i = index"
            #listItem
            (click)="select(item)"
            (mouseover)="preselected = _list.indexOf(item)"
        >
            {{ item }}
        </div>
    </div>
</div>
