<label>{{ label }}</label>
<input
    type="text"
    autocomplete="off"
    [ngClass]="inputClass"
    [max]="maxDate"
    [min]="minDate"
    [matDatepicker]="picker"
    placeholder="{{ placeholder }}"
    name="date"
    (dateChange)="select($event)"
/>
<i class="aico-calendar"></i>
<mat-datepicker #picker></mat-datepicker>
