import { BehaviorSubject } from 'rxjs';
import { User } from './user.class';
import { IUser, IUserFrontend } from '../interfaces';

export class UserFrontend<T> extends User<T> implements Partial<IUserFrontend> {
    token: string | undefined;
    refreshToken: string | undefined;
    public source: BehaviorSubject<UserFrontend<T>>;
    constructor() {
        super();
        this.source = new BehaviorSubject<UserFrontend<T>>(this);
    }
    public setModel(model: IUser<T> & Partial<IUserFrontend>): void {
        this._id = model._id;
        this.role = model.role;
        this.username = model.username;
        this.roleProperties = model.roleProperties;
        this.token = model.token;
        this.refreshToken = model.refreshToken;
        this.source.next(this);
    }
    public getModel(clearTokens?: boolean): IUser<T> & Partial<IUserFrontend> {
        let model: IUser<T> & Partial<IUserFrontend> = {
            _id: this._id,
            role: this.role,
            username: this.username,
            roleProperties: this.roleProperties,
            token: this.token,
            refreshToken: this.refreshToken,
        };
        if (clearTokens) {
            delete model.token;
            delete model.refreshToken;
        }
        return model;
    }
    public set tokens(tokens: { token: string; refreshToken?: string }) {
        this.loadWithJWT(tokens.token);
        this.token = tokens.token;
        this.refreshToken = tokens.refreshToken;
    }
}
