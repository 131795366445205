import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { CalibrationTestComponent } from '../calibration-test-component';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CALIBRATION_COMMAND, CALIBRATION_TEST, ICalibrationResult, ISensorCalibration, ISensorValue } from 'common-lib';
import { SensorCalibrationFrontend } from '../../../../classes/calibration-tests/sensor-test.model';
import { CommonBulbicamService } from '../../../../services/bulbiCam.service';
import { CommonSocketService } from '../../../../services/socket.service';
import { CommonConfigService } from '../../../../services/config.service';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';

@Component({
    selector: 'sensor-test',
    templateUrl: 'sensor-test.component.html',
    styleUrls: ['sensor-test.component.scss', '../reusable-styles.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule],
})
export class SensorTestComponent extends CalibrationTestComponent implements OnInit, AfterViewInit {
    @ViewChild('svgChart') svgChart: ElementRef;
    results: (ICalibrationResult<SensorCalibrationFrontend> & { isTemp?: boolean })[] = [];
    tempCalibrations: ISensorValue[] = [];
    isTestRunning = false;
    isSavingResults = false;

    redColor = '#EB5757';
    yellowColor = '#F2C94C';
    blueColor = '#2D9CDB';

    osFrontColor = '#bb0f6d';
    osMidColor = '#00b031';
    osRearColor = '#d5d939';

    odRearColor = '#e48932';
    odMidColor = '#00dcdb';
    odFrontColor = '#e60527';

    width: number;
    height: number;
    selectedIndex = -1;

    margin = 20;

    svg: any;
    svgInner: any;

    yScale: any;
    xScale: any;
    yAxis: any;
    xAxis: any;

    constructor(
        @Inject('BulbicamService') private bulbicamService: CommonBulbicamService,
        @Inject('SocketService') private socketService: CommonSocketService,
        @Inject('ConfigService') public configService: CommonConfigService,
        private route: ActivatedRoute
    ) {
        super(CALIBRATION_TEST.SENSOR_TEST);
    }

    ngOnInit(): void {
        const resolvedData: {
            calibrationResults: ICalibrationResult<ISensorCalibration>[];
        } = this.route.snapshot.data['calibrationResults'];
        this.handleCalibrationTestsResults(resolvedData.calibrationResults);
    }

    async messageHandler(data: any): Promise<void> {
        switch (data.message_type) {
            case CALIBRATION_COMMAND.START: {
                this.isTestRunning = true;
                const results = new SensorCalibrationFrontend(this.bulbicamService);
                const calibrationTest: ICalibrationResult<SensorCalibrationFrontend> & {
                    isTemp?: boolean | undefined;
                } = {
                    createdAt: Date.now(),
                    results: results,
                    test: data.testType,
                    isTemp: true,
                };
                this.results.unshift(calibrationTest);
                break;
            }

            case CALIBRATION_COMMAND.DATA_PACKAGE: {
                this.results[0].results?.calibrations.push({
                    xPoint: data?.x,
                    yFrontPoint: data?.y1,
                    yMidPoint: data?.y2,
                    yRearPoint: data?.y3,
                    eye: data?.eye,
                });
                break;
            }

            case CALIBRATION_COMMAND.STOP: {
                this.socketService.socket?.off(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);
                const [odSubArray, osSubArray] = [
                    this.results[0].results?.calibrations.filter((d) => d.eye === 0),
                    this.results[0].results?.calibrations.filter((d) => d.eye === 1),
                ];
                const [odRearHalfThreshold, odMidHalfThreshold, odFrontHalfThreshold, osRearHalfThreshold, osMidHalfThreshold, osFrontHalfThreshold] = [
                    this.calculateMaxThreshold(this.results[0].results?.calibrations!, 0, 'yRearPoint'),
                    this.calculateMaxThreshold(this.results[0].results?.calibrations!, 0, 'yMidPoint'),
                    this.calculateMaxThreshold(this.results[0].results?.calibrations!, 0, 'yFrontPoint'),
                    this.calculateMaxThreshold(this.results[0].results?.calibrations!, 1, 'yRearPoint'),
                    this.calculateMaxThreshold(this.results[0].results?.calibrations!, 1, 'yMidPoint'),
                    this.calculateMaxThreshold(this.results[0].results?.calibrations!, 1, 'yFrontPoint'),
                ];

                this.results[0]!.results!.odRearHalfThreshold = odRearHalfThreshold;
                this.results[0]!.results!.odMidHalfThreshold = odMidHalfThreshold;
                this.results[0]!.results!.odFrontHalfThreshold = odFrontHalfThreshold;
                this.results[0]!.results!.osRearHalfThreshold = osRearHalfThreshold;
                this.results[0]!.results!.osMidHalfThreshold = osMidHalfThreshold;
                this.results[0]!.results!.osFrontHalfThreshold = osFrontHalfThreshold;
                this.results[0]!.results!.osSampleSize = osSubArray?.length;
                this.results[0]!.results!.odSampleSize = odSubArray?.length;

                this.drawChart(0);

                this.isTestRunning = false;
                this.selectedIndex = 0;
                break;
            }

            default:
                break;
        }
    }

    get isUnsavedResultExists(): boolean {
        return !!this.results.find((r) => r.isTemp);
    }

    private handleCalibrationTestsResults(calibrationResults: ICalibrationResult<ISensorCalibration>[]): void {
        this.results = calibrationResults
            .map((result) => {
                const sensorCalibrationResult = new SensorCalibrationFrontend(this.bulbicamService);
                sensorCalibrationResult.setModel(result.results!);
                result.results = sensorCalibrationResult;
                return result as ICalibrationResult<SensorCalibrationFrontend>;
            })
            .sort((a, b) => b.createdAt - a.createdAt);
    }

    async deleteInputResults() {
        await firstValueFrom(this.bulbicamService.deleteCalibrationResult(this.results[this.selectedIndex].test, this.results[this.selectedIndex].createdAt));

        const calibrationResults = await firstValueFrom(this.bulbicamService.getCalibrationResults<ISensorCalibration>([this.calibrationType]));
        this.handleCalibrationTestsResults(calibrationResults);

        if (this.results[this.selectedIndex]) this.selectActive(this.selectedIndex);
        else if (this.results[this.selectedIndex - 1]) this.selectActive(this.selectedIndex - 1);
        else this.selectActive(-1);
    }

    toggleTest(): void {
        if (this.isTestRunning) {
            firstValueFrom(
                this.bulbicamService.sendCalibrationCommand({
                    test: this.calibrationType,
                    command: CALIBRATION_COMMAND.STOP,
                })
            );
        } else {
            if (this.isUnsavedResultExists) this.results.shift();
            this.isTestRunning = true;
            this.socketService.socket?.on(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);
            firstValueFrom(
                this.bulbicamService.sendCalibrationCommand({
                    test: this.calibrationType,
                    command: CALIBRATION_COMMAND.START,
                })
            );
        }
    }

    ngAfterViewInit(): void {
        this.width = this.svgChart.nativeElement.getBoundingClientRect().width;
        this.height = this.svgChart.nativeElement.getBoundingClientRect().height;
        this.initChart(this.width, this.height);
    }

    initChart(width: number = 300, height: number): void {
        this.svg = d3.select(this.svgChart?.nativeElement).attr('id', 'svgtest').attr('width', width).attr('height', height);

        this.svgInner = this.svg.append('svg').style('transform', `translate(${this.margin}px, ${this.margin}px)`);

        this.yScale = d3
            .scaleLinear()
            .domain([0.1, 0])
            .range([2 * this.margin, height - this.margin]);

        this.xScale = d3
            .scaleLinear()
            .domain([0, 1])
            .range([2 * this.margin, width - 2 * this.margin]);

        this.yAxis = this.svgInner
            .append('g')
            .attr('id', 'y-axis')
            .attr('class', 'axis-grey')
            .attr('stroke', 'grey')
            .style('transform', `translate(${2 * this.margin}px, -20px)`);

        this.xAxis = this.svgInner
            .append('g')
            .attr('id', 'x-axis')
            .attr('class', 'axis-grey')
            .attr('stroke', 'grey')
            .style('transform', `translate(0px, ${height - 2 * this.margin}px)`);

        const yAxis = d3.axisLeft(this.yScale);
        const xAxis = d3.axisBottom(this.xScale);

        this.yAxis.call(yAxis);
        this.xAxis.call(xAxis);

        this.svgInner
            .append('text')
            .attr('id', 'text-label')
            .text(`RGB`)
            .attr('dx', this.xScale(1))
            .attr('dy', this.yScale(0))
            .attr('stroke', 'white')
            .attr('stroke-width', '1px')
            .attr('font-size', '12px')
            .style('transform', `translate(0px, 15px)`);

        this.svgInner
            .append('text')
            .attr('id', 'text-label')
            .text(`Sensor Response`)
            .attr('dx', this.xScale(0))
            .attr('dy', this.yScale(0.1))
            .attr('stroke', 'white')
            .attr('stroke-width', '1px')
            .attr('font-size', '12px')
            .style('transform', `translate(-25px, -30px)`);
    }

    drawChart(index: number): void {
        d3.selectAll('#line').remove();
        d3.selectAll('#haflThresholdCircle').remove();

        const lineDefined = d3.line().defined(function (d) {
            return d[1] !== null;
        });

        const result = this.results[index]?.results;

        this.yScale = d3
            .scaleLinear()
            .domain([
                Math.max(
                    Math.max(...result?.calibrations.map((d) => d.yRearPoint)!),
                    Math.max(...result?.calibrations.map((d) => d.yMidPoint)!),
                    Math.max(...result?.calibrations.map((d) => d.yFrontPoint)!)
                ),
                0,
            ])
            .range([2 * this.margin, this.height - this.margin]);

        const yAxis = d3.axisLeft(this.yScale);

        this.yAxis.call(yAxis);

        this.xScale = d3
            .scaleLinear()
            .domain([0, Math.max(...result?.calibrations.map((d) => d.xPoint)!)])
            .range([2 * this.margin, this.width - 2 * this.margin]);

        const xAxis = d3.axisBottom(this.xScale);

        this.xAxis.call(xAxis);

        const odRearPoints: [number, number][] = this.results[index]
            .results!.calibrations.filter((d) => d.eye === 0)
            .map((d) => {
                return [this.xScale(d.xPoint), this.yScale(d.yRearPoint)];
            });

        const odMidPoints: [number, number][] = this.results[index]
            .results!.calibrations.filter((d) => d.eye === 0)
            .map((d) => {
                return [this.xScale(d.xPoint), this.yScale(d.yMidPoint)];
            });

        const odFrontPoints: [number, number][] = this.results[index]
            .results!.calibrations.filter((d) => d.eye === 0)
            .map((d) => {
                return [this.xScale(d.xPoint), this.yScale(d.yFrontPoint)];
            });

        const osRearPoints: [number, number][] = this.results[index]
            .results!.calibrations.filter((d) => d.eye === 1)
            .map((d) => {
                return [this.xScale(d.xPoint), this.yScale(d.yRearPoint)];
            });

        const osMidPoints: [number, number][] = this.results[index]
            .results!.calibrations.filter((d) => d.eye === 1)
            .map((d) => {
                return [this.xScale(d.xPoint), this.yScale(d.yMidPoint)];
            });

        const osFrontPoints: [number, number][] = this.results[index]
            .results!.calibrations.filter((d) => d.eye === 1)
            .map((d) => {
                return [this.xScale(d.xPoint), this.yScale(d.yFrontPoint)];
            });

        this.svgInner
            .append('path')
            .attr('id', `line`)
            .style('fill', 'none')
            .style('stroke', this.odRearColor)
            .style('stroke-width', '1px')
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('d', lineDefined(odRearPoints));

        this.svgInner
            .append('path')
            .attr('id', `line`)
            .style('fill', 'none')
            .style('stroke', this.odMidColor)
            .style('stroke-width', '1px')
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('d', lineDefined(odMidPoints));

        this.svgInner
            .append('path')
            .attr('id', `line`)
            .style('fill', 'none')
            .style('stroke', this.odFrontColor)
            .style('stroke-width', '1px')
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('d', lineDefined(odFrontPoints));

        this.svgInner
            .append('path')
            .attr('id', `line`)
            .style('fill', 'none')
            .style('stroke', this.osRearColor)
            .style('stroke-width', '1px')
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('d', lineDefined(osRearPoints));

        this.svgInner
            .append('path')
            .attr('id', `line`)
            .style('fill', 'none')
            .style('stroke', this.osMidColor)
            .style('stroke-width', '1px')
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('d', lineDefined(osMidPoints));

        this.svgInner
            .append('path')
            .attr('id', `line`)
            .style('fill', 'none')
            .style('stroke', this.osFrontColor)
            .style('stroke-width', '1px')
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('d', lineDefined(osFrontPoints));

        this.svgInner
            .append('circle')
            .attr('id', 'haflThresholdCircle')
            .attr('cx', this.xScale(result?.odFrontHalfThreshold?.xValue))
            .attr('cy', this.yScale(result?.odFrontHalfThreshold?.yValue))
            .attr('r', 3)
            .attr('stroke', this.odFrontColor)
            .attr('fill', this.odFrontColor)
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('stroke-width', '2px');

        this.svgInner
            .append('circle')
            .attr('id', 'haflThresholdCircle')
            .attr('cx', this.xScale(result?.odMidHalfThreshold?.xValue))
            .attr('cy', this.yScale(result?.odMidHalfThreshold?.yValue))
            .attr('r', 3)
            .attr('stroke', this.odMidColor)
            .attr('fill', this.odMidColor)
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('stroke-width', '2px');

        this.svgInner
            .append('circle')
            .attr('id', 'haflThresholdCircle')
            .attr('cx', this.xScale(result?.odRearHalfThreshold?.xValue))
            .attr('cy', this.yScale(result?.odRearHalfThreshold?.yValue))
            .attr('r', 3)
            .attr('stroke', this.odRearColor)
            .attr('fill', this.odRearColor)
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('stroke-width', '2px');

        this.svgInner
            .append('circle')
            .attr('id', 'haflThresholdCircle')
            .attr('cx', this.xScale(result?.osFrontHalfThreshold?.xValue))
            .attr('cy', this.yScale(result?.osFrontHalfThreshold?.yValue))
            .attr('r', 3)
            .attr('stroke', this.osFrontColor)
            .attr('fill', this.osFrontColor)
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('stroke-width', '2px');

        this.svgInner
            .append('circle')
            .attr('id', 'haflThresholdCircle')
            .attr('cx', this.xScale(result?.osMidHalfThreshold?.xValue))
            .attr('cy', this.yScale(result?.osMidHalfThreshold?.yValue))
            .attr('r', 3)
            .attr('stroke', this.osMidColor)
            .attr('fill', this.osMidColor)
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('stroke-width', '2px');

        this.svgInner
            .append('circle')
            .attr('id', 'haflThresholdCircle')
            .attr('cx', this.xScale(result?.osRearHalfThreshold?.xValue))
            .attr('cy', this.yScale(result?.osRearHalfThreshold?.yValue))
            .attr('r', 3)
            .attr('stroke', this.osRearColor)
            .attr('fill', this.osRearColor)
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('stroke-width', '2px');
    }

    override async saveResults(): Promise<void> {
        this.isSavingResults = true;
        try {
            await this.results[0]!.results!.saveModel();
            this.isSavingResults = false;
            this.results[0].isTemp = false;
        } catch (e) {
            console.error(e);
            this.isSavingResults = false;
            return;
        }

        try {
            const allTests = await firstValueFrom(this.bulbicamService.getCalibrationResults<ISensorCalibration>([this.calibrationType]));
            this.handleCalibrationTestsResults(allTests);
        } catch (e) {
            console.error(e);
            return;
        }
    }

    selectActive(index: number): void {
        this.selectedIndex = index;
        if (index < 0) return;
        this.drawChart(index);
    }

    calculateMaxThreshold(array: ISensorValue[], eye: number, side: string): { xValue: number; yValue: number } {
        const filteredArray = array.filter((d) => d.eye === eye);
        const maxFrame = filteredArray.reduce((a, b) => (a[side as keyof typeof a] > b[side as keyof typeof b] ? a : b));

        return { xValue: maxFrame.xPoint, yValue: maxFrame[side as keyof typeof maxFrame] };
    }

    customOnDestroy(): void {}
}
