import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { CustomDateAdapter } from 'marketplace/frontend/src/app/adapters/date.adapter';
import {
    CustomerDetailsComponent,
    DatepickerComponent,
    MultiselectComponent,
    PaginatorComponent,
    PointDetailsComponent,
    PointOverviewComponent,
    PointsListComponent,
    RepresentativesComponent,
} from 'marketplace/frontend/src/app/components';
import { TimeZoneDatePipe } from 'marketplace/frontend/src/app/pipes/dateTzPipe.pipe';
import { EnumToArrayPipe } from 'marketplace/frontend/src/app/pipes/enumToarray.pipe';
import { MaterialModule } from '../material.module';
import { ImageCarouselComponent, SingleselectComponent, TooltipDirective } from 'angular-staff';

export const APP_DATE_FORMATS = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'numeric' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    },
};

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, HttpClientModule, MaterialModule, RouterModule, SingleselectComponent, ImageCarouselComponent, TooltipDirective],
    declarations: [
        DatepickerComponent,
        MultiselectComponent,
        CustomerDetailsComponent,
        PaginatorComponent,
        PointDetailsComponent,
        PointsListComponent,
        EnumToArrayPipe,
        TimeZoneDatePipe,
        PointOverviewComponent,
        RepresentativesComponent,
    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule,
        DatepickerComponent,
        MultiselectComponent,
        SingleselectComponent,
        MaterialModule,
        TooltipDirective,
        CustomerDetailsComponent,
        PaginatorComponent,
        PointDetailsComponent,
        PointsListComponent,
        EnumToArrayPipe,
        TimeZoneDatePipe,
        PointOverviewComponent,
        RepresentativesComponent,
        RouterModule,
        ImageCarouselComponent,
    ],
    providers: [
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: APP_DATE_FORMATS,
        },
    ],
})
export class SharedModule {}
