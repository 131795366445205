import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { fromEvent, Subscription } from 'rxjs';
import { filter, map, scan, tap } from 'rxjs/operators';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';
import { AUTH_WAYS } from 'common-lib';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    public readonly authWays: typeof AUTH_WAYS = AUTH_WAYS;
    public authForm: FormGroup;
    public pendingUsername: string = '';
    public error: string;
    private subscriptions: Subscription[];

    constructor(private formBuilder: FormBuilder, private communicationService: ServerCommunicationService, private router: Router) {
        this.subscriptions = [];
    }

    ngOnInit() {
        this.communicationService.homeRedirect();
        this.authForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            authWay: ['', Validators.required],
            code: '',
        });
        this.subscriptions.push(
            fromEvent<KeyboardEvent>(document, 'keypress')
                .pipe(
                    map((e) => e.key),
                    scan((acc: string, value) => acc + value?.toLowerCase()),
                    filter((sequence) => typeof sequence === 'string')
                )
                .subscribe((sequence) => {
                    if (sequence?.endsWith('inviteadmin')) {
                        this.communicationService.sendInitialInvitation();
                    }
                })
        );
        this.subscriptions.push(
            this.authForm
                .get('code')!
                .valueChanges.pipe(
                    tap((value) => {
                        if (isNaN(value.charAt(value.length - 1))) {
                            // If the last character is non-numeric, remove it
                            this.authForm.get('code')!.setValue(value.slice(0, -1), { emitEvent: false });
                        }
                    }),
                    filter((value) => /^[0-9]{4}$/.test(value)), // Filter for valid 4-digit numeric values
                    tap(async (value: string) => {
                        try {
                            const token = await this.communicationService.sockets.UnsecuredSocket.checkSmsVerificationCode(this.pendingUsername, value);
                            await this.communicationService.completeLogin(token);
                            this.communicationService.homeRedirect();
                        } catch (error: any) {
                            this.error = error.error;
                        }
                    })
                )
                .subscribe()
        );
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    public async login() {
        try {
            const { username, password, authWay } = this.authForm.getRawValue();
            const selectedAuthWay: AUTH_WAYS = AUTH_WAYS[authWay as keyof typeof AUTH_WAYS];
            await this.communicationService.sockets.UnsecuredSocket.connect();
            switch (selectedAuthWay) {
                case AUTH_WAYS.U2F:
                    this.authForm.disable();
                    const publicKey: PublicKeyCredentialRequestOptions = await this.communicationService.sockets.UnsecuredSocket.loginWithU2F(username, password);
                    publicKey.challenge = this.communicationService.sockets.UnsecuredSocket.decode(publicKey.challenge as any);
                    for (let allowCred of publicKey.allowCredentials!) {
                        allowCred.id = this.communicationService.sockets.UnsecuredSocket.decode(allowCred.id as any);
                    }
                    const publicKeyCredential: Credential | null = await navigator.credentials.get({ publicKey });
                    const encodedpublicKeyCredential = this.communicationService.sockets.UnsecuredSocket.publicKeyCredentialToJSON(publicKeyCredential);
                    let token: string = await this.communicationService.sockets.UnsecuredSocket.sendWebAuthResponse(encodedpublicKeyCredential);
                    await this.communicationService.completeLogin(token);
                    this.communicationService.homeRedirect();
                    break;
                case AUTH_WAYS.SMS:
                    this.authForm.get('username')?.disable();
                    this.authForm.get('password')?.disable();
                    this.authForm.get('authWay')?.disable();
                    const error = await this.communicationService.sockets.UnsecuredSocket.loginWithSms(username, password);
                    if (error) {
                        alert(error);
                    } else {
                        this.pendingUsername = username;
                    }
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    }
}
