import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';
import { IAdminRoleProperties, ROLE } from 'common-lib';
import { UserDetailsComponent } from '../user-details.component';
import { passwordEqualityValidator } from 'angular-staff';

@Component({
    selector: 'admin-details',
    templateUrl: 'admin-details.component.html',
    styleUrls: ['../user-details.component.scss'],
})
export class AdminDetailsComponent extends UserDetailsComponent<IAdminRoleProperties> {
    public registerMode: boolean;
    constructor(route: ActivatedRoute, router: Router, location: Location, formBuilder: FormBuilder, communicationService: ServerCommunicationService) {
        super(ROLE.ADMIN, formBuilder, route, location, router, communicationService);
        this.form = this.formBuilder.group(
            {
                email: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
                phone: ['', [Validators.required, Validators.pattern(/^\+?[\d()\-\s]*$/)]],
                password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}')]],
                passwordConfirmation: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}')]],
                haveU2fKey: ['', Validators.required],
            },
            {
                validators: passwordEqualityValidator,
            }
        );
    }
    async buildForm(): Promise<void> {
        this.form.patchValue({
            email: this.user.roleProperties.email,
            phone: this.user.roleProperties.phone,
        });
        if (!this.registerMode) {
            this.form.disable();
        }
    }
    public fillRoleProps(rawFormValue: any): IAdminRoleProperties {
        return {
            firstName: rawFormValue.firstName,
            lastName: rawFormValue.lastName,
            email: rawFormValue.email,
            phone: rawFormValue.phone,
        };
    }
}
