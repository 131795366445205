import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'calibration',
    templateUrl: 'calibration.component.html',
    styleUrls: ['calibration.component.scss'],
})
export class CalibrationComponent implements OnDestroy {
    private subscriptions: Array<Subscription> = [];
    constructor() {}

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
