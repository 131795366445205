import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';

@Component({
    selector: 'paginator',
    templateUrl: 'paginator.component.html',
    styleUrls: ['paginator.component.scss'],
})
export class PaginatorComponent implements OnInit, OnDestroy {
    public pagesCnt: number;
    public page: BehaviorSubject<number>;
    private _totalItemsCnt: BehaviorSubject<number>;
    constructor(private serverCommunicationService: ServerCommunicationService) {
        this.pagesCnt = 8;
        this.page = new BehaviorSubject<number>(1);
        this._totalItemsCnt = new BehaviorSubject<number>(0);
    }
    ngOnInit(): void {
        this._totalItemsCnt.subscribe((itemsCnt) => {
            this.pagesCnt = Math.ceil(itemsCnt / this.serverCommunicationService.itemsPerPage);
        });
    }
    ngOnDestroy(): void {}
    public set totalItemsCnt(cnt: number) {
        this._totalItemsCnt.next(cnt);
    }
    public start(): void {
        if (this.page.value !== 1) {
            this.page.next(1);
        }
    }
    public prev(): void {
        if (this.page.value !== 1) {
            this.page.next(this.page.value - 1);
        }
    }
    public next(): void {
        if (this.page.value < this.pagesCnt) {
            this.page.next(this.page.value + 1);
        }
    }
    public end(): void {
        if (this.page.value < this.pagesCnt) {
            this.page.next(this.pagesCnt);
        }
    }
}
