import { firstValueFrom } from 'rxjs';
import { LuxCalibration } from 'common-lib';
import { CommonBulbicamService } from '../../services';

export class LuxCalibrationFrontend extends LuxCalibration {
    constructor(private bulbicamService: CommonBulbicamService) {
        super();
    }

    public async saveModel(): Promise<void> {
        await firstValueFrom(this.bulbicamService.saveCalibrationResult({ test: this.type, createdAt: Date.now(), results: this.getModel() }));
    }
}
