import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { IScreenshotFeedback } from 'common-lib';
import { ServerCommunicationService } from '../services/serverCommunication.service';
import { ScreenshotFeedbackFrontend } from '../classes/screenshotFeedbackFrontend.class';
import { FileService } from '../services/file.service';

export const issuePageResolver: ResolveFn<IScreenshotFeedback> = async (route: ActivatedRouteSnapshot) => {
    const scs = inject(ServerCommunicationService);
    const fileService = inject(FileService);
    const issueID: string = route.params['id'];
    const issueModel = await scs.sockets.IssuesSocket.findByID(issueID);
    const issue: ScreenshotFeedbackFrontend = new ScreenshotFeedbackFrontend();
    issue.model = issueModel;
    await issue.download(fileService);
    return issue;
};
