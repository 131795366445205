import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';
import { ROLE, UserFrontend } from 'common-lib';

@Component({
    template: '',
})
export abstract class UserDetailsComponent<T> implements OnInit {
    public user: UserFrontend<T>;
    public form: FormGroup;
    public abstract registerMode: boolean;
    protected constructor(
        role: ROLE,
        protected formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private location: Location,
        private router: Router,
        protected communicationService: ServerCommunicationService
    ) {
        this.user = new UserFrontend<T>();
        this.user.role = role;
    }

    async ngOnInit() {
        const id: string | null = this.route.snapshot.paramMap.get('id'),
            email: string | null = this.route.snapshot.queryParamMap.get('email'),
            role: string | null = this.route.snapshot.queryParamMap.get('role');
        if (id) {
            this.registerMode = false;
            this.user.setModel(await this.communicationService.sockets.UserSocket.findByID<T>(id));
        } else if (this.user.role === ROLE.REMOTEPOINT) {
            this.registerMode = true;
        } else if (email && role) {
            this.registerMode = true;
            this.user.role = ROLE[role as keyof typeof ROLE];
            this.user.username = email;
            (<any>this.user.roleProperties).email = email;
        }
        await this.buildForm();
    }

    abstract buildForm(): Promise<void>;
    abstract fillRoleProps(rawFormValue: any): any;

    public goBack(): void {
        this.location.back();
    }

    public async registerUser(): Promise<void> {
        try {
            this.form.disable();
            const rawFormValue = this.form.getRawValue(),
                model: any = {
                    _id: this.user._id,
                    username: rawFormValue.email,
                    role: this.user.role,
                    password: rawFormValue.password,
                    roleProperties: this.fillRoleProps(rawFormValue),
                };
            if (this.user._id) {
                this.user.setModel(await this.communicationService.sockets.UserSocket.update<T>(model));
            } else {
                this.user.setModel(await this.communicationService.sockets.UserSocket.create<T>(model));
            }
            // Since we are not going to use U2F keys we dont need to register them
            if (this.user.role === ROLE.REMOTEPOINT || rawFormValue.haveU2fKey === 'no') this.router.navigate(['/']);
            this.buildForm();
        } catch (error) {}
    }

    public async registerKey(): Promise<void> {
        await this.communicationService.sockets.UnsecuredSocket.connect();
        await this.communicationService.sockets.UnsecuredSocket.registerKey(this.user._id as string);
        this.communicationService.logout();
        this.router.navigate(['/']);
    }

    public clear() {
        this.buildForm();
    }
}
