<form [formGroup]="authForm">
    <div class="inputWrapper">
        <label>{{ 'username' }}</label>
        <input class="smallInput" type="text" formControlName="username" name="username" placeholder="{{ 'Email address' }}" />
    </div>
    <div class="inputWrapper">
        <label>{{ 'password' }}</label>
        <input class="smallInput" type="password" formControlName="password" name="password" />
    </div>
    <a [routerLink]="['/auth/forgot-password']"> {{ 'forgot password' }}</a>
    <div>
        <label class="radiobutton type1" *ngFor="let way of authWays | keyvalue">
            <input type="radio" formControlName="authWay" [value]="way.key" />
            <span></span>
            <h5>{{ way.value }}</h5>
        </label>
    </div>
    <button class="normalButton primaryButton" [disabled]="!authForm.valid || pendingUsername" (click)="login()">
        {{ 'login' }}
    </button>
    @if (pendingUsername) {
    <div class="inputWrapper">
        <label>{{ '4 digits SMS code' }}</label>
        <input class="smallInput" type="text" formControlName="code" placeholder="{{ 'Enter code here' }}" />
    </div>
    }
    <div class="error" *ngIf="error">
        {{ error }}
    </div>
    <a [routerLink]="['/auth/register']"> {{ 'register' }}</a>
</form>
<div class="logo"></div>
