import { Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';

@Component({
    selector: 'mp-root',
    styleUrls: ['root.component.scss'],
    templateUrl: 'root.component.html',
})
export class RootComponent {
    private loading: boolean = true;
    constructor(private router: Router) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.loading = true;
            }
            if (event instanceof NavigationEnd) {
                this.loading = false;
            }
            // Set loading state to false in both of the below events to hide the spinner in case a request fails
            if (event instanceof NavigationCancel) {
                this.loading = false;
            }
            if (event instanceof NavigationError) {
                this.loading = false;
            }
        });
    }
}
