import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IssueComponent, PageNotFoundComponent } from 'marketplace/frontend/src/app/components';
import { AboutComponent } from 'marketplace/frontend/src/app/components/about/about.component';
import { MyheaderComponent } from 'marketplace/frontend/src/app/components/header/myheader.component';
import { MenuComponent } from 'marketplace/frontend/src/app/components/menu/menu.component';
import { RootComponent } from 'marketplace/frontend/src/app/components/root/root.component';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';
import { SharedModule } from '../shared.module/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { FileService } from '../../services/file.service';

@NgModule({
    imports: [BrowserModule, AppRoutingModule, SharedModule, BrowserAnimationsModule],
    declarations: [PageNotFoundComponent, AboutComponent, RootComponent, MyheaderComponent, MenuComponent, IssueComponent],
    bootstrap: [RootComponent],
    providers: [
        ServerCommunicationService,
        {
            provide: APP_INITIALIZER,
            useFactory: (communicationService: ServerCommunicationService) => {
                return () => communicationService.load();
            },
            deps: [ServerCommunicationService],
            multi: true,
        },
        FileService,
        {
            provide: 'FileService',
            useExisting: FileService,
        },
        {
            provide: 'AuthenticationService',
            useExisting: ServerCommunicationService,
        },
    ],
})
export class AppModule {}
