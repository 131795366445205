<form [formGroup]="filterForm">
    <div class="inputs">
        <singleselect [label]="'Choose time period:'" [list]="timeRanges" [inputClass]="'normalInput'" formControlName="range"> </singleselect>
        <datepicker [label]="'From:'" [placeholder]="''" [maxDate]="maxDateForStartDatePicker" [inputClass]="'normalInput'" formControlName="startDate" #startPicker></datepicker>
        <datepicker
            [label]="'To:'"
            [placeholder]="''"
            [maxDate]="maxDate"
            [minDate]="minDateForEndDatePicker"
            [inputClass]="'normalInput'"
            formControlName="endDate"
            #endPicker
        ></datepicker>
    </div>
    <div class="checkboxes">
        <div formGroupName="tests" class="scrollable">
            <h4>Test exports</h4>
            <label class="checkbox normal">
                <input type="checkbox" (click)="selectAllCamTestTypes($event)" />
                <span></span>
                <h5>{{ 'All cam tests' }}</h5>
            </label>
            <div formGroupName="camTests">
                <div *ngFor="let camTest of tests.camTests">
                    <label class="checkbox normal">
                        <input type="checkbox" [formControlName]="camTest.type" />
                        <span></span>
                        <h5>{{ camTest.name }}</h5>
                    </label>
                </div>
            </div>
            <label class="checkbox normal">
                <input type="checkbox" (click)="selectAllNotCamTestTypes($event)" />
                <span></span>
                <h5>{{ 'All other devices tests' }}</h5>
            </label>
            <div formGroupName="notCamTests">
                <div *ngFor="let noCamTest of tests.notCamTests">
                    <label class="checkbox normal">
                        <input type="checkbox" [formControlName]="noCamTest.type" />
                        <span></span>
                        <h5>{{ noCamTest.name }}</h5>
                    </label>
                </div>
            </div>
        </div>
        <div>
            <h4>Remote points</h4>
            <label class="checkbox normal">
                <input type="checkbox" (click)="selectAllRemotePoints($event)" />
                <span></span>
                <h5>{{ 'All' }}</h5>
            </label>
            <div formGroupName="points" *ngFor="let point of points">
                <label class="checkbox normal">
                    <input type="checkbox" [formControlName]="point._id.toString()" />
                    <span></span>
                    <h5>{{ point.username }} ({{ point.roleProperties.type || 'no type selected' }})</h5>
                </label>
            </div>
        </div>
        <div>
            <h4>Unit of time</h4>
            <label class="radiobutton type1">
                <input type="radio" formControlName="timeUnit" [value]="'Daily'" />
                <span></span>
                <h5>{{ 'Daily' }}</h5>
            </label>
            <label class="radiobutton type1">
                <input type="radio" formControlName="timeUnit" [value]="'Weekly'" />
                <span></span>
                <h5>{{ 'Weekly' }}</h5>
            </label>
            <label class="radiobutton type1">
                <input type="radio" formControlName="timeUnit" [value]="'Monthly'" />
                <span></span>
                <h5>{{ 'Monthly' }}</h5>
            </label>
        </div>
        <div>
            <h4>Number of...</h4>
            <!-- <label class="radiobutton type1">
                <input type="radio" formControlName="target" [value]="'Patients'" />
                <span></span>
                <h5>{{ 'Patients' }}</h5>
            </label> -->
            <label class="radiobutton type1">
                <input type="radio" formControlName="target" [value]="'Examinations'" />
                <span></span>
                <h5>{{ 'Examinations' }}</h5>
            </label>
            <label class="radiobutton type1">
                <input type="radio" formControlName="target" [value]="'Tests'" />
                <span></span>
                <h5>{{ 'Tests' }}</h5>
            </label>
        </div>
    </div>
    <div class="errors">
        <h1 *ngIf="filterForm.get('startDate')?.hasError('required') || filterForm.get('endDate')?.hasError('required')">Fields Examination 'From' and 'To' are required.</h1>
        <h1 *ngIf="filterForm.hasError('noneTestTypeSelected')">At least one test type should be selected.</h1>
        <h1 *ngIf="filterForm.hasError('noneRemotePointsSelected')">At least one remote point should be selected.</h1>
    </div>
    <button class="primaryButton normalButton" [disabled]="filterForm.invalid || pendingResponce" (click)="export()">
        {{ 'Export' }}
    </button>
</form>
