import { Subject } from 'rxjs';
import { AuthSocket } from './auth.socket';
import { ConfigSocket } from './config.socket';
import { ExaminationSocket } from './examination.socket';
import { IOSocket } from './ioSocket';
import { RemotePointSocket } from './remotePoint.socket';
import { UserSocket } from './user.socket';
import { Manager } from 'socket.io-client';
import { IssuesSocket } from './issues.socket';
import { SessionsSocket } from './sessions.socket';
import { UnsecuredSocket } from './unsecured.socket';

export class SocketsStorage {
    private sockets: Map<string, IOSocket>;
    constructor(manager: Manager, socketError: Subject<Error>) {
        this.sockets = new Map<string, IOSocket>();
        this.sockets.set('Auth', new AuthSocket(manager, socketError));
        this.sockets.set('User', new UserSocket(manager, socketError));
        this.sockets.set('Config', new ConfigSocket(manager, socketError));
        this.sockets.set('Examination', new ExaminationSocket(manager, socketError));
        this.sockets.set('RemotePoint', new RemotePointSocket(manager, socketError));
        this.sockets.set('Issue', new IssuesSocket(manager, socketError));
        this.sockets.set('Sessions', new SessionsSocket(manager, socketError));
        this.sockets.set('Unsecured', new UnsecuredSocket(manager, socketError));
    }
    public async descructor(): Promise<void> {
        this.sockets.forEach((ns) => ns.desctuct());
    }
    public get AuthSocket(): AuthSocket {
        return this.sockets.get('Auth') as AuthSocket;
    }
    public get UserSocket(): UserSocket {
        return this.sockets.get('User') as UserSocket;
    }
    public get ConfigSocket(): ConfigSocket {
        return this.sockets.get('Config') as ConfigSocket;
    }
    public get ExaminationSocket(): ExaminationSocket {
        return this.sockets.get('Examination') as ExaminationSocket;
    }
    public get RemotePointSocket(): RemotePointSocket {
        return this.sockets.get('RemotePoint') as RemotePointSocket;
    }
    public get IssuesSocket(): IssuesSocket {
        return this.sockets.get('Issue') as IssuesSocket;
    }
    public get SessionsSocket(): SessionsSocket {
        return this.sockets.get('Sessions') as SessionsSocket;
    }
    public get UnsecuredSocket(): UnsecuredSocket {
        return this.sockets.get('Unsecured') as UnsecuredSocket;
    }
    public async connect(): Promise<void> {
        try {
            let promises: Promise<void>[] = [];
            this.sockets.forEach((s) => promises.push(s.connect()));
            await Promise.all(promises);
        } catch (error) {
            throw error;
        }
    }
    public async close(): Promise<void> {
        let promises: Promise<void>[] = [];
        this.sockets.forEach((s) => promises.push(s.close()));
        await Promise.all(promises);
    }
}
