import {CALIBRATION_TEST} from '../../enum/calibration.command.enum';
import {ITimingCalibration, ITimingValue} from '../../interfaces/calibration/timingCalibration.interface';
import {CommonCalibrationTest} from './common.calibration.test.model';

export class TimingCalibrationTest implements CommonCalibrationTest, ITimingCalibration {
    type: CALIBRATION_TEST.LONG_TIMING_TEST | CALIBRATION_TEST.SHORT_TIMING_TEST;
    calibrations: ITimingValue[];
    osMinValue?: number | undefined;
    odMinValue?: number | undefined;
    osMaxValue?: number | undefined;
    odMaxValue?: number | undefined;
    osAverageValue?: number | undefined;
    odAverageValue?: number | undefined;
    osSampleSize?: number | undefined;
    odSampleSize?: number | undefined;

    constructor(type: CALIBRATION_TEST.LONG_TIMING_TEST | CALIBRATION_TEST.SHORT_TIMING_TEST) {
        this.type = type;
        this.calibrations = [];
    }

    getModel(): ITimingCalibration {
        return {
            calibrations: this.calibrations,
            osMinValue: this.osMinValue,
            odMinValue: this.odMinValue,
            osMaxValue: this.osMaxValue,
            odMaxValue: this.odMaxValue,
            osAverageValue: this.osAverageValue,
            odAverageValue: this.odAverageValue,
            osSampleSize: this.osSampleSize,
            odSampleSize: this.odSampleSize,
        };
    }
    setModel(model: ITimingCalibration): void {
        this.calibrations = model.calibrations;
        this.osMinValue = model.osMinValue;
        this.odMinValue = model.odMinValue;
        this.osMaxValue = model.osMaxValue;
        this.odMaxValue = model.odMaxValue;
        this.osAverageValue = model.osAverageValue;
        this.odAverageValue = model.odAverageValue;
        this.osSampleSize = model.osSampleSize;
        this.odSampleSize = model.odSampleSize;
    }
}
