import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { from } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';
import { IBulbiPointRoleProperties } from 'common-lib';

@Component({
    selector: 'dashboard-admin',
    templateUrl: 'dashboard-admin.component.html',
    styleUrls: ['dashboard-admin.component.scss'],
})
export class DashboardAdminComponent implements OnInit {
    title = 'admin dashboard';
    constructor(private router: Router, private communicationService: ServerCommunicationService) {}
    ngOnInit() {
        let url = this.router.url.split('/');
        this.changeTitle(url);
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                url = event.url.split('/');
                this.changeTitle(url);
            }
        });
    }
    private changeTitle(url: string[]) {
        if (url.length < 3) {
            this.title = 'admin dashboard';
        } else {
            this.title = 'admin ' + url[2];
        }
    }
    public async refresh() {
        from(this.communicationService.sockets.RemotePointSocket.pointsOnline.value)
            .pipe(
                concatMap(async (pointID) => {
                    const point = await this.communicationService.sockets.UserSocket.findByID<IBulbiPointRoleProperties>(pointID);
                    console.log('Start to refresh data with point ' + point.username + '.');
                    await this.communicationService.sockets.RemotePointSocket.eraseRepresentatives(pointID);
                    console.log('Erasing synchronization marks in point ' + point.username + ' completed.');
                    this.communicationService.sockets.RemotePointSocket.sendSynchronationMessage(pointID);
                    console.log('Synchronization message sent to point ' + point.username + '.');
                })
            )
            .subscribe();
    }
}
