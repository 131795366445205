import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { axisBottom, axisLeft, BaseType, max, scaleLinear, scaleTime, select, stack, zoom as z } from 'd3';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';
import { ROLE, SORTING, IExaminationRepresentative, IBulbiPointRoleProperties, IUser } from 'common-lib';

@Component({
    selector: 'exams-by-day-chart',
    templateUrl: 'exams-by-day-chart.component.html',
    styleUrls: ['exams-by-day-chart.component.scss'],
})
export class ExamsByDayChartComponent implements OnInit, OnDestroy {
    @ViewChild('svg') SVG: ElementRef;
    private subscriptions: Subscription[];
    constructor(private elRef: ElementRef, private communicationService: ServerCommunicationService) {
        this.subscriptions = [];
    }
    async ngOnInit() {
        let responce: {
            filteredExams: IExaminationRepresentative[];
            totalCnt: number;
        } = await this.communicationService.sockets.ExaminationSocket.search({
            itemsPerPage: undefined,
            page: 1,
            orderBy: 'createdAt',
            orderDirection: SORTING.ASC,
            customerID: undefined,
            sourceID: undefined,
            createdAt: {
                startRange: null,
                endRange: null,
            },
        });
        const startRange: Date = moment(responce.filteredExams[0].createdAt).subtract(1, 'day').toDate(),
            endRange: Date = moment(responce.filteredExams[responce.filteredExams.length - 1].createdAt)
                .add(1, 'day')
                .toDate(),
            points: IUser<IBulbiPointRoleProperties>[] = await this.communicationService.sockets.UserSocket.getUsers<IBulbiPointRoleProperties>(ROLE.REMOTEPOINT);
        let data: any[] = [];
        responce.filteredExams.forEach((e, i) => {
            if (
                data.some((d) => {
                    return moment(e.createdAt).isSame(d.date, 'day');
                })
            ) {
                let index: number = data.findIndex((d) => {
                    return moment(e.createdAt).isSame(d.date, 'day');
                });
                data[index][e.sourceID] += 1;
            } else {
                data.push({ date: moment(e.createdAt).startOf('day').toDate() });
                points.forEach((p) => {
                    data[data.length - 1][p._id as string] = 0;
                });
                data[data.length - 1][e.sourceID] += 1;
            }
        });

        const series = stack<{
            date: Date;
        }>()
            .keys(points.map((p) => p._id as string))(data)
            .map((d) => (d.forEach((v: any) => (v.key = d.key)), d));

        // set the dimensions and margins of the graph
        const margin = { top: 30, right: 300, bottom: 30, left: 30 },
            width = this.SVG.nativeElement.clientWidth - margin.left - margin.right,
            height = this.SVG.nativeElement.clientHeight - margin.top - margin.bottom;

        // Set the zoom and Pan features: how much you can zoom, on which part, and what to do when there is a zoom
        const zoom = z()
            .scaleExtent([0.3, 1]) // This control how much you can unzoom (x1) and zoom (x15)
            .extent([
                [0, 0],
                [width, height],
            ])
            .translateExtent([
                [-Infinity, 0],
                [Infinity, height],
            ])
            .on('zoom', updateChart);

        // append the SVG object to the body of the page
        // const SVG = select(this.elRef.nativeElement)
        const SVG = select(this.SVG.nativeElement)
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .call(zoom)
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

        // Add X axis
        let xTicks: number[] = [];
        const x = scaleTime()
            .domain([moment(endRange).subtract(1, 'months').startOf('month'), endRange])
            .range([0, width]);
        const xAxis = SVG.append('g')
            .attr('transform', 'translate(0,' + height + ')')
            .attr('color', 'white')
            .call(axisBottom(x))
            .call(function (selection) {
                // @ts-ignore
                selection.selectAll('.tick').each(function (d: Date, index: number, nodes: BaseType[]) {
                    const shift = x(moment(d).add(12, 'hour').toDate());
                    select(nodes[index]).attr('transform', 'translate(' + shift + ', 0)');
                    xTicks.push(shift);
                });
            });

        // Add Y axis
        let yTicks: (number | undefined)[];
        const y = scaleLinear()
            .domain([0, max(series, (d) => max(d, (d) => d[1])) as number])
            .range([height, 0]);
        const yAxis = SVG.append('g')
            .attr('color', 'white')
            .call(axisLeft(y))
            .call(function (selection) {
                yTicks = selection
                    .selectAll('.tick')
                    .nodes()
                    .map((node, index) => {
                        if (index !== 0) return +select(node).attr('transform').slice(12, -1);
                        return;
                    });
            });

        let gridTicks: { x: number; y: number | undefined }[] = [];
        SVG.append('g').attr('id', 'gridWrapper');
        gridTicks = [];
        xTicks.forEach((x) => {
            yTicks.forEach((y) => {
                gridTicks.push({
                    x: x,
                    y: y,
                });
            });
        });
        let gridWrapper: any = select('#gridWrapper').selectAll('rect').data(gridTicks);
        gridWrapper
            .enter()
            .append('rect')
            .merge(gridWrapper)
            .attr('fill', '#355255')
            .attr('x', (d: any) => d.x)
            .attr('y', (d: any) => d.y)
            .attr('height', '3')
            .attr('width', '3');
        gridWrapper.exit().remove();

        // Add a clipPath: everything out of this area won't be drawn.
        const clip = SVG.append('defs').append('SVG:clipPath').attr('id', 'clip').append('SVG:rect').attr('width', width).attr('height', height).attr('x', 0).attr('y', 0);

        // Create the scatter variable: where both the circles and the brush take place
        const scatter = SVG.append('g').attr('clip-path', 'url(#clip)');

        let testsSet: Map<string, { color: string; count: number }> = new Map<string, { color: string; count: number }>(),
            colorsMap: Map<string, string> = new Map<string, string>();

        let barPadding: number = 4;
        let barWidth: number = x(moment().toDate()) - x(moment().subtract(1, 'days'));

        scatter
            .selectAll('g')
            .data(series)
            .join('g')
            .attr('fill', (d) => {
                if (!testsSet.has(d.key)) {
                    testsSet.set(d.key, { color: '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6), count: 0 });
                }
                return testsSet.get(d.key)!.color;
            })
            .selectAll('rect')
            .data((d) => d)
            .join('rect')
            .attr('x', (d, i) => x(d.data.date) + barPadding)
            .attr('y', (d) => y(d[1]))
            .attr('height', (d) => y(d[0]) - y(d[1]))
            .attr('width', barWidth - 2 * barPadding)
            .append('title')
            .text((d) => {
                if (d[1] - d[0] > 0) {
                    testsSet.forEach((v, k) => {
                        if (k === (<any>d).key) {
                            v.count += d[1] - d[0];
                        }
                    });
                }
                const point = points.find((p) => p._id === (<any>d).key);
                return d.data.date.toLocaleDateString() + ': \n\r ' + point!.username + ` - ${d[1] - d[0]}`;
            });

        // A function that updates the chart when the user zoom and thus new boundaries are available
        // now the user can zoom and it will trigger the function called updateChart
        function updateChart(event: any) {
            // recover the new scale
            let newX = event.transform.rescaleX(x);

            xTicks = [];
            // update axes with these new boundaries
            xAxis.call(axisBottom(newX)).call(function (selection) {
                //@ts-ignore
                selection.selectAll('.tick').each(function (d: Date, index: number, nodes: BaseType[]) {
                    const shift = newX(moment(d).add(12, 'hour').toDate());
                    select(nodes[index]).attr('transform', 'translate(' + shift + ', 0)');
                    xTicks.push(shift);
                });
            });

            gridTicks = [];
            xTicks.forEach((x) => {
                yTicks.forEach((y) => {
                    gridTicks.push({
                        x: x,
                        y: y,
                    });
                });
            });

            let gridWrapper: any = select('#gridWrapper').selectAll('rect').data(gridTicks);
            gridWrapper
                .enter()
                .append('rect')
                .merge(gridWrapper)
                .attr('fill', '#355255')
                .attr('x', (d: any) => d.x)
                .attr('y', (d: any) => d.y)
                .attr('height', '3')
                .attr('width', '3');
            gridWrapper.exit().remove();

            let barWidth: number = newX(moment().toDate()) - newX(moment().subtract(1, 'days'));

            scatter
                .selectAll('g')
                .data(series)
                .join('g')
                .selectAll('rect')
                .data((d) => d)
                .join('rect')
                .attr('x', (d, i) => newX(d.data.date) + barPadding)
                .attr('width', barWidth - 2 * barPadding);
        }

        testsSet.forEach((v, k) => {
            if (v.count > 0) colorsMap.set(k, v.color);
        });

        // Draw legend
        let legend = SVG.selectAll('.legend')
            .data(colorsMap)
            .enter()
            .append('g')
            .attr('class', 'legend')
            .attr('transform', function (d, i) {
                return 'translate(50,' + i * 19 + ')';
            });

        legend
            .append('rect')
            .attr('x', width - 18)
            .attr('width', 18)
            .attr('height', 18)
            .style('fill', function (d, i) {
                return d[1];
            });

        legend
            .append('text')
            .attr('x', width + 5)
            .attr('y', 9)
            .attr('dy', '.35em')
            .style('fill', 'white')
            .style('text-anchor', 'start')
            .text(function (d, i) {
                const point = points.find((p) => p._id === d[0]);
                return point!.username + ': ' + testsSet.get(d[0])!.count;
            });
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
