import { DEVICE } from '../../../../enum/device';
import { TEST_TYPE } from '../../../../enum/test-type';
import { TEST_STAGE } from '../../../../enum/testStage.enum';

export class PostSegmentNidekRs330Test {
    public readonly type: TEST_TYPE = TEST_TYPE.POSTERIOR_SEGMENT_EVALUATION;
    public readonly device: DEVICE = DEVICE.NIDEK_RS330;
    public readonly name: string = 'Posterior segment evaluation';
    public stage: TEST_STAGE;
}
