import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IExaminationRepresentative } from 'common-lib';
import { IDoctorExaminationsSearchTerms } from '../../../../../common/interfaces/doctorExaminationsSearchTerms';
import { IExaminationsSearchTerms } from '../../../../../common/interfaces/examinationsSearchTerms';
import { IManagerExportTerms } from '../../../../../common/interfaces/managerExportTerms.interface';
import { IOSocket } from './ioSocket';
import { Manager } from 'socket.io-client';
import { checkSocketPromise } from '../decorators/checkSocket.decorator';

export class ExaminationSocket extends IOSocket {
    private _updateExaminationsRequestSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    constructor(manager: Manager, socketError: Subject<Error>) {
        super(manager, '/examination', socketError);
    }
    @checkSocketPromise()
    public async addHendlers(resolvable: () => void): Promise<void> {
        this._socket.on('updateExaminationsRequest', () => {
            this._updateExaminationsRequestSource.next(true);
        });
    }
    @checkSocketPromise()
    public getRepresentativeById(representID: string): Promise<IExaminationRepresentative> {
        return new Promise<IExaminationRepresentative>((res, rej) => {
            this._socket.emit('getOne', representID, (responce: IExaminationRepresentative) => {
                res(responce);
            });
        });
    }
    @checkSocketPromise()
    public search(term: IExaminationsSearchTerms): Promise<{ filteredExams: IExaminationRepresentative[]; totalCnt: number }> {
        return new Promise<{ filteredExams: IExaminationRepresentative[]; totalCnt: number }>((res, rej) => {
            this._socket.emit('search', term, (responce: any) => {
                res(responce);
            });
        });
    }
    @checkSocketPromise()
    public export(term: IManagerExportTerms): Promise<{ start: number; end: number; representatives: IExaminationRepresentative[] }[]> {
        return new Promise<{ start: number; end: number; representatives: IExaminationRepresentative[] }[]>((res, rej) => {
            this._socket.emit('export', term, (responce: { start: number; end: number; representatives: IExaminationRepresentative[] }[]) => {
                res(responce);
            });
        });
    }
    @checkSocketPromise()
    public doctorsSearch(term: IDoctorExaminationsSearchTerms): Promise<{ filteredExams: IExaminationRepresentative[]; totalCnt: number }> {
        return new Promise<{ filteredExams: IExaminationRepresentative[]; totalCnt: number }>((res, rej) => {
            this._socket.emit('doctorsSearch', term, (responce: any) => {
                res(responce);
            });
        });
    }
    public get updateExaminationsRequestSource(): Observable<boolean> {
        return this._updateExaminationsRequestSource.asObservable();
    }
}
