import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';

@Component({
    selector: 'myheader',
    templateUrl: 'myheader.component.html',
    styleUrls: ['myheader.component.scss'],
})
export class MyheaderComponent implements OnInit, OnDestroy {
    public homeLink: string;
    private subscriptions: Subscription[];
    constructor(public communicationService: ServerCommunicationService, private router: Router) {
        this.subscriptions = [];
    }
    ngOnInit(): void {}
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
    public async logout(): Promise<void> {
        await this.communicationService.logout();
    }
}
