import { Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';

@Component({
    selector: 'about',
    templateUrl: 'about.component.html',
    styleUrls: ['about.component.scss'],
})
export class AboutComponent implements OnInit {
    version = '';

    constructor(private location: Location, private communicationService: ServerCommunicationService) {}

    ngOnInit() {
        this.version = this.communicationService.version;
    }

    @HostListener('document:keydown.escape', ['$event'])
    onEscapeHandler() {
        this.location.back();
    }
}
