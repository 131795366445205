import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'customer-overview',
    templateUrl: './customer-overview.component.html',
    styleUrls: ['./customer-overview.component.scss'],
})
export class CustomerOverviewComponent implements OnInit, OnDestroy {
    private subscriptions: Array<Subscription> = [];
    constructor() {}
    ngOnInit(): void {}
    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
