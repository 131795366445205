import {IPatternCalibration} from '../../interfaces/calibration/patternCalibration.interface';
import {SmartImage} from '../../classes/smartImage.class';
import {CALIBRATION_TEST} from '../../enum/calibration.command.enum';
import {CommonCalibrationTest} from './common.calibration.test.model';

export class PatternCalibration implements CommonCalibrationTest, IPatternCalibration {
    public type: CALIBRATION_TEST = CALIBRATION_TEST.PATTERN_TEST;
    public patternImage: SmartImage;
    public odAngle: number;
    public odCenterX: number;
    public odCenterY: number;
    public osAngle: number;
    public osCenterX: number;
    public osCenterY: number;

    public setModel(model: IPatternCalibration): void {
        this.patternImage.model = model.patternImage;
        this.osAngle = model.osAngle;
        this.osCenterX = model.osCenterX;
        this.osCenterY = model.osCenterY;
        this.odAngle = model.odAngle;
        this.odCenterX = model.odCenterX;
        this.odCenterY = model.odCenterY;
    }

    public getModel(): IPatternCalibration {
        return {
            patternImage: this.patternImage.model,
            osAngle: this.osAngle,
            osCenterX: this.osCenterX,
            osCenterY: this.osCenterY,
            odAngle: this.odAngle,
            odCenterX: this.odCenterX,
            odCenterY: this.odCenterY,
        };
    }
}
