import { CommonBulbicamService, CommonFileService } from '../../services';
import { IGridFsFileMetadata, PatternCalibration } from 'common-lib';
import { SmartImageFrontend } from '../smartImageFrontend.class';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';

export class PatternCalibrationFrontend extends PatternCalibration {
    public override patternImage: SmartImageFrontend;

    constructor(private fileService: CommonFileService, private bulbicamService: CommonBulbicamService) {
        super();
        this.patternImage = new SmartImageFrontend();
    }

    public async saveModel(): Promise<void> {
        const metadata: IGridFsFileMetadata = {
            patientID: '',
            examinationID: '',
            testType: this.type,
            mimeType: 'text/plain',
            compressed: false,
        };
        await this.patternImage.saveToDb(metadata, this.fileService);
        await firstValueFrom(this.bulbicamService.saveCalibrationResult({ test: this.type, createdAt: Date.now(), results: this.getModel() }));
    }
}
