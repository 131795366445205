import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CalibrationTestComponent } from '../calibration-test-component';
import { CALIBRATION_COMMAND, CALIBRATION_TEST, ICalibrationResult, IPatternCalibration, IVPosCalibration } from 'common-lib';
import { CommonBulbicamService } from '../../../../services/bulbiCam.service';
import { CommonSocketService } from '../../../../services/socket.service';
import { CommonConfigService } from '../../../../services/config.service';
import { CommonFileService } from '../../../../services/file.service';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { VPosCalibrationFrontend } from '../../../../classes/calibration-tests/vPosCalibrationFrontend.model';
import { CommonModule } from '@angular/common';
import { ImageCarouselComponent } from '../../../image-carousel/image-carousel.component';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { TooltipDirective } from '../../../../../lib/directives';

@Component({
    selector: 'vpos-test',
    templateUrl: 'vpos-test.component.html',
    styleUrls: ['./vpos-test.component.scss', '../reusable-styles.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, ImageCarouselComponent, ReactiveFormsModule, TooltipDirective],
})
export class VPosComponent extends CalibrationTestComponent implements OnInit {
    public image: FormControl;
    public isTestRunning: boolean = false;
    public isSavingResults: boolean = false;
    public isPatternResults: boolean = false;
    public selectedIndex: number = -1;
    public results: (ICalibrationResult<VPosCalibrationFrontend> & { isTemp?: boolean })[];

    constructor(
        @Inject('BulbicamService') private bulbicamService: CommonBulbicamService,
        @Inject('SocketService') private socketService: CommonSocketService,
        @Inject('ConfigService') public configService: CommonConfigService,
        @Inject('FileService') public fileService: CommonFileService,
        private route: ActivatedRoute
    ) {
        super(CALIBRATION_TEST.VPOS_TEST);
        this.image = new FormControl();
    }

    ngOnInit(): void {
        const resolvedData: {
            calibrationResults: ICalibrationResult<IVPosCalibration>[];
            patternResults: ICalibrationResult<IPatternCalibration>[];
        } = this.route.snapshot.data['calibrationResults'];
        this.isPatternResults = resolvedData.patternResults.length > 0 ? true : false;
        this.handleCalibrationTestsResults(resolvedData.calibrationResults);
    }

    customOnDestroy(): void {}

    public isStartButtonDisabled(): boolean {
        return !this.configService.camState || !this.isPatternResults;
    }

    public async messageHandler(data: any): Promise<void> {
        switch (data.message_type) {
            case CALIBRATION_COMMAND.START: {
                this.isTestRunning = true;
                break;
            }
            case CALIBRATION_COMMAND.DATA_PACKAGE: {
                const vPosCalibrationResult = new VPosCalibrationFrontend(this.fileService, this.bulbicamService);
                vPosCalibrationResult.setModel({
                    osAngle: data.OS_angle,
                    osLength: data.OS_length,
                    odAngle: data.OD_angle,
                    odLength: data.OD_length,
                    image: data.smartImage,
                });
                this.results.unshift({
                    test: this.calibrationType,
                    createdAt: +new Date(),
                    results: vPosCalibrationResult,
                    isTemp: true,
                });
                this.image.setValue(this.results[0].results!.image);
                break;
            }
            case CALIBRATION_COMMAND.STOP: {
                this.socketService.socket?.off(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);
                this.isTestRunning = false;
                this.selectedIndex = 0;
                break;
            }
            default:
                break;
        }
    }

    get isUnsavedResultExists(): boolean {
        return !!this.results.find((r) => r.isTemp);
    }

    public async selectActive(index: number): Promise<void> {
        this.selectedIndex = index;
        if (index < 0) return;
        await this.results[index]?.results!.image.download(this.fileService);
        this.image.setValue(this.results[index]?.results!.image);
    }

    async deleteInputResults() {
        await firstValueFrom(this.bulbicamService.deleteCalibrationResult(this.results[this.selectedIndex].test, this.results[this.selectedIndex].createdAt));

        const calibrationResults = await firstValueFrom(this.bulbicamService.getCalibrationResults<IVPosCalibration>([this.calibrationType]));
        this.handleCalibrationTestsResults(calibrationResults);

        if (this.results[this.selectedIndex]) {
            this.selectActive(this.selectedIndex);
        } else if (this.results[this.selectedIndex - 1]) {
            this.selectActive(this.selectedIndex - 1);
        } else {
            this.selectActive(-1);
            this.image.setValue(null);
        }
    }

    toggleTest(): void {
        if (this.isTestRunning) {
            firstValueFrom(
                this.bulbicamService.sendCalibrationCommand({
                    test: this.calibrationType,
                    command: CALIBRATION_COMMAND.STOP,
                })
            );
        } else {
            if (this.isUnsavedResultExists) this.results.shift();
            this.socketService.socket?.on(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);
            firstValueFrom(
                this.bulbicamService.sendCalibrationCommand({
                    test: this.calibrationType,
                    command: CALIBRATION_COMMAND.START,
                })
            );
        }
    }

    private handleCalibrationTestsResults(calibrationResults: ICalibrationResult<IVPosCalibration>[]): void {
        this.results = calibrationResults
            .map((result) => {
                const vPosCalibrationResult = new VPosCalibrationFrontend(this.fileService, this.bulbicamService);
                vPosCalibrationResult.setModel(result.results!);
                result.results = vPosCalibrationResult;
                return result as ICalibrationResult<VPosCalibrationFrontend>;
            })
            .sort((a, b) => b.createdAt - a.createdAt);
    }

    override async saveResults(): Promise<void> {
        this.isSavingResults = true;
        try {
            await this.results[0]!.results!.saveModel();
            this.isSavingResults = false;
            this.results[0].isTemp = false;
        } catch (e) {
            console.error(e);
            this.isSavingResults = false;
            return;
        }

        try {
            const allTests = await firstValueFrom(this.bulbicamService.getCalibrationResults<IVPosCalibration>([this.calibrationType]));
            this.handleCalibrationTestsResults(allTests);
        } catch (e) {
            console.error(e);
            return;
        }
    }
}
