<form [formGroup]="form" novalidate>
    <div class="inputWrapper">
        <label>{{ 'first name' }}</label>
        <input class="smallInput" type="text" formControlName="firstName" name="firstName" />
    </div>
    <div class="inputWrapper">
        <label>{{ 'last name' }}</label>
        <input class="smallInput" type="text" formControlName="lastName" name="lastName" />
    </div>
    <div class="inputWrapper">
        <label>{{ 'email' }}*</label>
        <input class="smallInput" type="email" formControlName="email" name="email" />
    </div>
    <div class="inputWrapper">
        <label>{{ 'phone' }}*</label>
        <input class="smallInput" type="tel" formControlName="phone" name="phone" />
    </div>
    <div class="inputWrapper">
        <label>{{ 'company' }}*</label>
        <input class="smallInput" type="text" formControlName="company" name="company" />
    </div>
    <div class="inputWrapper">
        <label>{{ 'password' }}</label>
        <input class="smallInput" type="password" formControlName="password" name="password" />
    </div>
    <div class="inputWrapper">
        <label>{{ 'confirm password' }}</label>
        <input class="smallInput" type="password" formControlName="passwordConfirmation" name="password" />
    </div>
    <div class="error" *ngIf="form.errors?.['areEqual']">
        <small>
            {{ 'not equal passwords' }}
        </small>
    </div>
    <div class="error" *ngIf="form.get('password')?.errors?.['pattern']">
        <small>
            {{ 'Password is required (has a number, has upper case letter, has a lower-case letter, has a special character, has a minimum length of 8 characters)' }}
        </small>
    </div>
    <div class="buttons">
        <button class="normalButton primaryButton" (click)="registerKey()" [disabled]="!user._id">{{ 'register key' }}</button>
        <button class="normalButton primaryButton" (click)="goBack()" *ngIf="!registerMode">{{ 'go back' }}</button>
        <button class="normalButton primaryButton" (click)="registerUser()" [disabled]="form.pristine || form.invalid">
            {{ 'save' }}
        </button>
        <button class="normalButton primaryButton" (click)="clear()" [disabled]="form.pristine">{{ 'clear' }}</button>
    </div>
</form>
