import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import moment from 'moment';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';
import { TestClassProvider, DEVICE, GENDER, ROLE, TEST_TYPE, IBulbiPointRoleProperties, IUser, IExaminationRepresentative } from 'common-lib';
import { DatepickerComponent } from '../custom-inputs/datepicker/datepicker.component';
import { IManagerExportTerms } from '../../../../../common/interfaces/managerExportTerms.interface';
import { saveAs } from 'file-saver';

@Component({
    selector: 'export',
    templateUrl: 'export.component.html',
    styleUrls: ['export.component.scss'],
})
export class ExportComponent implements OnInit {
    public filterForm: FormGroup;
    public readonly timeRanges: string[] = ['Today', 'This week', 'Last week', 'This month', 'Last month', 'This year', 'Last year', 'Custom'];
    @ViewChild('startPicker') private startPicker: DatepickerComponent;
    @ViewChild('endPicker') private endPicker: DatepickerComponent;
    public readonly maxDate: Date = new Date();
    public maxDateForStartDatePicker: Date = new Date();
    public minDateForEndDatePicker: Date;
    public readonly gender: GENDER | string[] = ['', GENDER.FEMALE, GENDER.MALE, GENDER.OTHER];
    public tests: {
        camTests: { name: string; type: TEST_TYPE }[];
        notCamTests: { name: string; type: TEST_TYPE }[];
    } = {
        camTests: [],
        notCamTests: [],
    };
    public points: IUser<IBulbiPointRoleProperties>[] = [];
    public pendingResponce: boolean = false;
    public readonly ageRange: string[] = (() => {
        let arr: string[] = [];
        arr.push('');
        for (let index = 0; index < 121; index++) {
            arr.push(index.toString());
        }
        return arr;
    })();

    constructor(private formBuilder: FormBuilder, private testClassProvider: TestClassProvider, private communicationService: ServerCommunicationService) {
        this.tests.camTests = this.testClassProvider.testsProperties
            .filter((test) => test.device === DEVICE.HAPLO)
            .map((test) => {
                return { name: test.name, type: test.type };
            });
        let camTestTypesCheckboxGroup: {
            [key: string]: boolean;
        } = {};
        this.tests.camTests.forEach((ct) => (camTestTypesCheckboxGroup[ct.type] = false));

        this.testClassProvider.testsProperties
            .filter((test) => test.device !== DEVICE.HAPLO)
            .forEach((test) => {
                if (this.tests.notCamTests.map((t) => t.type).indexOf(test.type) === -1) {
                    this.tests.notCamTests.push({ name: test.name, type: test.type });
                }
            });
        let notCamTestTypesCheckboxGroup: {
            [key: string]: boolean;
        } = {};
        this.tests.notCamTests.forEach((nct) => (notCamTestTypesCheckboxGroup[nct.type] = false));

        this.filterForm = this.formBuilder.group(
            {
                range: null,
                startDate: [null, Validators.required],
                endDate: [null, Validators.required],
                tests: this.formBuilder.group({
                    camTests: this.formBuilder.group(camTestTypesCheckboxGroup),
                    notCamTests: this.formBuilder.group(notCamTestTypesCheckboxGroup),
                }),
                timeUnit: ['', Validators.required],
                target: ['', Validators.required],
            },
            {
                validators: (control: AbstractControl): ValidationErrors | null => {
                    const rawFormValue = control.getRawValue();
                    let selectedTestTypes: TEST_TYPE[] = [];
                    for (const camTest in rawFormValue.tests.camTests) {
                        if (Object.prototype.hasOwnProperty.call(rawFormValue.tests.camTests, camTest)) {
                            const isSelected: boolean = rawFormValue.tests.camTests[camTest];
                            if (isSelected) selectedTestTypes.push(TEST_TYPE[camTest as keyof typeof TEST_TYPE]);
                        }
                    }
                    for (const notCamTest in rawFormValue.tests.notCamTests) {
                        if (Object.prototype.hasOwnProperty.call(rawFormValue.tests.notCamTests, notCamTest)) {
                            const isSelected: boolean = rawFormValue.tests.notCamTests[notCamTest];
                            if (isSelected) selectedTestTypes.push(TEST_TYPE[notCamTest as keyof typeof TEST_TYPE]);
                        }
                    }
                    let res: {
                        [key: string]: boolean;
                    } = {};
                    if (selectedTestTypes.length === 0) {
                        res['noneTestTypeSelected'] = true;
                    }
                    let selectedRemotePoints: string[] = [];
                    for (const point in rawFormValue.points) {
                        if (Object.prototype.hasOwnProperty.call(rawFormValue.points, point)) {
                            const isSelected: boolean = rawFormValue.points[point];
                            if (isSelected) selectedRemotePoints.push(point);
                        }
                    }
                    if (selectedRemotePoints.length === 0) {
                        res['noneRemotePointsSelected'] = true;
                    }
                    return !!Object.keys(res).length ? res : null;
                },
            }
        );

        this.communicationService.sockets.UserSocket.getUsers<IBulbiPointRoleProperties>(ROLE.REMOTEPOINT).then((points) => {
            let pointsCheckboxGroup: {
                [key: string]: boolean;
            } = {};
            points.forEach((p) => (pointsCheckboxGroup[p._id as string] = false));
            this.filterForm.setControl('points', this.formBuilder.group(pointsCheckboxGroup));
            this.points = points;
        });
    }
    ngOnInit(): void {
        this.filterForm.get('range')!.valueChanges.subscribe((range: string) => {
            switch (range) {
                case 'Today':
                    this.filterForm.get('startDate')!.setValue(moment().startOf('day').toDate());
                    this.filterForm.get('endDate')!.setValue(moment().toDate());
                    break;
                case 'This week':
                    this.filterForm.get('startDate')!.setValue(moment().startOf('isoWeek').toDate());
                    this.filterForm.get('endDate')!.setValue(moment().toDate());
                    break;
                case 'Last week':
                    this.filterForm.get('startDate')!.setValue(moment().subtract(1, 'week').startOf('isoWeek').toDate());
                    this.filterForm.get('endDate')!.setValue(moment().subtract(1, 'week').endOf('isoWeek').toDate());
                    break;
                case 'This month':
                    this.filterForm.get('startDate')!.setValue(moment().startOf('month').toDate());
                    this.filterForm.get('endDate')!.setValue(moment().toDate());
                    break;
                case 'Last month':
                    this.filterForm.get('startDate')!.setValue(moment().subtract(1, 'month').startOf('month').toDate());
                    this.filterForm.get('endDate')!.setValue(moment().subtract(1, 'month').endOf('month').toDate());
                    break;
                case 'This year':
                    this.filterForm.get('startDate')!.setValue(moment().startOf('year').toDate());
                    this.filterForm.get('endDate')!.setValue(moment().toDate());
                    break;
                case 'Last year':
                    this.filterForm.get('startDate')!.setValue(moment().subtract(1, 'year').startOf('year').toDate());
                    this.filterForm.get('endDate')!.setValue(moment().subtract(1, 'year').endOf('year').toDate());
                    break;
                case 'Custom':
                    this.filterForm.get('startDate')!.setValue(null, { emitEvent: false });
                    this.filterForm.get('endDate')!.setValue(null, { emitEvent: false });
                    this.startPicker.open();
                    break;
            }
            return;
        });
        this.filterForm.get('startDate')!.valueChanges.subscribe((startRange: Date) => {
            this.minDateForEndDatePicker = startRange;
            if (startRange && this.filterForm.get('range')!.value === 'Custom') {
                this.endPicker.open();
            }
        });
    }

    public selectAllCamTestTypes(event: any): void {
        let testTypesCheckboxGroup: {
            [key: string]: boolean;
        } = {};
        this.tests.camTests.forEach((ct) => (testTypesCheckboxGroup[ct.type] = event.target.checked));
        this.filterForm.get('tests.camTests')!.setValue(testTypesCheckboxGroup);
    }

    public selectAllNotCamTestTypes(event: any): void {
        let testTypesCheckboxGroup: {
            [key: string]: boolean;
        } = {};
        this.tests.notCamTests.forEach((ct) => (testTypesCheckboxGroup[ct.type] = event.target.checked));
        this.filterForm.get('tests.notCamTests')!.setValue(testTypesCheckboxGroup);
    }

    public selectAllRemotePoints(event: any): void {
        let remotePointsCheckboxGroup: {
            [key: string]: boolean;
        } = {};
        this.points.forEach((point) => (remotePointsCheckboxGroup[point._id as string] = event.target.checked));
        this.filterForm.get('points')!.setValue(remotePointsCheckboxGroup);
    }

    public async export() {
        this.pendingResponce = true;
        this.filterForm.disable({ emitEvent: false });
        const formRawValue = this.filterForm.getRawValue();
        let query: IManagerExportTerms = {
            startDate: moment(formRawValue.startDate).startOf('day').valueOf(),
            endDate: moment(formRawValue.endDate).endOf('day').valueOf(),
            tests: {
                camTests: [],
                notCamTests: [],
            },
            points: [],
            target: formRawValue.target,
            timeUnit: formRawValue.timeUnit,
        };
        for (const camTestType in formRawValue.tests.camTests) {
            if (Object.prototype.hasOwnProperty.call(formRawValue.tests.camTests, camTestType)) {
                const isSelected: boolean = formRawValue.tests.camTests[camTestType];
                if (isSelected) query.tests.camTests.push(TEST_TYPE[camTestType as keyof typeof TEST_TYPE]);
            }
        }
        for (const notCamTestType in formRawValue.tests.notCamTests) {
            if (Object.prototype.hasOwnProperty.call(formRawValue.tests.notCamTests, notCamTestType)) {
                const isSelected: boolean = formRawValue.tests.notCamTests[notCamTestType];
                if (isSelected) query.tests.notCamTests.push(TEST_TYPE[notCamTestType as keyof typeof TEST_TYPE]);
            }
        }
        for (const remotePoint in formRawValue.points) {
            if (Object.prototype.hasOwnProperty.call(formRawValue.points, remotePoint)) {
                const isSelected: boolean = formRawValue.points[remotePoint];
                if (isSelected) query.points.push(remotePoint);
            }
        }

        const res: { start: number; end: number; representatives: IExaminationRepresentative[] }[] = await this.communicationService.sockets.ExaminationSocket.export(query);
        if (query.target === 'Examinations') this.handleExaminationsCntQuery(res, query);
        else if (query.target === 'Tests') this.handleTestsCntQuery(res, query);
        this.filterForm.enable({ emitEvent: false });
        this.pendingResponce = false;
    }
    private handleExaminationsCntQuery(data: { start: number; end: number; representatives: IExaminationRepresentative[] }[], query: IManagerExportTerms): void {
        let content: string = 'Time ranges\t';

        data.forEach((d, i, a) => {
            const delimiter: string = i < a.length - 1 ? '\t' : '\n';
            content += `${moment(d.start).format('YY-MM-DD HH:mm:ss')} - ${moment(d.end).format('YY-MM-DD HH:mm:ss')}${delimiter}`;
        });

        query.tests.camTests.forEach((ct, i) => {
            data.forEach((d, j, a) => {
                const delimiter: string = j < a.length - 1 ? '\t' : '\n';
                if (j === 0) {
                    if (i === 0) {
                        content += 'Cam tests\n';
                    }
                    content += ct + '\t';
                }
                let representativesCnt: number = 0;
                d.representatives.forEach((r) => {
                    if (r.completedTests.find((t) => t.device === DEVICE.HAPLO && t.type === ct && t.count > 0)) {
                        representativesCnt += 1;
                    }
                });
                content += representativesCnt > 0 ? representativesCnt + delimiter : delimiter;
            });
        });

        query.tests.notCamTests.forEach((nct, i) => {
            data.forEach((d, j, a) => {
                const delimiter: string = j < a.length - 1 ? '\t' : '\n';
                if (j === 0) {
                    if (i === 0) {
                        content += 'Other devices tests\n';
                    }
                    content += nct + '\t';
                }
                let representativesCnt: number = 0;
                d.representatives.forEach((r) => {
                    if (r.completedTests.find((t) => t.device !== DEVICE.HAPLO && t.type === nct && t.count > 0)) {
                        representativesCnt += 1;
                    }
                });
                content += representativesCnt > 0 ? representativesCnt + delimiter : delimiter;
            });
        });

        let blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, `${moment(query.startDate).format('YY-MM-DD HH:mm:ss')}-${moment(query.endDate).format('YY-MM-DD HH:mm:ss')}_${query.timeUnit}_${query.target}.tsv`);
    }
    private handleTestsCntQuery(data: { start: number; end: number; representatives: IExaminationRepresentative[] }[], query: IManagerExportTerms): void {
        let content: string = 'Time ranges\t';

        data.forEach((d, i, a) => {
            const delimiter: string = i < a.length - 1 ? '\t' : '\n';
            content += `${moment(d.start).format('YY-MM-DD HH:mm:ss')} - ${moment(d.end).format('YY-MM-DD HH:mm:ss')}${delimiter}`;
        });

        query.tests.camTests.forEach((ct, i) => {
            data.forEach((d, j, a) => {
                const delimiter: string = j < a.length - 1 ? '\t' : '\n';
                if (j === 0) {
                    if (i === 0) {
                        content += 'Cam tests\n';
                    }
                    content += ct + '\t';
                }
                let testsCnt: number = 0;
                d.representatives.forEach((r) => {
                    r.completedTests
                        .filter((t) => t.device === DEVICE.HAPLO && t.type === ct)
                        .forEach((t) => {
                            testsCnt += t.count;
                        });
                });
                content += testsCnt > 0 ? testsCnt + delimiter : delimiter;
            });
        });

        query.tests.notCamTests.forEach((ct, i) => {
            data.forEach((d, j, a) => {
                const delimiter: string = j < a.length - 1 ? '\t' : '\n';
                if (j === 0) {
                    if (i === 0) {
                        content += 'Other devices tests\n';
                    }
                    content += ct + '\t';
                }
                let testsCnt: number = 0;
                d.representatives.forEach((r) => {
                    r.completedTests
                        .filter((t) => t.device !== DEVICE.HAPLO && t.type === ct)
                        .forEach((t) => {
                            testsCnt += t.count;
                        });
                });
                content += testsCnt > 0 ? testsCnt + delimiter : delimiter;
            });
        });

        let blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, `${moment(query.startDate).format('YY-MM-DD HH:mm:ss')}-${moment(query.endDate).format('YY-MM-DD HH:mm:ss')}_${query.timeUnit}_${query.target}.tsv`);
    }
}
