<div class="sliderBlock" [class.fullScreen]="fullScreen">
    <i class="aico-arrowLeft" [class.disabled]="isBusy || activeImage.value === 0" *ngIf="value.length > 1" (click)="!isBusy && $event.preventDefault(); prevImage()"></i>
    <div
        #slide
        class="slide"
        [style.background-image]="(activeImage.value !== null && value[activeImage.value].imageUrl) || ''"
        (click)="value.length > 0 && (fullScreen = !fullScreen) && (zoomModeEnabled = false)"
        (mouseover)="$event.stopPropagation(); $event.preventDefault(); value.length >= 1 && !fullScreen && (zoomModeEnabled = true)"
        (mouseleave)="$event.stopPropagation(); $event.preventDefault(); zoomModeEnabled = false"
    >
        <div #target class="target" [class.visible]="supportZoom && zoomModeEnabled"></div>
    </div>
    <i
        class="aico-arrowRight"
        [class.disabled]="isBusy || activeImage.value === value.length - 1"
        *ngIf="value.length > 1"
        (click)="!isBusy && $event.preventDefault(); nextImage()"
    >
    </i>
    <i
        class="aico-close"
        [class.disabled]="isBusy"
        *ngIf="!fullScreen && activeImage.value !== null && isDeletable && value.length > 0"
        (click)="!isBusy && activeImage.value !== null && delete.emit(value[activeImage.value].imageID)"
    ></i>
    <i
        class="aico-refresh"
        [class.disabled]="isBusy"
        *ngIf="!fullScreen && activeImage.value !== null && isRotateble && value.length > 0"
        (click)="!isBusy && activeImage.value !== null && rotate(value[activeImage.value])"
    ></i>
</div>
<div class="uploadBlock" *ngIf="isUploadable">
    <button class="normalButton primaryButton" type="button" (click)="!isBusy && imageInput.click()">{{ 'add image' }}</button>
    <input #imageInput type="file" (click)="imageInput.value = ''" (change)="addImage($event)" accept="image/*,.pdf" />
</div>
<div class="remarkBlock" *ngIf="supportRemarks">
    <textarea class="normalTextarea" placeholder="{{ 'textarea placeholder' }}" [formControl]="imageRemarks"></textarea>
</div>
<div class="previewBlock" *ngIf="supportRemarks">
    <div
        class="slide"
        *ngFor="let image of value; let i = index"
        [class.selected]="i === activeImage.value"
        [style.background-image]="image?.imageUrl || ''"
        (click)="!isBusy && activeImage.next(i)"
    ></div>
</div>
<div class="zoomWindow" #zoomWindow [class.visible]="zoomModeEnabled" *ngIf="supportZoom">
    <div class="zoomedImageWrapper">
        <div
            class="zoomedImage"
            *ngIf="activeImage.value !== null"
            #zoomedImage
            [style.background-image]="(activeImage.value !== null && value[activeImage.value].imageUrl) || ''"
        ></div>
    </div>
</div>
