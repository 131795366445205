import { CALIBRATION_TEST } from '../../enum/calibration.command.enum';
import { ILuxCalibration } from './luxCalibration.interface';
import { ILensDistortionCalibration } from './lensDistortionCalibration.interface';
import { IPatternCalibration } from './patternCalibration.interface';
import { IScreenCalibration } from './screenCalibration.interface';
import { ISensorCalibration } from './sensorCalibration.interface';
import { ITimingCalibration } from './timingCalibration.interface';
import { IVPosCalibration } from './vposCalibration.interface';

export type CalibrationResultType =
    | IScreenCalibration
    | IPatternCalibration
    | IVPosCalibration
    | ITimingCalibration
    | ISensorCalibration
    | ILuxCalibration
    | ILensDistortionCalibration;

export const calibrationTestsSequence: { type: CALIBRATION_TEST; order: number }[] = [
    { type: CALIBRATION_TEST.SCREEN_CALIBRATION, order: 1 },
    { type: CALIBRATION_TEST.PATTERN_TEST, order: 2 },
    { type: CALIBRATION_TEST.VPOS_TEST, order: 3 },
    { type: CALIBRATION_TEST.SHORT_TIMING_TEST, order: 4 },
    { type: CALIBRATION_TEST.LONG_TIMING_TEST, order: 4 },
    { type: CALIBRATION_TEST.SENSOR_TEST, order: 5 },
    { type: CALIBRATION_TEST.LUX_CALIBRATION, order: 6 },
    { type: CALIBRATION_TEST.LENS_DISTORTION_TEST, order: 7 },
];

export type CalibrationTestResultMap = {
    [CALIBRATION_TEST.SCREEN_CALIBRATION]: IScreenCalibration;
    [CALIBRATION_TEST.PATTERN_TEST]: IPatternCalibration;
    [CALIBRATION_TEST.VPOS_TEST]: IVPosCalibration;
    [CALIBRATION_TEST.LONG_TIMING_TEST]: ITimingCalibration;
    [CALIBRATION_TEST.SENSOR_TEST]: ISensorCalibration;
    [CALIBRATION_TEST.LUX_CALIBRATION]: ILuxCalibration;
    [CALIBRATION_TEST.LENS_DISTORTION_TEST]: ILensDistortionCalibration;
    [CALIBRATION_TEST.SHORT_TIMING_TEST]: ITimingCalibration;
};
export function getTypeName(type: CALIBRATION_TEST | undefined): string {
    switch (type) {
        case CALIBRATION_TEST.SCREEN_CALIBRATION:
            return 'Screen Calibration';
        case CALIBRATION_TEST.PATTERN_TEST:
            return 'Pattern Identification';
        case CALIBRATION_TEST.VPOS_TEST:
            return 'Virtual Camera Position Calibration';
        case CALIBRATION_TEST.LONG_TIMING_TEST:
            return 'Timing Test';
        case CALIBRATION_TEST.SHORT_TIMING_TEST:
            return 'Timing Test';
        case CALIBRATION_TEST.SENSOR_TEST:
            return 'Sensor Test';
        case CALIBRATION_TEST.LUX_CALIBRATION:
            return 'Lux Calibration';
        case CALIBRATION_TEST.LENS_DISTORTION_TEST:
            return 'Lens Distortion Test';
        default:
            throw new Error('Unknown type');
    }
}

export interface ICalibrationResult<T extends CalibrationResultType> {
    _id?: string;
    test: CALIBRATION_TEST;
    createdAt: number;
    results: T | undefined;
    sourceID?: string;
    synchronizedAt?: number;
    user?: string;
}
