import { BehaviorSubject, Subject } from 'rxjs';
import { Manager, Socket } from 'socket.io-client';

export abstract class IOSocket {
    protected _name: string;
    protected _socket: Socket;
    private _connected: BehaviorSubject<boolean>;
    constructor(protected manager: Manager, name: string, socketError: Subject<Error>) {
        this._name = name;
        this._connected = new BehaviorSubject<boolean>(false);
        this._socket = this.manager.socket(this.name);
        this._socket.on('connect_error', (error: Error) => {
            socketError.next(error);
        });
        this._socket.on('connect', () => {
            this._connected.next(true);
        });
        this._socket.on('reconnect', () => {
            // console.log(this._name + ' socket reconnected');
            this._connected.next(true);
        });
        this._socket.on('reconnecting', () => {
            // console.log(this._name + ' socket reconnecting');
        });
        this._socket.on('reconnect_attempt', (attempt) => {
            // console.log(this._name + ' socket reconnect_attempt number' + attempt);
        });
        this._socket.on('connect_error', (error) => {
            // console.log(this._name + ' socket connect_error');
            // console.log(error);
            socketError.next(error);
        });
        this._socket.on('reconnect_error', (error) => {
            // console.log(this._name + ' socket reconnect_error');
            // console.log(error);
            socketError.next(error);
        });
        this._socket.on('reconnect_failed', () => {
            // console.log(this._name + ' socket reconnect_failed');
        });
        this._socket.on('connect_timeout', (timeout) => {
            // console.log(this._name + ' socket connect_timeout');
            // console.log(timeout);
        });
        this._socket.on('reconnect_error', (error) => {
            // console.log(this._name + ' socket reconnect_error');
            // console.log(error);
            socketError.next(error);
        });
        this._socket.on('disconnect', () => {
            // console.log(this._name + ' socket disonnected');
            this._connected.next(false);
            this.desctuct();
        });
    }
    public desctuct(): void {}
    public get name(): string {
        return this._name;
    }
    public get connected(): boolean {
        return this._connected.value;
    }
    public async connect(): Promise<void> {
        return new Promise<void>((res, rej) => {
            if (this._socket.connected) {
                res();
            } else {
                this._socket.once('connect', () => {
                    this.addHendlers();
                    res();
                });
                this._socket.once('error', (error) => {
                    rej(error);
                });
                this._socket.open();
            }
        });
    }
    public async close(): Promise<void> {
        return new Promise<void>((res, rej) => {
            if (this._socket.connected) {
                this._socket.once('disconnect', () => {
                    res();
                });
                this._socket.close();
            } else {
                res();
            }
        });
    }
    protected abstract addHendlers(callback?: () => void): Promise<void>;
}
