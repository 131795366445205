import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import * as d3 from 'd3';
import { firstValueFrom } from 'rxjs';
import { CalibrationTestComponent } from '../calibration-test-component';
import { CALIBRATION_COMMAND, CALIBRATION_TEST, ICalibrationResult, ITimingCalibration, IVPosCalibration } from 'common-lib';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CommonBulbicamService } from '../../../../services/bulbiCam.service';
import { CommonSocketService } from '../../../../services/socket.service';
import { CommonConfigService } from '../../../../services/config.service';
import { TimingCalibrationFrontend } from '../../../../classes/calibration-tests/timingCalibrationFrontend.model';
import { TooltipDirective } from '../../../../../lib/directives';
@Component({
    selector: 'timing-test',
    templateUrl: 'timing-test.component.html',
    styleUrls: ['timing-test.component.scss', '../reusable-styles.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, ReactiveFormsModule, TooltipDirective],
})
export class TimingTestComponent extends CalibrationTestComponent implements OnInit, AfterViewInit {
    @ViewChild('svgChart') svgChart: ElementRef;
    public yellowColor: string = '#F2C94C';
    public blueColor: string = '#2D9CDB';
    public selectedIndex: number = -1;
    public form: FormGroup;
    public results: (ICalibrationResult<TimingCalibrationFrontend> & { isTemp?: boolean })[] = [];
    public isVposResults: boolean = false;

    private width: number;
    private height: number;

    public isTestRunning: boolean = false;
    public isSavingResults: boolean = false;
    private margin: number = 20;
    private svg: any;
    private svgInner: any;
    private yScale: any;
    private xScale: any;
    private yAxis: any;
    private xAxis: any;

    constructor(
        @Inject('BulbicamService') private bulbicamService: CommonBulbicamService,
        @Inject('SocketService') private socketService: CommonSocketService,
        @Inject('ConfigService') public configService: CommonConfigService,
        private route: ActivatedRoute,
        private fb: FormBuilder
    ) {
        super(CALIBRATION_TEST.SHORT_TIMING_TEST || CALIBRATION_TEST.LONG_TIMING_TEST);
        this.form = this.fb.group({
            testType: [{ value: '', disabled: false }, Validators.required],
        });
    }

    ngOnInit(): void {
        const resolvedData: {
            calibrationResults: ICalibrationResult<ITimingCalibration>[];
            vposResults: ICalibrationResult<IVPosCalibration>[];
        } = this.route.snapshot.data['calibrationResults'];
        this.isVposResults = resolvedData.vposResults.length > 0 ? true : false;
        this.handleCalibrationTestsResults(resolvedData.calibrationResults);
    }

    ngAfterViewInit(): void {
        this.width = this.svgChart.nativeElement.getBoundingClientRect().width;
        this.height = this.svgChart.nativeElement.getBoundingClientRect().height;
        this.initChart(this.width, this.height);
    }

    customOnDestroy(): void {}

    public isStartButtonDisabled(): boolean {
        return !this.form.get('testType')?.value || !this.configService.camState || !this.isVposResults;
    }

    async messageHandler(data: any): Promise<void> {
        switch (data.message_type) {
            case CALIBRATION_COMMAND.START: {
                this.isTestRunning = true;
                const results = new TimingCalibrationFrontend(data.testType, this.bulbicamService);
                const calibrationTest: ICalibrationResult<TimingCalibrationFrontend> & {
                    isTemp?: boolean | undefined;
                } = {
                    createdAt: Date.now(),
                    results: results,
                    test: data.testType,
                    isTemp: true,
                };
                this.results.unshift(calibrationTest);
                break;
            }
            case CALIBRATION_COMMAND.DATA_PACKAGE: {
                this.results[0].results?.calibrations.push({
                    xPoint: data?.x,
                    yPoint: data?.y,
                    eye: data?.eye,
                });
                break;
            }
            case CALIBRATION_COMMAND.STOP: {
                this.form.enable();
                this.socketService.socket?.off(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);
                const [odSubArray, osSubArray] = [
                    this.results[0].results?.calibrations.filter((d) => d.eye === 0).map((d) => d.xPoint),
                    this.results[0].results?.calibrations.filter((d) => d.eye === 1).map((d) => d.xPoint),
                ];

                this.results[0]!.results!.osAverageValue = d3.mean(osSubArray!);
                this.results[0]!.results!.osMinValue = Math.min(...osSubArray!);
                this.results[0]!.results!.osMaxValue = Math.max(...osSubArray!);
                this.results[0]!.results!.osSampleSize = osSubArray!.length;
                this.results[0]!.results!.odAverageValue = d3.mean(odSubArray!);
                this.results[0]!.results!.odMinValue = Math.min(...odSubArray!);
                this.results[0]!.results!.odMaxValue = Math.max(...odSubArray!);
                this.results[0]!.results!.odSampleSize = odSubArray!.length;

                this.drawChart(0);

                this.isTestRunning = false;
                this.selectedIndex = 0;
                break;
            }
            default:
                break;
        }
    }

    private handleCalibrationTestsResults(calibrationResults: ICalibrationResult<ITimingCalibration>[]): void {
        this.results = calibrationResults
            .map((result) => {
                const timingCalibrationResults = new TimingCalibrationFrontend(
                    result.test as CALIBRATION_TEST.LONG_TIMING_TEST | CALIBRATION_TEST.SHORT_TIMING_TEST,
                    this.bulbicamService
                );
                timingCalibrationResults.setModel(result.results!);
                result.results = timingCalibrationResults;
                return result as ICalibrationResult<TimingCalibrationFrontend>;
            })
            .sort((a, b) => b.createdAt - a.createdAt);
    }

    async deleteInputResults() {
        await firstValueFrom(this.bulbicamService.deleteCalibrationResult(this.results[this.selectedIndex].test, this.results[this.selectedIndex].createdAt));

        const calibrationResults = await firstValueFrom(
            this.bulbicamService.getCalibrationResults<ITimingCalibration>([CALIBRATION_TEST.SHORT_TIMING_TEST, CALIBRATION_TEST.LONG_TIMING_TEST])
        );
        this.handleCalibrationTestsResults(calibrationResults);

        if (this.results[this.selectedIndex]) this.selectActive(this.selectedIndex);
        else if (this.results[this.selectedIndex - 1]) this.selectActive(this.selectedIndex - 1);
        else this.selectActive(-1);
    }

    initChart(width: number = 300, height: number): void {
        this.svg = d3.select(this.svgChart?.nativeElement).attr('id', 'svgtest').attr('width', width).attr('height', height);

        this.svgInner = this.svg.append('svg').style('transform', `translate(${this.margin}px, ${this.margin}px)`);

        this.yScale = d3
            .scaleLinear()
            .domain([1, 0])
            .range([2 * this.margin, height - this.margin]);

        this.xScale = d3
            .scaleLinear()
            .domain([0, 1])
            .range([2 * this.margin, width - 2 * this.margin]);

        this.yAxis = this.svgInner
            .append('g')
            .attr('id', 'y-axis')
            .attr('class', 'axis-grey')
            .attr('stroke', 'grey')
            .style('transform', `translate(${2 * this.margin}px, -20px)`);

        this.xAxis = this.svgInner
            .append('g')
            .attr('id', 'x-axis')
            .attr('class', 'axis-grey')
            .attr('stroke', 'grey')
            .style('transform', `translate(0px, ${height - 2 * this.margin}px)`);

        const yAxis = d3.axisLeft(this.yScale);
        const xAxis = d3.axisBottom(this.xScale);

        this.yAxis.call(yAxis);
        this.xAxis.call(xAxis);

        this.svgInner
            .append('text')
            .attr('id', 'text-label')
            .text(`Time Delay /ms`)
            .attr('dx', this.xScale(0.9))
            .attr('dy', this.yScale(0))
            .attr('stroke', 'white')
            .attr('stroke-width', '1px')
            .attr('font-size', '12px')
            .style('transform', `translate(0px, 15px)`);

        this.svgInner
            .append('text')
            .attr('id', 'text-label')
            .text(`Samples`)
            .attr('dx', this.xScale(0))
            .attr('dy', this.yScale(1))
            .attr('stroke', 'white')
            .attr('stroke-width', '1px')
            .attr('font-size', '12px')
            .style('transform', `translate(-25px, -30px)`);
    }

    drawChart(index: number): void {
        d3.selectAll('#line').remove();
        const lineDefined = d3.line().defined(function (d) {
            return d[1] !== null;
        });

        const result = this.results[index].results as ITimingCalibration;

        this.yScale = d3
            .scaleLinear()
            .domain([Math.max(...result.calibrations.map((d) => d.yPoint)), 0])
            .range([2 * this.margin, this.height - this.margin]);

        const yAxis = d3.axisLeft(this.yScale);

        this.yAxis.call(yAxis);

        this.xScale = d3
            .scaleLinear()
            .domain([0, Math.max(...result.calibrations.map((d) => d.xPoint))])
            .range([2 * this.margin, this.width - 2 * this.margin]);

        const xAxis = d3.axisBottom(this.xScale);

        this.xAxis.call(xAxis);

        const odPoints: [number, number][] = this.results[index].results?.calibrations
            .filter((d) => d.eye === 0)
            .map((d) => {
                return [this.xScale(d.xPoint), this.yScale(d.yPoint)];
            }) as [number, number][];

        const osPoints: [number, number][] = this.results[index].results?.calibrations
            .filter((d) => d.eye === 1)
            .map((d) => {
                return [this.xScale(d.xPoint), this.yScale(d.yPoint)];
            }) as [number, number][];

        this.svgInner
            .append('path')
            .attr('id', `line`)
            .style('fill', 'none')
            .style('stroke', this.blueColor)
            .style('stroke-width', '1px')
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('d', lineDefined(odPoints));

        this.svgInner
            .append('path')
            .attr('id', `line`)
            .style('fill', 'none')
            .style('stroke', this.yellowColor)
            .style('stroke-width', '1px')
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('d', lineDefined(osPoints));
    }

    toggleTest() {
        if (this.form.get('testType')?.value === 'short') {
            this.calibrationType = CALIBRATION_TEST.SHORT_TIMING_TEST;
        } else {
            this.calibrationType = CALIBRATION_TEST.LONG_TIMING_TEST;
        }

        if (this.isTestRunning) {
            firstValueFrom(
                this.bulbicamService.sendCalibrationCommand({
                    test: this.calibrationType,
                    command: CALIBRATION_COMMAND.STOP,
                })
            );
        } else {
            if (this.isUnsavedResultExists) this.results.shift();
            this.socketService.socket!.on(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);
            firstValueFrom(
                this.bulbicamService.sendCalibrationCommand({
                    test: this.calibrationType,
                    command: CALIBRATION_COMMAND.START,
                })
            );
            this.form.disable();
        }
    }

    override async saveResults(): Promise<void> {
        this.isSavingResults = true;
        try {
            await this.results[0]!.results!.saveModel();
            this.isSavingResults = false;
            this.results[0].isTemp = false;
        } catch (e) {
            console.error(e);
            this.isSavingResults = false;
            return;
        }

        try {
            const allShortTimingTests = await firstValueFrom(this.bulbicamService.getCalibrationResults<ITimingCalibration>([CALIBRATION_TEST.SHORT_TIMING_TEST]));
            const allLongTimingTests = await firstValueFrom(this.bulbicamService.getCalibrationResults<ITimingCalibration>([CALIBRATION_TEST.LONG_TIMING_TEST]));
            this.handleCalibrationTestsResults([...allShortTimingTests, ...allLongTimingTests]);
        } catch (e) {
            console.error(e);
            return;
        }
    }

    get isUnsavedResultExists(): boolean {
        return !!this.results.find((r) => r.isTemp);
    }

    selectActive(index: number): void {
        this.selectedIndex = index;
        if (index < 0) return;
        this.drawChart(index);
    }
}
