import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

const matElements = [MatDatepickerModule];

@NgModule({
    imports: matElements,
    exports: matElements,
})
export class MaterialModule {}
