import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServerCommunicationService } from '../../services/serverCommunication.service';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'forgot-password',
    templateUrl: 'forgot-password.component.html',
    styleUrls: ['forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
    public form: FormGroup;
    private error: string;
    constructor(private formBuilder: FormBuilder, private router: Router, private communicationService: ServerCommunicationService, public location: Location) {}
    ngOnInit(): void {
        this.communicationService.sockets.UnsecuredSocket.connect();
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }
    ngOnDestroy(): void {
        this.communicationService.sockets.UnsecuredSocket.close();
    }
    public async restore() {
        try {
            const rawFormValue: { email: string } = this.form.getRawValue();
            const message = await this.communicationService.sockets.UnsecuredSocket.restorePassword(rawFormValue.email);
            console.log(message);

            alert(message);
            this.router.navigate(['/']);
        } catch (error) {
            console.log(error);
        }
    }
    public goBack(): void {
        this.location.back();
    }
}
