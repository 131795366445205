<paginator></paginator>
<div class="tableHead">
    <div>
        <h5>#</h5>
    </div>
    <div>
        <h5>Customer name</h5>
        <singleselect [label]="''" [list]="customersList" [inputClass]="'smallInput'" [formControl]="customersSelect" tabindex="-1"></singleselect>
    </div>
    <div>
        <h5>Source HUB name</h5>
        <singleselect [label]="''" [list]="hubsList" [inputClass]="'smallInput'" [formControl]="hubsSelect" tabindex="-1"></singleselect>
    </div>
    <div (click)="orderBy('createdAt')">
        <h5>
            Examination created<i
                *ngIf="(_searchQuery | async)!.orderBy === 'createdAt'"
                [ngClass]="'aico-triangle' + ((_searchQuery | async)!.orderDirection === SORTING.DESC ? 'Down' : 'Up')"
            ></i>
        </h5>
    </div>
    <div>
        <h5>Examination modified</h5>
    </div>
    <div>
        <h5>Examination synchronized</h5>
    </div>
    <div>
        <h5>Tests performed</h5>
    </div>
</div>
<div *ngFor="let exam of _examinations | async; let i = index" class="oddHighlited">
    <div>
        <p>{{ i + 1 }}</p>
    </div>
    <div>
        <p>{{ exam.customerName }}</p>
    </div>
    <div>
        <a [ngClass]="_HUBsOnline.includes(exam.sourceID) ? 'isOnline' : 'isOffline'">{{ exam.sourceName }}</a>
    </div>
    <div>
        <p [style.color]="exam.timeZone ? '' : 'salmon'">{{ exam.createdAt | timeZoneDate : 'dd.MM.yy H:mm:ss' : exam.timeZone }}</p>
    </div>
    <div>
        <p [style.color]="exam.timeZone ? '' : 'salmon'">{{ exam.lastModified | timeZoneDate : 'dd.MM.yy H:mm:ss' : exam.timeZone }}</p>
    </div>
    <div>
        <p [style.color]="exam.timeZone ? '' : 'salmon'">{{ exam.synchronizedAt | timeZoneDate : 'dd.MM.yy H:mm:ss' : exam.timeZone }}</p>
    </div>
    <div tooltip [content]="converter(exam, true)" [routerLink]="['../', exam._id]">
        <p #overflowTest>
            {{ converter(exam, false) }}
        </p>
        <p *ngIf="checkOverflow(overflowTest)">({{ exam.completedTests.length }})</p>
    </div>
</div>
