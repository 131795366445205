<div class="test-container scrollable">
    <div class="test-header">{{ name }}</div>
    <div class="test-main">
        <div class="test-main-buttons">
            <button type="button" class="normalButton primaryButton" (click)="toggleTest()" [disabled]="!configService.camState">
                {{ isTestRunning ? 'Stop Test' : 'Start Test' }}
            </button>

            <div class="test-main-buttons-action">
                <button type="button" class="normalButton primaryButton" [disabled]="!isUnsavedResultExists || isSavingResults || isTestRunning" (click)="saveResults()">
                    Save Results
                </button>
                <button type="button" class="normalButton primaryButton" [disabled]="(!selectedIndex && selectedIndex !== 0) || selectedIndex < 0" (click)="deleteInputResults()">
                    Delete
                </button>
            </div>
        </div>
        <div class="test-main-picture">
            <img src="/assets/images/sensor.png" alt="CE mark" />
        </div>
        <div class="test-main-tips">
            <svg #svgChart></svg>
        </div>
    </div>

    <div class="test-results">
        <div class="test-results-header">Calibration History</div>
        <div class="test-results-content">
            <div
                class="test-results-content-column"
                *ngFor="let column of results; let ind = index"
                [ngClass]="{ odd: ind % 2, selected: ind === selectedIndex }"
                (click)="selectActive(ind)"
            >
                <div class="results-row">
                    <ng-container *ngIf="column?.isTemp">
                        <span style="color: #eb5757">Temporary Result</span>
                    </ng-container>
                    <ng-container *ngIf="!column?.isTemp">
                        <div class="left">
                            <div>{{ column.createdAt | date : 'yyyy.MM.dd' }}</div>
                            <div>{{ column.createdAt | date : 'hh:mm' }}</div>
                        </div>
                    </ng-container>
                    <div class="middle">Max threshold</div>
                    <div class="right">Sample Size</div>
                </div>

                <div class="results-row">
                    <div class="left" [ngStyle]="{ color: osFrontColor }">OS Front</div>
                    <div class="middle">{{ column.results?.osFrontHalfThreshold?.xValue | number : '1.0-1' }}</div>
                    <div class="right">{{ column.results?.osSampleSize | number : '1.0-1' }}</div>
                </div>

                <div class="results-row">
                    <div class="left" [ngStyle]="{ color: osMidColor }">OS Mid</div>
                    <div class="middle">{{ column.results?.osMidHalfThreshold?.xValue | number : '1.0-1' }}</div>
                    <div class="right">{{ column.results?.osSampleSize | number : '1.0-1' }}</div>
                </div>

                <div class="results-row">
                    <div class="left" [ngStyle]="{ color: osRearColor }">OS Rear</div>
                    <div class="middle">{{ column.results?.osRearHalfThreshold?.xValue | number : '1.0-1' }}</div>
                    <div class="right">{{ column.results?.osSampleSize | number : '1.0-1' }}</div>
                </div>

                <div class="results-row">
                    <div class="left" [ngStyle]="{ color: odFrontColor }">OD Front</div>
                    <div class="middle">{{ column.results?.odFrontHalfThreshold?.xValue | number : '1.0-1' }}</div>
                    <div class="right">{{ column.results?.odSampleSize | number : '1.0-1' }}</div>
                </div>

                <div class="results-row">
                    <div class="left" [ngStyle]="{ color: odMidColor }">OD Mid</div>
                    <div class="middle">{{ column.results?.odMidHalfThreshold?.xValue | number : '1.0-1' }}</div>
                    <div class="right">{{ column.results?.odSampleSize | number : '1.0-1' }}</div>
                </div>

                <div class="results-row">
                    <div class="left" [ngStyle]="{ color: odRearColor }">OD Rear</div>
                    <div class="middle">{{ column.results?.odRearHalfThreshold?.xValue | number : '1.0-1' }}</div>
                    <div class="right">{{ column.results?.odSampleSize | number : '1.0-1' }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="test-buttons">
        <button type="button" class="normalButton primaryButton" (click)="configService.gotoPreviousCalibrationTest(calibrationType)" [disabled]="isTestRunning">
            {{ 'Previous test' }}
        </button>
        <button type="button" class="normalButton secondaryButton" (click)="configService.cancelReturnFromCalibrationTest()">{{ 'Cancel / Return' }}</button>
        <button type="button" class="normalButton primaryButton" (click)="configService.gotoNextCalibrationTest(calibrationType)" [disabled]="isTestRunning">
            {{ 'Next test' }}
        </button>
    </div>
</div>
