import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';

@Component({
    selector: 'dashboard-customer',
    templateUrl: 'dashboard-customer.component.html',
    styleUrls: ['dashboard-customer.component.scss'],
})
export class DashboardCustomerComponent implements OnInit, OnDestroy {
    private _subscriptions: Array<Subscription>;
    constructor(public communicationService: ServerCommunicationService) {
        this._subscriptions = [];
    }
    ngOnInit(): void {}
    ngOnDestroy(): void {
        this._subscriptions.forEach((s) => s.unsubscribe());
    }
}
