import { DEVICE } from '../../../../enum/device';
import { TEST_TYPE } from '../../../../enum/test-type';
import { TEST_STAGE } from '../../../../enum/testStage.enum';

export class AcaNidekNT530PTest {
    public readonly type: TEST_TYPE = TEST_TYPE.ANTERIOR_CHAMBER_ANGLE;
    public readonly device: DEVICE = DEVICE.NIDEK_NT530P;
    public readonly name: string = 'Anterior chamber angle';
    public stage: TEST_STAGE;
}
