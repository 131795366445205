<form novalidate [formGroup]="form">
    <div class="inputWrapper">
        <label>{{ 'password' }}</label>
        <input class="smallInput" type="password" formControlName="password" name="password" />
    </div>
    <div class="inputWrapper">
        <label>{{ 'confirm password' }}</label>
        <input class="smallInput" type="password" formControlName="passwordConfirmation" name="password" />
    </div>
    <label class="error" *ngIf="form.errors?.['areEqual']">not equal passwords</label>
    <label class="error" *ngIf="form.get('password')?.errors?.['pattern']"
        >Password is required (has a number, has upper case letter, has a lower-case letter, has a special character, has a minimum length of 8 characters)</label
    >
    <div>
        <button class="normalButton primaryButton" (click)="updatePassword()" [disabled]="form.pristine || form.invalid">{{ 'update password' }}</button>
    </div>
</form>
<div class="logo"></div>
