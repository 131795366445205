import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IRemotePointSystemParams } from '../../../../../common/interfaces/remotePointSystemParams.interface';
import { CommonSystemParams, UPGRADE_FEATURES } from 'common-lib';

@Component({
    selector: 'point-overview',
    templateUrl: 'point-overview.component.html',
    styleUrls: ['point-overview.component.scss'],
    host: { class: 'scrollable' },
})
export class PointOverviewComponent implements OnInit, OnDestroy {
    public systemParams: IRemotePointSystemParams;
    private subscriptions: Array<Subscription> = [];
    constructor(private route: ActivatedRoute) {}
    ngOnInit(): void {
        this.systemParams = this.route.snapshot.data['systemParams'];
        this.systemParams.systemParams.map((sp) => sp.supportEvent?.upgradeFeatures.map((feature: string) => console.log()));
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
    public getFeatureName(feature: string): string {
        return UPGRADE_FEATURES[feature as keyof typeof UPGRADE_FEATURES];
    }
    public getBgColor(i: number, key: string): object {
        const current = this.systemParams.systemParams[i];
        const previous = this.systemParams.systemParams[i + 1];
        let color: string;
        if (current && previous) {
            if (key === 'supportEvent') {
                color = JSON.stringify(current.supportEvent?.upgradeFeatures.sort()) === JSON.stringify(previous.supportEvent?.upgradeFeatures.sort()) ? '#dce2e1' : 'red';
            } else {
                color = current[key as keyof CommonSystemParams] === previous[key as keyof CommonSystemParams] ? '#dce2e1' : 'red';
            }
        } else {
            color = '#dce2e1';
        }
        return {
            color: color,
        };
    }
}
