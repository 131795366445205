import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';
import { TEST_TYPE, IExaminationRepresentative, IBulbicamTest, ICommonCamMessage } from 'common-lib';

@Component({
    selector: 'fetch-chart-data',
    templateUrl: './fetch-chart-data.component.html',
    styleUrls: ['./fetch-chart-data.component.scss'],
})
export class FetchChartDataComponent implements OnInit {
    public representative: IExaminationRepresentative;
    public charts: { testType: TEST_TYPE; charts: IBulbicamTest<ICommonCamMessage>[] }[] = [];
    public existingData: { testType: TEST_TYPE; createdAt: number[] }[];
    constructor(private communicationService: ServerCommunicationService, private route: ActivatedRoute) {}
    async ngOnInit() {
        const id: string | null = this.route.snapshot.paramMap.get('id');
        if (!id) return;
        this.representative = await this.communicationService.sockets.ExaminationSocket.getRepresentativeById(id);
        this.charts = await this.communicationService.sockets.RemotePointSocket.fetchCharts(this.representative);
        this.existingData = await this.communicationService.sockets.RemotePointSocket.showExistingChartData();
    }
    public async download(testType: TEST_TYPE, chart: IBulbicamTest<ICommonCamMessage>): Promise<void> {
        await this.communicationService.sockets.RemotePointSocket.fetchChartData(testType, chart.createdAt, this.representative.sourceID, chart.haplotestData);
        this.existingData = await this.communicationService.sockets.RemotePointSocket.showExistingChartData();
    }
    public async delete(testType: TEST_TYPE, chart: IBulbicamTest<ICommonCamMessage>): Promise<void> {
        await this.communicationService.sockets.RemotePointSocket.deleteChartData(testType, chart.createdAt);
        this.existingData = await this.communicationService.sockets.RemotePointSocket.showExistingChartData();
    }
    public isDownloaded(testType: TEST_TYPE, createdAt: number): boolean {
        if (!this.existingData?.some((d) => d.testType === testType)) return false;
        return this.existingData.find((d) => d.testType === testType)!.createdAt.some((cr) => cr === createdAt);
    }
}
