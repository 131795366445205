import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { ServerCommunicationService } from '../services/serverCommunication.service';

export const roleGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const expectedRole = route.data['expectedRole']; // Check if route.data is defined
    const communicationService = inject(ServerCommunicationService);
    /**
     * Check if the user has the required role to access the route.
     * @param expectedRole Array of expected roles.
     * @returns boolean Returns true if the user has one of the expected roles, otherwise false.
     */
    if (expectedRole.includes(communicationService.currentUser.role)) {
        return true;
    } else {
        return false;
    }
};
