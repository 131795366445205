import { TestHepler, CONSULTATION_TYPE, DOCTORS_SPECIALITY, TEST_TYPE, TEST_STAGE, IExaminationRepresentative } from 'common-lib';
import { ObjectId } from 'mongodb';

export class ExaminationRepresentativeFrontend implements IExaminationRepresentative {
    _id: string | ObjectId | undefined;
    localId: string;
    sourceID: string;
    customerID: string;
    timeZone: string;
    createdAt: number;
    lastModified: number | undefined;
    synchronizedAt: number | undefined;
    completedTests: (TestHepler & { count: number })[];
    doctorsSpeciality: DOCTORS_SPECIALITY[];
    language: string[];
    consultationType: CONSULTATION_TYPE[];
    doctorsLicence: string[];
    sourceIPaddress: string;
    HUBversion: string;
    CAMversion: string;

    customerName: string;
    sourceName: string;
    constructor() {}
    set model(model: IExaminationRepresentative) {
        this._id = model._id;
        this.localId = model.localId;
        this.sourceID = model.sourceID;
        this.customerID = model.customerID;
        this.timeZone = model.timeZone;
        this.createdAt = model.createdAt;
        this.lastModified = model.lastModified;
        this.synchronizedAt = model.synchronizedAt;
        this.completedTests = model.completedTests;
        this.doctorsSpeciality = model.doctorsSpeciality;
        this.language = model.language;
        this.consultationType = model.consultationType;
        this.doctorsLicence = model.doctorsLicence;
        this.HUBversion = model.HUBversion;
        this.CAMversion = model.CAMversion;
        this.sourceIPaddress = model.sourceIPaddress;
    }
    public static sorterByStage(t1: TestHepler, t2: TestHepler): number {
        if (t1.stage === TEST_STAGE.SHOWN && t2.stage === TEST_STAGE.HIDDEN) {
            return -1;
        } else if (t1.stage === TEST_STAGE.HIDDEN && t2.stage === TEST_STAGE.SHOWN) {
            return 1;
        } else {
            return 0;
        }
    }
    public static customSorter(t1: TestHepler, t2: TestHepler): number {
        const compariser = function (t: TestHepler): number {
            switch (t.type) {
                case TEST_TYPE.DARK_ADAPTAION_AMD:
                    return 2;
                case TEST_TYPE.PUPILLARY_EVALUATION2:
                    return 4;
                case TEST_TYPE.NYSTAGMUS_EVALUATION:
                    return 5;
                case TEST_TYPE.PTOSIS_EVALUATION:
                    return 7;
                case TEST_TYPE.ACUITY4KIDS:
                    return 8;
                case TEST_TYPE.CONVERGENCE:
                    return 10;
                case TEST_TYPE.PUPILLARY_EVALUATION:
                    return 11;
                case TEST_TYPE.ANTISACCADE:
                    return 15;
                case TEST_TYPE.SACCADES:
                    return 16;
                default:
                    return 0;
                    break;
            }
        };
        if (compariser(t1) < compariser(t2)) {
            return -1;
        } else if (compariser(t1) > compariser(t2)) {
            return 1;
        } else {
            return 0;
        }
    }
    public static deviceSorter(t1: TestHepler, t2: TestHepler): number {
        if (t1.device < t2.device) {
            return -1;
        } else if (t1.device > t2.device) {
            return 1;
        } else {
            return 0;
        }
    }
}
