import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import moment from 'moment';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';
import { SORTING } from 'common-lib';
import { IExaminationsSearchTerms } from '../../../../../common/interfaces/examinationsSearchTerms';
import { DatepickerComponent } from '../custom-inputs/datepicker/datepicker.component';
import { SimpleExamsChartComponent } from '../simple-exams-chart/simple-exams-chart.component';
import { SingleselectComponent } from 'angular-staff';

@Component({
    selector: 'statistics',
    templateUrl: 'statistics.component.html',
    styleUrls: ['statistics.component.scss'],
})
export class StatisticsComponent implements OnInit, OnDestroy {
    public filterForm: FormGroup;
    public readonly maxDate: Date = new Date();
    public maxDateForStartDatePicker: Date = new Date();
    public minDateForEndDatePicker: Date;
    private open: boolean = true; // to prevent bug when changing max date value of min datepicker fires value change event
    private cnt: number;
    public readonly timeRanges: string[] = ['This week', 'Last week', 'This month', 'Last month', 'This year', 'Last year', 'Custom'];
    @ViewChild(SingleselectComponent) private rangeComponent: SingleselectComponent;
    @ViewChild('startPicker') private startPicker: DatepickerComponent;
    @ViewChild('endPicker') private endPicker: DatepickerComponent;
    @ViewChild(SimpleExamsChartComponent) private chart: SimpleExamsChartComponent;
    constructor(private formBuilder: FormBuilder, private communicationService: ServerCommunicationService) {
        this.filterForm = this.formBuilder.group({
            range: null,
            startDate: null,
            endDate: null,
        });
    }
    ngOnInit() {
        this.filterForm
            .get('range')!
            .valueChanges.pipe(
                map((range: string) => {
                    let start: number | undefined, end: number | undefined;
                    switch (range) {
                        case 'Today':
                            start = moment().startOf('day').valueOf();
                            end = moment().valueOf();
                            break;
                        case 'This week':
                            start = moment().startOf('isoWeek').valueOf();
                            end = moment().valueOf();
                            break;
                        case 'Last week':
                            start = moment().subtract(1, 'week').startOf('isoWeek').valueOf();
                            end = moment().subtract(1, 'week').endOf('isoWeek').valueOf();
                            break;
                        case 'This month':
                            start = moment().startOf('month').valueOf();
                            end = moment().valueOf();
                            break;
                        case 'Last month':
                            start = moment().subtract(1, 'month').startOf('month').valueOf();
                            end = moment().subtract(1, 'month').endOf('month').valueOf();
                            break;
                        case 'This year':
                            start = moment().startOf('year').valueOf();
                            end = moment().valueOf();
                            break;
                        case 'Last year':
                            start = moment().subtract(1, 'year').startOf('year').valueOf();
                            end = moment().subtract(1, 'year').endOf('year').valueOf();
                            break;
                        default:
                            this.startPicker.open();
                            break;
                    }
                    return { start, end };
                })
            )
            .subscribe((range) => {
                this.open = false;
                this.filterForm.get('startDate')!.setValue(moment(range.start).toDate());
                this.filterForm.get('endDate')!.setValue(moment(range.end).toDate());
            });
        this.filterForm.get('startDate')!.valueChanges.subscribe((startRange: Date) => {
            this.minDateForEndDatePicker = startRange;
            if (this.open) this.endPicker.open();
        });
        this.filterForm
            .get('endDate')!
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe(async (endRange: Date) => {
                this.open = true;
                this.maxDateForStartDatePicker = endRange;
                this.filterForm.get('range')!.setValue(this.defineRange(), {
                    emitEvent: false,
                });
                const terms: IExaminationsSearchTerms = {
                    itemsPerPage: undefined,
                    page: undefined,
                    orderBy: 'createdAt',
                    orderDirection: SORTING.ASC,
                    customerID: undefined,
                    sourceID: undefined,
                    createdAt: {
                        startRange: moment(this.filterForm.get('startDate')!.value).valueOf(),
                        endRange: moment(endRange).endOf('day').valueOf(),
                    },
                };
                const res = await this.communicationService.sockets.ExaminationSocket.search(terms);
            });
    }

    ngOnDestroy(): void {}

    private defineRange(): string {
        const startRange: number = moment(this.filterForm.get('startDate')!.value).valueOf(),
            endRange: number = moment(this.filterForm.get('endDate')!.value).endOf('day').valueOf();
        if (startRange === moment().startOf('day').valueOf()) {
            return 'Today';
        } else if (startRange === moment().startOf('isoWeek').valueOf() && endRange > moment().startOf('day').valueOf()) {
            return 'This week';
        } else if (startRange === moment().subtract(1, 'week').startOf('isoWeek').valueOf() && endRange === moment().subtract(1, 'week').endOf('isoWeek').valueOf()) {
            return 'Last week';
        } else if (startRange === moment().startOf('month').valueOf() && endRange > moment().startOf('day').valueOf()) {
            return 'This month';
        } else if (startRange === moment().subtract(1, 'month').startOf('month').valueOf() && endRange === moment().subtract(1, 'month').endOf('month').valueOf()) {
            return 'Last month';
        } else if (startRange === moment().startOf('year').valueOf() && endRange > moment().startOf('day').valueOf()) {
            return 'This year';
        } else if (startRange === moment().subtract(1, 'year').startOf('year').valueOf() && endRange === moment().subtract(1, 'year').endOf('year').valueOf()) {
            return 'Last year';
        } else {
            return 'Custom';
        }
    }
}
