import { ILuxValue, ILuxCalibration, IInterpolatedLuxValues } from '../../interfaces/calibration/luxCalibration.interface';
import { CALIBRATION_TEST } from '../../enum/calibration.command.enum';
import { CommonCalibrationTest } from './common.calibration.test.model';

export class LuxCalibration implements CommonCalibrationTest, ILuxCalibration {
    public type: CALIBRATION_TEST = CALIBRATION_TEST.LUX_CALIBRATION;
    public calibrations: ILuxValue[];
    public interpolatedLuxValuesOD: IInterpolatedLuxValues[];
    public interpolatedLuxValuesOS: IInterpolatedLuxValues[];
    public minLuxValue: number;

    public setModel(model: ILuxCalibration): void {
        this.calibrations = model.calibrations;
        this.minLuxValue = model.minLuxValue;
        this.interpolatedLuxValuesOD = model.interpolatedLuxValuesOD;
        this.interpolatedLuxValuesOS = model.interpolatedLuxValuesOS;
    }

    public getModel(): ILuxCalibration {
        return {
            calibrations: this.calibrations,
            minLuxValue: this.minLuxValue,
            interpolatedLuxValuesOD: this.interpolatedLuxValuesOD,
            interpolatedLuxValuesOS: this.interpolatedLuxValuesOS,
        };
    }
}
