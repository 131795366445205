import { firstValueFrom } from 'rxjs';
import { IGridFsFileMetadata, SmartImage } from 'common-lib';
import { CommonFileService } from '../services/file.service';

// SmartImageFrontend is a subclass of SmartImage
export class SmartImageFrontend extends SmartImage {
    // constructor calls the superclass constructor
    constructor() {
        super();
    }

    // check if image is downloaded
    public isDownloaded(): boolean {
        return !!this.imageUrl;
    }

    // download image content from fileService
    public async download(fileService: CommonFileService): Promise<void> {
        try {
            // if image is already downloaded or empty, return
            if (this.isDownloaded()) return;
            if (this.isEmpty()) return;

            // fetch image content from fileService and set it as imageUrl
            this.imageUrl = await fileService.getFileContentByFileID(this.imageID);
        } catch (error) {
            console.log(error);
        }
    }

    // extract image content from imageUrl
    public get imageContent(): string {
        let url: string = '';
        if (this.imageUrl) url = this.imageUrl.slice(4, this.imageUrl.length - 1);
        return url;
    }

    // get the aspect ratio of the image
    public async getRatio(): Promise<number> {
        return new Promise<number>((res, rej) => {
            if (!this.imageUrl) {
                rej('no imageUrl to get ratio');
            } else {
                // create a new Image and calculate aspect ratio on load
                let img = new Image();
                img.onload = () => {
                    let height = img.height;
                    let width = img.width;
                    res(width / height);
                };
                img.src = this.imageContent;
            }
        });
    }

    // get dimentions of the image
    public async getDimentions(): Promise<{ width: number; height: number }> {
        return new Promise<{ width: number; height: number }>((resolve, reject) => {
            if (!this.imageUrl) {
                reject('no imageUrl to get dimentions');
            } else {
                // create a new Image and return dimentions on load
                const img = new Image();
                img.onload = () => {
                    resolve({ width: img.width, height: img.height });
                };
                img.onerror = reject;
                img.src = this.imageContent;
            }
        });
    }

    // save image content to fileService and set imageID
    public override async saveToDb(metadata: IGridFsFileMetadata, fileService: CommonFileService): Promise<SmartImageFrontend> {
        try {
            if (this.imageUrl && !this.imageID) {
                // if imageUrl is not null and imageID is null, save the file and set imageID
                metadata.mimeType = this.mimeType;
                this.imageID = await fileService.saveFile(this.imageUrl, this.remark.name, metadata);
            }
            return this;
        } catch (error) {
            throw error;
        }
    }

    // update image content in fileService with the new imageUrl and update imageID
    public async update(fileService: CommonFileService): Promise<void> {
        try {
            if (this.imageUrl && this.imageID) {
                // if imageUrl and imageID are both not null, update the file and set new imageID
                const oldID: string = this.imageID,
                    responce: { newID: string } = await firstValueFrom(fileService.updateFile(oldID, this.imageUrl));
                this.imageID = responce.newID;
            } else {
                throw new Error('Cannot update image with missing ID or content.');
            }
        } catch (error) {
            throw error;
        }
    }

    // rotate image by 90 degrees
    public async rotate90deg(): Promise<void> {
        return new Promise<void>((res) => {
            const image = new Image();
            image.onload = async () => {
                // create a canvas element, rotate image and set it as imageUrl
                const canvas = document.createElement('canvas'),
                    ctx = canvas.getContext('2d');
                canvas.width = image.height;
                canvas.height = image.width;
                ctx?.translate(canvas.width / 2, canvas.height / 2);
                ctx?.rotate(Math.PI / 2);
                ctx?.drawImage(image, -image.width / 2, -image.height / 2);
                this.imageUrl = `url(${canvas.toDataURL()})`;
                res();
            };
            image.src = this.imageContent;
        });
    }
}
