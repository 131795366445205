import { Component, OnInit } from '@angular/core';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';

@Component({
    selector: 'register-key',
    templateUrl: 'register-key.component.html',
    styleUrls: ['register-key.component.scss'],
})
export class RegisterKeyComponent implements OnInit {
    public registeredKeys: number;
    constructor(private communicationService: ServerCommunicationService) {}
    async ngOnInit(): Promise<void> {
        this.registeredKeys = await this.communicationService.sockets.AuthSocket.getKeysCount();
    }
    public async register(): Promise<void> {
        await this.communicationService.sockets.UnsecuredSocket.registerKey(this.communicationService.currentUser._id as string);
        this.registeredKeys = await this.communicationService.sockets.AuthSocket.getKeysCount();
    }
}
