import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, distinctUntilChanged } from 'rxjs';
import { Manager, Socket } from 'socket.io-client';
import { CALIBRATION_COMMAND, CALIBRATION_TEST, CalibrationResultType, ICalibrationResult, ROLE, UserFrontend, calibrationTestsSequence } from 'common-lib';
import { IFrontendConfig } from '../../../../common/interfaces/frontendConfig';
import { SocketsStorage } from '../classes/sockets/socketsStorage';
import conf from '../../../../common/configurations/frontendConfig.json';
import { CommonAuthenticationService, CommonBulbicamService, CommonConfigService, CommonSocketService } from 'angular-staff';

@Injectable({
    providedIn: 'root',
})
export class ServerCommunicationService implements CommonAuthenticationService, CommonBulbicamService, CommonConfigService, CommonSocketService {
    private _frontendConfiguration: IFrontendConfig;
    private _manager: Manager;
    public currentUser: UserFrontend<any>;
    private _socketError: Subject<Error>;
    private _socketsStorage: SocketsStorage;
    constructor(private inj: Injector, private router: Router) {
        this._frontendConfiguration = conf;
        this.currentUser = new UserFrontend<any>();
        this._socketError = new Subject<any>();
        this._socketError.pipe(distinctUntilChanged(this.errorChangeDetector)).subscribe(async (error) => {
            if (error.message.includes('jwt expired') || error.message.includes('jwt malformed')) {
                localStorage.clear();
                delete this._manager.opts.query;
                await this._socketsStorage.close();
                this.currentUser = new UserFrontend();
                window.location.reload();
            } else {
                console.log('SocketError - ', error);
            }
        });
        this._manager = new Manager(this._frontendConfiguration.server.protocol + '://' + this._frontendConfiguration.server.ip + ':' + this._frontendConfiguration.server.port, {
            path: '/api/v1',
            autoConnect: false,
            secure: true,
            rejectUnauthorized: false,
        });
        this._manager.on('reconnect_attempt', () => {
            console.log('reconnect attempt');
            const token: string | null = localStorage.getItem('token');
            if (token !== null) {
                this._manager.opts.query = { token: token };
            }
        });
        this._manager.on('reconnect_error', (error) => {
            console.log('reconnect error');
            console.log(error);
        });
        this._manager.on('reconnect_failed', () => {
            console.log('reconnect_failed');
        });
        this._manager.on('error', (error: any) => {
            console.log('error');
            console.log(error);
        });
        this._socketsStorage = new SocketsStorage(this._manager, this._socketError);
        this.sockets.ConfigSocket.configurationsSource.subscribe((conf) => (this._frontendConfiguration.version = conf.version));
    }
    public cancelReturnFromCalibrationTest(): void {
        const url: string[] = this.router.routerState.snapshot.url.split('/');
        this.router.navigate([url.slice(0, -2).join('/')]);
    }
    public gotoNextCalibrationTest(current: CALIBRATION_TEST): void {
        const currentTestOrder: number = calibrationTestsSequence.find((t) => t.type === current)!.order;
        const nextTestType = calibrationTestsSequence.find((t) => t.order === currentTestOrder + 1)?.type;
        const url: string[] = this.router.routerState.snapshot.url.split('/');
        if (!nextTestType) {
            this.router.navigate([url.slice(0, -2).join('/')]);
            return;
        }
        if (nextTestType === CALIBRATION_TEST.SHORT_TIMING_TEST || nextTestType === CALIBRATION_TEST.LONG_TIMING_TEST) {
            url[5] = 'TIMING_TEST';
            this.router.navigate([url.join('/')]);
            return;
        }
        url[5] = nextTestType;
        this.router.navigate([url.join('/')]);
    }
    public gotoPreviousCalibrationTest(current: CALIBRATION_TEST): void {
        const currentTestOrder: number = calibrationTestsSequence.find((t) => t.type === current)!.order;
        const nextTestType = calibrationTestsSequence.find((t) => t.order === currentTestOrder - 1)!.type;
        const url: string[] = this.router.routerState.snapshot.url.split('/');
        if (nextTestType === CALIBRATION_TEST.SHORT_TIMING_TEST || nextTestType === CALIBRATION_TEST.LONG_TIMING_TEST) {
            url[5] = 'TIMING_TEST';
            this.router.navigate([url.join('/')]);
            return;
        }
        url[5] = nextTestType;
        this.router.navigate([url.join('/')]);
    }
    socket: Socket | undefined;
    getCalibrationResults<T extends CalibrationResultType>(type: CALIBRATION_TEST[]): Observable<ICalibrationResult<T>[]> {
        throw new Error('Method not implemented.');
    }
    sendCalibrationCommand<T extends { command: CALIBRATION_COMMAND; test: CALIBRATION_TEST; socketIoId?: string | undefined }>(payload: T): Observable<void> {
        throw new Error('Method not implemented.');
    }
    saveCalibrationResult<T extends CalibrationResultType>(payload: ICalibrationResult<T>): Observable<void> {
        throw new Error('Method not implemented.');
    }
    get camState(): boolean {
        return false;
    }
    deleteCalibrationResult(test: CALIBRATION_TEST, createdAt: number): Observable<void> {
        throw new Error('Method not implemented.');
    }

    public async load(): Promise<void> {
        return new Promise<void>(async (res) => {
            try {
                if (location.pathname?.split('/')[1] === 'auth' && location.pathname.split('/')[2]?.split('.').length === 3) {
                    let token = location.pathname.split('/')[2];
                    this._manager.opts.query = { invite_token: token };
                    this._manager.open(async (error) => {
                        try {
                            if (error) {
                                throw error;
                            } else {
                                await this._socketsStorage.AuthSocket.connect();
                                await this._socketsStorage.UserSocket.connect();
                                this.sockets.AuthSocket.findInvitationCredentialsByToken().then((creds) => {
                                    switch (creds.role) {
                                        case ROLE.ADMIN:
                                            this.inj.get(Router).navigate(['/auth/register/admin'], { queryParams: creds });
                                            break;
                                        case ROLE.DOCTOR:
                                            this.inj.get(Router).navigate(['/auth/register/doctor'], { queryParams: creds });
                                            break;
                                        case ROLE.MANAGER:
                                            this.inj.get(Router).navigate(['/auth/register/manager'], { queryParams: creds });
                                            break;
                                        case ROLE.CUSTOMER:
                                            this.inj.get(Router).navigate(['/auth/register/customer'], { queryParams: creds });
                                            break;
                                        default:
                                            console.log(creds);
                                            break;
                                    }
                                    return false;
                                });
                                this.inj.get(Router).navigate(['/auth/' + token]);
                                res();
                            }
                        } catch (error) {
                            console.error(error);
                            this.logout();
                            res();
                        }
                    });
                } else if (localStorage.getItem('token')) {
                    let token = localStorage.getItem('token')!;
                    this._manager.opts.query = { token };
                    if (this._manager._readyState === 'closed') {
                        this._manager.open(async (error) => {
                            try {
                                if (error) {
                                    throw error;
                                } else {
                                    await this._socketsStorage.connect();
                                    this.currentUser.tokens = { token };
                                    res();
                                }
                            } catch (error) {
                                console.error(error);
                                this.logout();
                                res();
                            }
                        });
                    } else {
                        await this._socketsStorage.close();
                        await this._socketsStorage.connect();
                        this.currentUser.tokens = { token };
                        res();
                    }
                } else if (location.pathname.includes('issue')) {
                    const issueID: string = location.pathname.split('/')[2];
                    this._manager.opts.query = { issue: issueID };
                    this._manager.open(async (error) => {
                        try {
                            if (error) {
                                throw error;
                            } else {
                                await this._socketsStorage.IssuesSocket.connect();
                                res();
                            }
                        } catch (error) {
                            console.error(error);
                            this.logout();
                            res();
                        }
                    });
                } else {
                    this.inj.get(Router).navigate([location.pathname]);
                    res();
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    public async sendInitialInvitation(): Promise<void> {
        if (this._manager._readyState === 'closed') {
            await new Promise<void>((res1, rej) => {
                this._manager.open(async (error) => {
                    if (error) rej(error);
                    res1();
                });
            });
        }
        await this._socketsStorage.UnsecuredSocket.connect();
        const error = await this._socketsStorage.UnsecuredSocket.sendInitialInvitation();
        if (error) {
            alert(error);
        } else {
            alert('invitation successfully sent.');
        }
        await this._socketsStorage.UnsecuredSocket.close();
    }

    public async logout(): Promise<void> {
        return new Promise<void>(async (res, rej) => {
            try {
                this._socketsStorage.AuthSocket.logout();
                localStorage.clear();
                delete this._manager.opts.query;
                await this._socketsStorage.close();
                this.currentUser = new UserFrontend();
                this.inj.get(Router).navigate(['/auth']);
                res();
            } catch (error) {
                console.log(error);
                rej(error);
            }
        });
    }

    public get userSource(): Observable<UserFrontend<any>> {
        return this.currentUser.source.asObservable();
    }

    public get sockets(): SocketsStorage {
        return this._socketsStorage;
    }

    public get version(): string {
        return this._frontendConfiguration.version;
    }

    public get itemsPerPage(): number {
        return this._frontendConfiguration.pageSettings.dashboard.itemsPerPage;
    }

    public homeRedirect(): void {
        if (this.currentUser.token) {
            switch (this.currentUser.role) {
                case ROLE.DOCTOR:
                    this.inj.get(Router).navigate(['/dashboard-doctor']);
                    break;
                case ROLE.ADMIN:
                    this.inj.get(Router).navigate(['/dashboard-admin']);
                    break;
                case ROLE.PRODUCTION:
                    this.inj.get(Router).navigate(['/dashboard-production']);
                    break;
                case ROLE.MANAGER:
                    this.inj.get(Router).navigate(['/dashboard-manager']);
                    break;
                default:
                    break;
            }
        }
    }
    private errorChangeDetector(error1: Error, error2: Error): boolean {
        return error1.message === error2.message;
    }

    public async completeLogin(token: string): Promise<void> {
        localStorage.setItem('token', token);
        await this.load();
    }
}
