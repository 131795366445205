<div class="test-container scrollable">
    <div class="test-header">{{ name }}</div>
    <div class="test-main">
        <div class="test-main-buttons">
            <button type="button" class="normalButton primaryButton" (click)="toggleTest()" [disabled]="!configService.camState">
                {{ isTestRunning ? 'Stop Pattern Identification' : 'Start Pattern Identification' }}
            </button>
            <!-- <button type="button" class="normalButton primaryButton" (click)="toggleTest()">Redo Shape ID</button> -->
            <div class="test-main-buttons-action">
                <button type="button" class="normalButton primaryButton" [disabled]="!isUnsavedResultExists || isSavingResults || isTestRunning" (click)="saveResults()">
                    Save Results
                </button>
                <button type="button" class="normalButton primaryButton" [disabled]="(!selectedIndex && selectedIndex !== 0) || selectedIndex < 0" (click)="deleteInputResults()">
                    Delete
                </button>
            </div>
        </div>
        <div class="test-main-picture">
            <image-carousel [formControl]="image" [isDeletable]="false" [isUploadable]="false"></image-carousel>
        </div>
        <div class="test-main-tips">
            <h4 style="margin-bottom: 10px">This test can take up to one minute!</h4>
            <div class="test-main-tips-header">Explanation</div>
            <div class="test-main-tips-content">
                This test verifies that the calibration shape is correctly identified and located at acceptable positions in the camera picture
            </div>
        </div>
    </div>

    <div class="test-results">
        <div class="test-results-header">Calibration History</div>
        <div class="test-results-content">
            <div
                class="test-results-content-column"
                *ngFor="let column of results; let ind = index"
                [ngClass]="{ odd: ind % 2, selected: ind === selectedIndex }"
                (click)="selectActive(ind)"
            >
                <div class="results-row">
                    <ng-container *ngIf="column?.isTemp">
                        <span style="color: #eb5757">Temporary Result</span>
                    </ng-container>
                    <ng-container *ngIf="!column?.isTemp">
                        <div class="left">
                            {{ column.createdAt | date : 'yyyy.MM.dd' }}
                        </div>
                        <div class="right">
                            {{ column.createdAt | date : 'hh:mm' }}
                        </div>
                    </ng-container>
                </div>
                <div class="results-row">
                    <!-- <div class="left">OD</div> -->
                    <div class="left">
                        <div class="upper">OD Angle</div>
                        <div class="lower">OD Center X</div>
                        <div class="lower">OD Center Y</div>
                    </div>
                    <div class="right">
                        <div class="upper">{{ column.results?.odAngle | number : '1.0-1' }}</div>
                        <div class="lower">{{ column.results?.odCenterX | number : '1.0-1' }}</div>
                        <div class="lower">{{ column.results?.odCenterY | number : '1.0-1' }}</div>
                    </div>
                </div>
                <div class="results-row">
                    <!-- <div class="left">OS</div> -->
                    <div class="left">
                        <div class="upper">OS Angle</div>
                        <div class="lower">OS Center X</div>
                        <div class="lower">OS Center Y</div>
                    </div>
                    <div class="right">
                        <div class="upper">{{ column.results?.osAngle | number : '1.0-1' }}</div>
                        <div class="lower">{{ column.results?.osCenterX | number : '1.0-1' }}</div>
                        <div class="lower">{{ column.results?.osCenterY | number : '1.0-1' }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="test-buttons">
        <button type="button" class="normalButton primaryButton" (click)="configService.gotoPreviousCalibrationTest(calibrationType)" [disabled]="isTestRunning">
            {{ 'Previous test' }}
        </button>
        <button type="button" class="normalButton secondaryButton" (click)="configService.cancelReturnFromCalibrationTest()">{{ 'Cancel / Return' }}</button>
        <button type="button" class="normalButton primaryButton" (click)="configService.gotoNextCalibrationTest(calibrationType)" [disabled]="isTestRunning">
            {{ 'Next test' }}
        </button>
    </div>
</div>
