<doctor-paginator></doctor-paginator>
<div class="content">
    <div class="header">
        <div class="testCell oddHighlited">
            <h4>Examination ID</h4>
        </div>
        <div class="testCell oddHighlited">
            <h4>Customer</h4>
        </div>
        <div class="testCell oddHighlited">
            <h4>Source</h4>
        </div>
    </div>
    <div class="examinationsList">
        <div class="column" *ngFor="let exam of _examinations | async">
            <div class="testCell oddHighlited" [routerLink]="['/dashboard-doctor/representative-overview', exam]">
                <p [style.color]="exam.timeZone ? '' : 'salmon'">{{ exam.createdAt | timeZoneDate : 'dd.MM.yy H:mm:ss' : exam.timeZone }}</p>
            </div>
            <div class="testCell oddHighlited">
                <p>{{ exam.customerName }}</p>
            </div>
            <div class="testCell oddHighlited">
                <p>{{ exam.sourceName }}</p>
            </div>
        </div>
        <div class="column last">
            <div class="testCell oddHighlited">
                <p></p>
            </div>
            <div class="testCell oddHighlited">
                <p></p>
            </div>
            <div class="testCell oddHighlited">
                <p></p>
            </div>
        </div>
    </div>
</div>
<div class="content scrollable">
    <div class="header">
        <div class="testCell evenHighlited" *ngFor="let test of tests">
            <h5>{{ test.name }}</h5>
        </div>
    </div>
    <div class="examinationsList">
        <div class="column columnBased" *ngFor="let examination of _examinations | async">
            <div *ngFor="let test of tests" class="testCell evenHighlited">
                <!-- <div *ngIf="getTest(test, examination); let testCell; else: noTest" (click)="showRemarks(testCell)">
                    {{ testCell.count }}
                    <i *ngIf="testCell.isRemarksExist()" class="aico-checked"></i>
                </div> -->
                <ng-template #noTest> </ng-template>
            </div>
        </div>
        <div class="column last">
            <div *ngFor="let test of tests" class="testCell evenHighlited"></div>
        </div>
    </div>
</div>
