import {CommonCalibrationTest} from './common.calibration.test.model';
import {IScreenCalibration} from '../../interfaces/calibration/screenCalibration.interface';
import {CALIBRATION_TEST} from '../../enum/calibration.command.enum';

export class ScreenCalibration implements CommonCalibrationTest, IScreenCalibration {
    distance: number;

    type: CALIBRATION_TEST = CALIBRATION_TEST.SCREEN_CALIBRATION;

    public setModel(model: IScreenCalibration): void {
        this.distance = model.distance;
    }

    public getModel(): IScreenCalibration {
        return {
            distance: this.distance,
        };
    }
}
