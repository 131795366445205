import { IOSocket } from '../sockets/ioSocket';
import { throwError } from 'rxjs';

export function checkSocketPromise() {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        const originalMethod = descriptor.value;

        descriptor.value = async function (...args: any[]) {
            const instance = this as IOSocket;
            if (!instance.connected) {
                return Promise.reject(`${instance.name} socket is disconnected`);
            }
            return originalMethod.apply(this, args);
        };

        return descriptor;
    };
}

export function checkSocketObservable() {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        const originalMethod = descriptor.value;

        descriptor.value = function (...args: any[]) {
            const instance = this as IOSocket;
            if (!instance.connected) {
                return throwError(() => new Error(`${instance.name} socket is disconnected`));
            }
            return originalMethod.apply(this, args);
        };

        return descriptor;
    };
}
