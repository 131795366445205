import { ObjectId } from 'mongodb';
import { TEST_TYPE } from '../enum/test-type';
import { ITechStaffRoleProperties } from './roleProperties/techStaffRoleProperties.interface';
import { IUser } from './user';
/**
 * An interface to describe an oject to hold user's feedback (grade) of test measurement
 * Feedback has 2 types: recording and clinical
 * One cam test measurement can have only one grade object in DB for one author
 * Each author can have own feedback object for same CAM measurement in DB
 *
 * _id: identificator in DB
 * examinationID: reference to corresponding examination ID in DB
 * testType: type of test presented in ixamination
 * measurementCreatedAt: field to determine exact measurement in test object this grade was created for
 * createdAt: timestamp of grade creation
 * recordingGrade: recorded grade value itself
 * clinicalGrade: clinical grade value itself
 * freeze: flag if CAM was freezed during test
 * crash: flag if CAM crashed during test
 * comment: random comment from user
 * author: object that represent grade's author data
 */
export interface IGrade {
    _id: string | undefined | ObjectId;
    examinationID: string;
    testType: TEST_TYPE;
    measurementCreatedAt: number;
    createdAt: number;
    recordingGrade: RecordingGrade | null;
    clinicalGrade: ClinicalValueGrade | null;
    freeze: boolean;
    crash: boolean;
    comment: string;
    author: IUser<ITechStaffRoleProperties>;
}

export type RecordingGrade = 1 | 2 | 3 | 4 | 5 | 'Not relevant' | 'Just testing';
export const recordingGrades: RecordingGrade[] = [1, 2, 3, 4, 5, 'Not relevant', 'Just testing'];
export type ClinicalValueGrade = 'Not helpful' | 'Helpful' | 'High' | 'Very high' | 'Doubtful value' | 'Not relevant';
export const clinicalValueGrades: ClinicalValueGrade[] = ['Not helpful', 'Helpful', 'High', 'Very high', 'Doubtful value', 'Not relevant'];

export const isRecordingGradeGuard = (value: any): value is RecordingGrade => {
    return recordingGrades.includes(value);
};

export const isClinicalValueGradeGuard = (value: any): value is ClinicalValueGrade => {
    return clinicalValueGrades.includes(value);
};
