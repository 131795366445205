import { DEVICE } from '../../../../enum/device';
import { TEST_TYPE } from '../../../../enum/test-type';
import { TEST_STAGE } from '../../../../enum/testStage.enum';

export class DryEyeForus3NethraClassicTest {
    public readonly type: TEST_TYPE = TEST_TYPE.DRY_EYE_EVALUATION;
    public readonly device: DEVICE = DEVICE.FORUS_3NETHRA;
    public readonly name: string = 'Dry Eye evaluation';
    public stage: TEST_STAGE;
}
