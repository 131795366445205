<div class="test-container scrollable">
    <div class="test-header">{{ name }}</div>

    <div class="test-main-wrapper">
        <div class="test-main">
            <div class="test-titles">
                <div>
                    <span>Diopters</span>
                </div>
                <div>
                    <span>OS</span>
                    <span>OD</span>
                </div>
                <div class="test-results">
                    <span>OS</span>
                    <span>OD</span>
                </div>
            </div>
            <div *ngFor="let item of DIOPTERS_LIST; let id = index" class="test-content">
                <div>
                    <span>{{ item }}</span>
                </div>
                <div class="test-content-buttons">
                    <div>
                        <button
                            type="button"
                            class="normalButton primaryButton"
                            id="OS-button-{{ id }}"
                            (click)="toggleTest(item, OCULUS.OS)"
                            [disabled]="getDisableState(id, OCULUS.OS)"
                        >
                            {{ setButtonName(id, OCULUS.OS) }}
                        </button>
                    </div>
                    <div>
                        <button
                            type="button"
                            class="normalButton primaryButton"
                            id="OD-button-{{ id }}"
                            (click)="toggleTest(item, OCULUS.OD)"
                            [disabled]="getDisableState(id, OCULUS.OD)"
                        >
                            {{ setButtonName(id, OCULUS.OD) }}
                        </button>
                    </div>
                </div>
                <div class="test-results">
                    <span id="test-result-OS-{{ id }}" #cellSpanOS>{{ 'None' }}</span>
                    <span id="test-result-OD-{{ id }}" #cellSpanOD>{{ 'None' }}</span>
                </div>
            </div>
        </div>

        <div class="test-main-picture">
            <image-carousel [formControl]="image" [isDeletable]="false" [isUploadable]="false"></image-carousel>
        </div>

        <div class="test-main-tips">
            <h4 style="margin-bottom: 10px">Insert Lensholder with PD 63</h4>
        </div>
    </div>

    <button type="button" class="normalButton primaryButton save-button" [disabled]="!isTestDone" (click)="saveResults()">Save Results</button>

    <div class="test-list-wrapper">
        <ul class="test-list">
            <li *ngFor="let el of calibrationResults; let i = index">
                <span class="list-item" (click)="selectActive(i)" [ngClass]="{ active: selectedIndex === i }">{{ el.createdAt | date : 'dd/MM/yyyy hh:mm' }}</span>
                <i class="aico-noUpload cross" (click)="deleteResult(i)"></i>
            </li>
        </ul>
    </div>
    <div class="test-buttons">
        <button type="button" class="normalButton primaryButton" (click)="configService.gotoPreviousCalibrationTest(calibrationType)" [disabled]="isTestRunning">
            {{ 'Previous test' }}
        </button>
        <button type="button" class="normalButton secondaryButton" (click)="configService.cancelReturnFromCalibrationTest()">{{ 'Cancel / Return' }}</button>
        <button type="button" class="normalButton primaryButton" (click)="configService.gotoNextCalibrationTest(calibrationType)" [disabled]="isTestRunning">{{ 'Finish' }}</button>
    </div>
</div>
