import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { ServerCommunicationService } from '../services/serverCommunication.service';

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const communicationService = inject(ServerCommunicationService);
    const router = inject(Router);
    // Check if user is logged in and redirect if not
    if (communicationService.currentUser.token) {
        return true;
    }
    // Not logged in, so redirect to login page
    return router.parseUrl('/auth');
};
