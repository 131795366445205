import { DEVICE } from '../../../enum/device';
import { TEST_TYPE } from '../../../enum/test-type';
import { TEST_STAGE } from '../../../enum/testStage.enum';

export class Acuity4KidsBulbicamTest {
    public readonly type: TEST_TYPE = TEST_TYPE.ACUITY4KIDS;
    public readonly device: DEVICE = DEVICE.HAPLO;
    public readonly name: string = 'Acuity 4 kids';
    public stage: TEST_STAGE;
}
