<form [formGroup]="form" novalidate>
    <singleselect [inputClass]="'smallInput'" [label]="'customer *'" [list]="customersList" formControlName="customer"></singleselect>
    <div class="inputWrapper">
        <label>{{ 'ID (BCU serial)' }}*</label>
        <input class="smallInput" type="text" formControlName="email" name="username" />
    </div>
    <singleselect [inputClass]="'smallInput'" [label]="'type'" [list]="POINT_TYPE" formControlName="type"></singleselect>
    <div class="inputWrapper" *ngIf="registerMode">
        <label>{{ 'password (BCUSERIAL&BCUSERIAL)' }}</label>
        <input class="smallInput" type="password" formControlName="password" name="password" />
    </div>
    <div class="inputWrapper" *ngIf="registerMode">
        <label>{{ 'confirm password' }}</label>
        <input class="smallInput" type="password" formControlName="passwordConfirmation" name="password" />
    </div>
    <div class="error" *ngIf="form.errors?.['areEqual']">
        <small>
            {{ 'not equal passwords' }}
        </small>
    </div>
    <div class="error" *ngIf="form.get('password')?.errors?.['pattern']">
        <small>
            {{ 'Password is required (has a number, has upper case letter, has a special character, has a minimum length of 8 characters)' }}
        </small>
    </div>
    <div class="buttons">
        <button class="normalButton primaryButton" (click)="goBack()" *ngIf="!registerMode">{{ 'go back' }}</button>
        <button class="normalButton primaryButton" (click)="registerUser()" [disabled]="form.pristine || form.invalid" *ngIf="registerMode">
            {{ 'save' }}
        </button>
        <button class="normalButton primaryButton" (click)="clear()" [disabled]="form.pristine" *ngIf="registerMode">{{ 'clear' }}</button>
    </div>
</form>
