import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';
import { ROLE } from 'common-lib';

@Component({
    selector: 'invite-user',
    templateUrl: 'invite-user.component.html',
    styleUrls: ['invite-user.component.scss'],
})
export class InviteUserComponent implements OnInit {
    public readonly ROLE: typeof ROLE = ROLE;
    public form: FormGroup;
    constructor(private router: Router, private formBuilder: FormBuilder, private location: Location, private communicationService: ServerCommunicationService) {}
    ngOnInit(): void {
        this.form = this.formBuilder.group({
            role: ['', Validators.required],
            email: [
                null,
                [Validators.required, Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)],
            ],
        });
    }
    public async registerUser() {
        try {
            const rawFormValue = this.form.getRawValue();
            await this.communicationService.sockets.AuthSocket.inviteUser(rawFormValue);
            this.router.navigate(['/']);
        } catch (error) {
            console.log(error);
        }
    }
    public clear() {
        this.form.reset();
    }
    public goBack(): void {
        this.location.back();
    }
}
