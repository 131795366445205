import { IPixelsPerMillimeterResult, ILensDistortionCalibration } from '../../interfaces/calibration/lensDistortionCalibration.interface';
import { SmartImage } from '../../classes/smartImage.class';
import { CALIBRATION_TEST } from '../../enum/calibration.command.enum';
import { CommonCalibrationTest } from './common.calibration.test.model';

export class LensDistortionCalibration implements CommonCalibrationTest, ILensDistortionCalibration {
    public type: CALIBRATION_TEST = CALIBRATION_TEST.LENS_DISTORTION_TEST;
    public lensImage: SmartImage;
    public pixelsPerMillimeterResult: IPixelsPerMillimeterResult[];

    public setModel(model: ILensDistortionCalibration): LensDistortionCalibration {
        this.lensImage.model = model.lensImage;
        this.pixelsPerMillimeterResult = model.pixelsPerMillimeterResult;
        return this;
    }

    public getModel(): ILensDistortionCalibration {
        return {
            lensImage: this.lensImage.model,
            pixelsPerMillimeterResult: this.pixelsPerMillimeterResult,
        };
    }
}
