import { Injectable, OnDestroy } from '@angular/core';
import { CALIBRATION_TEST, getTypeName } from 'common-lib';
import { Subscription } from 'rxjs';

@Injectable()
export abstract class CalibrationTestComponent implements OnDestroy {
    protected calibrationType: CALIBRATION_TEST;
    protected name: string;
    protected subscriptions: Subscription[];
    protected bindedMessageHandler: (...args: any[]) => void;
    constructor(type: CALIBRATION_TEST) {
        this.subscriptions = [];
        this.calibrationType = type;
        this.name = getTypeName(type);
        this.bindedMessageHandler = this.messageHandler.bind(this);
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.customOnDestroy();
    }
    protected abstract messageHandler(data: any): Promise<void>;

    saveResults(): void {}

    startTest(): void {}

    submitTest(): void {}

    protected abstract customOnDestroy(): void;
}
