import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChildren } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CalibrationTestComponent } from '../calibration-test-component';
import { concatMap } from 'rxjs/operators';
import { QueryList } from '@angular/core';
import {
    CALIBRATION_COMMAND,
    CALIBRATION_TEST,
    DIOPTERS_LIST,
    ICalibrationResult,
    ILensDistortionCalibration,
    ILensDistortionResponce,
    IPixelsPerMillimeterResult,
    OCULUS,
} from 'common-lib';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { LensDistortionCalibrationFrontend } from '../../../../classes/calibration-tests/lens-distortion.model';
import { CommonBulbicamService } from '../../../../services/bulbiCam.service';
import { CommonSocketService } from '../../../../services/socket.service';
import { CommonConfigService } from '../../../../services/config.service';
import { CommonFileService } from '../../../../services/file.service';
import { SmartImageFrontend } from '../../../../classes/smartImageFrontend.class';
import { ImageCarouselComponent } from '../../../image-carousel/image-carousel.component';

@Component({
    selector: 'lens-distortion',
    templateUrl: 'lens-distortion.component.html',
    styleUrls: ['lens-distortion.component.scss', '../reusable-styles.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, ImageCarouselComponent, ReactiveFormsModule],
})
export class LensDistortionComponent extends CalibrationTestComponent implements OnInit {
    @ViewChildren('cellSpanOD') spansOD: QueryList<ElementRef>;
    @ViewChildren('cellSpanOS') spansOS: QueryList<ElementRef>;

    public readonly DIOPTERS_LIST: string[] = ['-3 diopter', '0 diopter (no lens)', '+3 diopter', '+6 diopter', '+9 diopter'];
    public readonly SPAN_ID_NAME_OD = 'test-result-OD-';
    public readonly SPAN_ID_NAME_OS = 'test-result-OS-';

    public OCULUS = OCULUS;
    public isTestRunning = false;
    public isTestDone: boolean = false;
    public image: FormControl;
    public calibrationResults: {
        createdAt: number;
        results: ILensDistortionCalibration;
    }[] = [];
    public selectedIndex = -1;
    public pixelsPerMillimeterResults: IPixelsPerMillimeterResult[] = [];

    private unSavedResult: {
        results: LensDistortionCalibrationFrontend | null;
        createdAt: number;
    } = {
        results: null,
        createdAt: Date.now(),
    };
    private pressedItemId: number = -1;
    private selectedEye: OCULUS = OCULUS.NONE;
    private isResultsSaved: boolean = false;

    constructor(
        @Inject('BulbicamService') private bulbicamService: CommonBulbicamService,
        @Inject('SocketService') private socketService: CommonSocketService,
        @Inject('ConfigService') public configService: CommonConfigService,
        @Inject('FileService') public fileService: CommonFileService,
        private route: ActivatedRoute,
        private renderer: Renderer2
    ) {
        super(CALIBRATION_TEST.LENS_DISTORTION_TEST);
        this.image = new FormControl();
        this.initializeResultArray();
    }
    ngOnInit(): void {
        const resolvedData: {
            calibrationResults: ICalibrationResult<ILensDistortionCalibration>[];
        } = this.route.snapshot.data['calibrationResults'];
        this.calibrationResults = resolvedData.calibrationResults.map((el) => {
            return {
                createdAt: el.createdAt,
                results: el.results!,
            };
        });
    }

    customOnDestroy(): void {}

    async messageHandler(data: ILensDistortionResponce): Promise<void> {
        if (data.message_type === CALIBRATION_COMMAND.DATA_PACKAGE) {
            this.setSpansContent(this.pressedItemId, this.selectedEye, data.pixelsPerMillimeter.toString());

            this.pixelsPerMillimeterResults[this.pressedItemId] = {
                diopter: this.getDiopterById(this.pressedItemId) as DIOPTERS_LIST,
                pixelsOS: this.selectedEye === OCULUS.OS ? data.pixelsPerMillimeter : this.pixelsPerMillimeterResults[this.pressedItemId].pixelsOS,
                pixelsOD: this.selectedEye === OCULUS.OD ? data.pixelsPerMillimeter : this.pixelsPerMillimeterResults[this.pressedItemId].pixelsOD,
                id: this.pressedItemId,
            };

            const model: ILensDistortionCalibration = {
                lensImage: data.smartImage,
                pixelsPerMillimeterResult: this.pixelsPerMillimeterResults,
            };

            const lensResults = new LensDistortionCalibrationFrontend(this.fileService, this.bulbicamService);
            lensResults.setModel(model);

            this.unSavedResult.createdAt = +new Date();
            this.unSavedResult.results = lensResults;

            const smartImage: SmartImageFrontend = new SmartImageFrontend();
            smartImage.model = data.smartImage;
            this.image.setValue(smartImage);
        }

        if (data.message_type === CALIBRATION_COMMAND.STOP) {
            this.isTestRunning = false;
            this.isTestDone = true;
            this.socketService.socket!.off(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);
        }
    }

    toggleTest(diopter: string = '', eye: OCULUS = OCULUS.NONE): void {
        if (this.isResultsSaved) {
            this.clearSpansContent();
            this.isResultsSaved = false;
        }

        if (this.isTestRunning) {
            const sub = this.bulbicamService
                .sendCalibrationCommand({
                    test: this.calibrationType,
                    command: CALIBRATION_COMMAND.STOP,
                })
                .subscribe();
            this.subscriptions.push(sub);
        } else {
            const diopterIndex = this.DIOPTERS_LIST.indexOf(diopter);

            if (diopterIndex !== -1 && eye in OCULUS) {
                this.pressedItemId = diopterIndex;
                this.selectedEye = eye;

                this.socketService.socket!.on(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);

                const sub = this.bulbicamService
                    .sendCalibrationCommand({
                        test: this.calibrationType,
                        command: CALIBRATION_COMMAND.START,
                        diopters: diopter,
                        eye: eye,
                    })
                    .subscribe();
                this.subscriptions.push(sub);
            } else {
                throw new Error('Unknown diopter');
            }
        }

        this.isTestRunning = !this.isTestRunning;
        this.isTestDone = false;
    }

    override async saveResults(): Promise<void> {
        const results$ = await this.unSavedResult.results?.saveModel();

        const subscription = results$!.subscribe((res) => {
            this.calibrationResults = res.map((el) => {
                let lensResults = el as ICalibrationResult<ILensDistortionCalibration>;

                return {
                    createdAt: lensResults.createdAt,
                    results: lensResults.results as ILensDistortionCalibration,
                };
            });
        });

        this.subscriptions.push(subscription);

        this.isResultsSaved = true;
        this.isTestDone = false;
        this.unSavedResult = {
            results: null,
            createdAt: Date.now(),
        };
        this.pixelsPerMillimeterResults = [];
        this.initializeResultArray();
    }

    selectActive(index: number): void {
        this.selectedIndex = index;

        const smartImage: SmartImageFrontend = new SmartImageFrontend();

        smartImage.model = this.calibrationResults[index].results.lensImage;
        const lensModel = this.calibrationResults[index].results;

        smartImage.download(this.fileService).then((_) => {
            this.image.setValue(smartImage);
        });

        const result = lensModel.pixelsPerMillimeterResult;

        this.clearSpansContent();
        this.setActiveResult(result);
        this.isResultsSaved = true;
    }

    private setSpansContent(id: number, eye: OCULUS, newValue: string) {
        this.spansOD.forEach((span) => {
            if (span.nativeElement.id === this.SPAN_ID_NAME_OD + id && eye === OCULUS.OD) {
                this.renderer.setProperty(span.nativeElement, 'innerHTML', newValue);
            }
        });
        this.spansOS.forEach((span) => {
            if (span.nativeElement.id === this.SPAN_ID_NAME_OS + id && eye === OCULUS.OS) {
                this.renderer.setProperty(span.nativeElement, 'innerHTML', newValue);
            }
        });
    }

    private clearSpansContent() {
        this.spansOD.forEach((span) => {
            this.renderer.setProperty(span.nativeElement, 'innerHTML', 'None');
        });
        this.spansOS.forEach((span) => {
            this.renderer.setProperty(span.nativeElement, 'innerHTML', 'None');
        });
    }

    private setActiveResult(results: IPixelsPerMillimeterResult[]) {
        const spansOD = this.spansOD.toArray();
        const spansOS = this.spansOS.toArray();

        results.forEach((el, i) => {
            if (el.pixelsOD) {
                this.renderer.setProperty(spansOD[i].nativeElement, 'innerHTML', el.pixelsOD);
            }
            if (el.pixelsOS) {
                this.renderer.setProperty(spansOS[i].nativeElement, 'innerHTML', el.pixelsOS);
            }
        });
    }

    deleteResult(index: number) {
        this.bulbicamService
            .deleteCalibrationResult(this.calibrationType, this.calibrationResults[index].createdAt)
            .pipe(
                concatMap((_) => {
                    this.image.reset();
                    this.image.setValue(null);
                    return this.bulbicamService.getCalibrationResults<ILensDistortionCalibration>([this.calibrationType]);
                })
            )
            .subscribe((res) => {
                this.calibrationResults = res.map((el) => {
                    let lensResults = el as ICalibrationResult<ILensDistortionCalibration>;

                    return {
                        createdAt: lensResults.createdAt,
                        results: lensResults.results as ILensDistortionCalibration,
                    };
                });
            });
    }

    getDisableState(id: number, eye: OCULUS): boolean {
        //Cam not ready
        if (!this.configService.camState) {
            return true;
        }
        //Сurrent button has аlready been pressed or neighboring for the other eye
        if (this.pressedItemId === id) {
            return this.selectedEye !== eye && this.isTestRunning;
        }
        //Another button has already been pressed
        if (this.pressedItemId !== id && this.isTestRunning) {
            return true;
        }

        return false;
    }

    setButtonName(id: number, eye: OCULUS): string {
        if (this.isTestRunning && this.pressedItemId === id && this.selectedEye === eye) {
            return 'Stop';
        }
        if (!this.isTestRunning && this.pressedItemId === id && this.selectedEye === eye && !this.isResultsSaved) {
            return 'Repeat';
        }

        return 'Test';
    }

    initializeResultArray() {
        this.DIOPTERS_LIST.forEach((el) => {
            this.pixelsPerMillimeterResults.push({
                diopter: null,
                pixelsOD: null,
                pixelsOS: null,
                id: null,
            });
        });
    }

    getDiopterById(id: number): DIOPTERS_LIST | null {
        if (id === 0) return DIOPTERS_LIST.MINUS_THREE;
        if (id === 1) return DIOPTERS_LIST.ZERO;
        if (id === 2) return DIOPTERS_LIST.THREE;
        if (id === 3) return DIOPTERS_LIST.SIX;
        if (id === 4) return DIOPTERS_LIST.NINE;

        return null;
    }
}
