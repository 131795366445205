import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[tooltip]',
    standalone: true,
    host: {
        '[style.position]': '"relative"',
    },
})
export class TooltipDirective implements OnInit {
    // Input properties for the tooltip's display condition and content
    @Input() isVisible: boolean = true;
    @Input() content: string;
    @Input() type: 'notification' | 'tooltip' = 'tooltip';
    private element: HTMLElement;
    private span: HTMLElement;

    constructor(private el: ElementRef, private renderer: Renderer2) {}

    ngOnInit() {
        // Create the tooltip's container element
        this.element = this.renderer.createElement('div') as HTMLElement;
        if (this.type === 'notification') {
            const h5 = this.renderer.createElement('h5');
            this.renderer.addClass(this.element, 'notification');
            h5.textContent = 'notification';
            this.renderer.appendChild(this.element, h5);
        }
        this.renderer.addClass(this.element, 'tooltipDirective');
    }

    // Show tooltip on mouseover, only if isVisible is true and content exists
    @HostListener('mouseover', ['$event']) async onMouseHover(event: MouseEvent) {
        if (this.isVisible && this.content) {
            // Create or update the h4 element with the current content
            if (!this.span) {
                this.span = this.renderer.createElement('span');
                this.renderer.appendChild(this.element, this.span);
            }
            this.span.textContent = this.content;

            this.renderer.appendChild(this.el.nativeElement, this.element);
        }
    }

    // Hide tooltip on mouseleave, only if isVisible is true and content exists
    @HostListener('mouseleave') hideTooltip() {
        if (this.isVisible && this.content) {
            this.renderer.removeChild(this.el.nativeElement, this.element);
        }
    }
}
