<div *ngIf="points" class="table">
    <div class="header"><p>Last online</p></div>
    <div class="header"><p>Edited at</p></div>
    <div class="header">
        <p>BCU SN</p>
        <div>
            <span class="isOnline"></span>
            <small>- Is online</small>
        </div>
    </div>
    <div class="header"><p>BCAM SN</p></div>
    <div class="header"><p>Production officer</p></div>
    <div class="header"><p>First shipping address</p></div>
    <div class="header"><p>Support event</p></div>
    <div class="header"><p>Support SW</p></div>
    <div class="header"><p>IP ARD</p></div>
    <div class="header"><p>Location Country/City</p></div>
    <div class="header"><p>Vimba CAM SN</p></div>
    <div class="header"><p>PICO SN</p></div>
    <div class="header"><p>HUB image</p></div>
    <div class="header"><p>HUB SW</p></div>
    <div class="header"><p>CAM SW</p></div>
    @for (point of points; track point; let index = $index) {
    <ng-container>
        <div class="cell">
            <p>{{ point.roleProperties.lastSessionStarted ? (point.roleProperties.lastSessionStarted | date : 'dd.MM.yy H:mm:ss') : 'was not online yet' }}</p>
        </div>
        <div class="cell">
            <p>{{ point.roleProperties.editedAt | date : 'dd.MM.yy H:mm:ss' }}</p>
        </div>
        <div class="cell">
            <a [routerLink]="['../', point._id]">{{ point.username }}</a>
            @if (point.roleProperties.isOnline) {
            <span [ngClass]="point.roleProperties.isOnline ? 'isOnline' : 'isOffline'"></span>
            }
        </div>
        <div class="cell">
            <p>{{ point.roleProperties['BulbiCAM serial number'] }}</p>
        </div>
        <div class="cell">
            <p>{{ point.roleProperties['Production officer'] }}</p>
        </div>
        <div class="cell">
            <p>{{ point.roleProperties['First shipping address'] }}</p>
        </div>
        <div class="cell">
            <small>{{ point.roleProperties.supportEvent.supportOfficer }}</small>
            <small>{{ point.roleProperties.supportEvent.event }}</small>
            @for (feature of point.roleProperties.supportEvent.upgradeFeatures; track feature) {
            <small>{{ getFeatureName(feature) }}</small>
            }
        </div>
        <div class="cell">
            <p>{{ point.roleProperties['Rustdesk ID'] }}</p>
        </div>
        <div class="cell">
            <p>{{ point.roleProperties['Production IP'] }}</p>
        </div>
        <div class="cell" style="grid-auto-flow: column">
            <p>{{ point.roleProperties.Country }}</p>
            @if (point.roleProperties.Country && point.roleProperties.City) {
            <p>/</p>
            }
            <p>{{ point.roleProperties.City }}</p>
        </div>
        <div class="cell">
            <p>{{ point.roleProperties['Camera serial number'] }}</p>
        </div>
        <div class="cell">
            <p>{{ point.roleProperties['Main PCB serial number'] }}</p>
        </div>
        <div class="cell">
            <p>{{ point.roleProperties['BulbiHUB image'] }}</p>
        </div>
        <div class="cell">
            <p>{{ point.roleProperties['BulbiHUB version'] }}</p>
        </div>
        <div class="cell">
            <p>{{ point.roleProperties['BulbiCAM version'] }}</p>
        </div>
    </ng-container>
    }
</div>
