import { DEVICE } from '../../../../enum/device';
import { TEST_TYPE } from '../../../../enum/test-type';
import { TEST_STAGE } from '../../../../enum/testStage.enum';

export class PupillaryEvaluationNidekArk1sTest {
    public readonly type: TEST_TYPE = TEST_TYPE.PUPILLARY_EVALUATION;
    public readonly device: DEVICE = DEVICE.NIDEK_ARK1S;
    public readonly name: string = 'Pupil assessment';
    public stage: TEST_STAGE;
}
