<div class="menuButton">
    <i class="aico-userNormal"></i>
    <h5>
        {{ (communicationService.userSource | async)?.username | lowercase }}
    </h5>
    <i class="aico-triangleDown"></i>
</div>
<ul>
    <li [routerLink]="['/u2f-management']">
        <p>{{ 'U2F key management' }}</p>
    </li>
    <li [routerLink]="['/about']">
        <p>{{ 'about' }}</p>
    </li>
    <li (click)="communicationService.logout()">
        <p>{{ 'log out' }}</p>
    </li>
</ul>
