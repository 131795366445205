import { Component, ElementRef, forwardRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
    selector: 'datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true,
        },
    ],
})
export class DatepickerComponent implements ControlValueAccessor, OnInit {
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public maxDate: Date;
    @Input() public minDate: Date;
    @Input() public inputClass: string;
    @ViewChild('picker', { static: true }) public picker: MatDatepicker<Date>;
    // @ViewChild('picker') public picker: MatDatepicker<Date>;
    private listener: any;
    private nativeElement: any;
    private disabled: boolean = false;
    private onChange = (_: any) => {};
    private onTouched = () => {};

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
        this.nativeElement = this.elementRef.nativeElement;
    }
    ngOnInit(): void {}
    writeValue(value: Date): void {
        this.picker.select(value);
    }
    registerOnChange(fn: (_: any) => {}): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    public select(event: MatDatepickerInputEvent<Date>, stopPropagation?: boolean) {
        this.onTouched();
        if (!stopPropagation) {
            this.onChange(event.value);
        }
    }
    public open(): void {
        this.picker.open();
    }
    @HostListener('click', ['$event'])
    clickEvent(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.picker.opened) {
            this.picker.close();
        } else {
            this.picker.open();
        }
    }
}
