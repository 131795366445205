import { ScreenCalibration } from 'common-lib';
import { CommonBulbicamService } from '../../services';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';

export class ScreenCalibrationFrontend extends ScreenCalibration {
    constructor(private bulbicamService: CommonBulbicamService) {
        super();
    }

    public async saveModel(): Promise<void> {
        await firstValueFrom(this.bulbicamService.saveCalibrationResult({ test: this.type, createdAt: Date.now(), results: this.getModel() }));
    }
}
