import JSZip from 'jszip';

export class Compressor {
    public async compress(filename: string, rawData: string): Promise<string> {
        const zip = new JSZip();
        zip.file(filename, rawData);
        const data = await zip.generateAsync({
            type: 'base64',
            compression: 'DEFLATE',
            compressionOptions: {
                level: 9,
            },
        });
        return data;
    }
    public async decompress(filename: string, compressedData: string): Promise<string> {
        const zip = new JSZip();
        await zip.loadAsync(compressedData, { base64: true });
        const decompressedData = await zip.files[filename].async('string');
        return decompressedData;
    }
}
