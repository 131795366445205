import { Subject } from 'rxjs';
import { IOSocket } from './ioSocket';
import { Manager } from 'socket.io-client';
import { IScreenshotFeedback } from 'common-lib';
import { checkSocketPromise } from '../decorators/checkSocket.decorator';

export class IssuesSocket extends IOSocket {
    constructor(manager: Manager, socketError: Subject<Error>) {
        super(manager, '/issues', socketError);
    }
    public async addHendlers(resolvable: () => void): Promise<void> {}

    @checkSocketPromise()
    public findByID(id: string): Promise<IScreenshotFeedback> {
        return new Promise<IScreenshotFeedback>((res) => {
            this._socket.emit('getIssue', id, res);
        });
    }
    @checkSocketPromise()
    public getAll(): Promise<IScreenshotFeedback[]> {
        return new Promise<IScreenshotFeedback[]>((res) => {
            this._socket.emit('getAll', res);
        });
    }
    @checkSocketPromise()
    public getNextPage(skip: number, limit: number): Promise<IScreenshotFeedback[]> {
        return new Promise<IScreenshotFeedback[]>((res) => {
            this._socket.emit('getNextPage', skip, limit, res);
        });
    }
    @checkSocketPromise()
    public getRawScreenshot(id: string): Promise<string> {
        return new Promise<string>((res) => {
            this._socket.emit('getRawScreenshot', id, res);
        });
    }
}
