<div>
    <div>
        <img src="/assets/images/label/ce.png" alt="CE mark" />
        <a href="http://www.bulbitech.com/" target="_blank"> <img src="/assets/images/logo_full_light.png" alt="Company logo" /> </a>
    </div>
    <div>
        <p>Medical product:</p>
        <p>Bulbi Marketplace (dev)</p>
    </div>
    <div>
        <img src="/assets/images/label/book_i.png" alt="company logo" />
    </div>
    <div>
        <img src="/assets/images/label/plant.png" alt="company logo" />
        <div>
            <p>BulbiTech AS</p>
            <p>Krambugata 2, 7011, Trondheim, Norway</p>
            <p>+47 90 99 01 99</p>
            <p>support&#64;bulbitech.com</p>
            <p>www.bulbitech.com</p>
            <p>v.: {{ version }}</p>
        </div>
    </div>
</div>
