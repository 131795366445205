import { Observable, Subject } from 'rxjs';
import { IFrontendConfig } from '../../../../../common/interfaces/frontendConfig';
import { IOSocket } from './ioSocket';
import { Manager } from 'socket.io-client';
import { checkSocketPromise } from '../decorators/checkSocket.decorator';

export class ConfigSocket extends IOSocket {
    private _configurationsSource: Subject<IFrontendConfig>;
    constructor(manager: Manager, socketError: Subject<Error>) {
        super(manager, '/config', socketError);
        this._configurationsSource = new Subject<IFrontendConfig>();
    }
    @checkSocketPromise()
    public async addHendlers(resolvable: () => void): Promise<void> {
        this._socket.on('configurations', (configuration: IFrontendConfig) => {
            this._configurationsSource.next(configuration);
        });
    }
    public get configurationsSource(): Observable<IFrontendConfig> {
        return this._configurationsSource.asObservable();
    }
}
