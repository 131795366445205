<div class="test-container scrollable">
    <div class="test-header">{{ name }}</div>
    <div class="test-main">
        <div class="test-main-controls">
            <button type="button" class="normalButton primaryButton" (click)="toggleTest()" [disabled]="!configService.camState">
                {{ currentState !== stateList.Start ? 'Start' : 'Stop' }}
            </button>
            <ul class="test-main-list">
                <li *ngFor="let el of results; let i = index">
                    <span class="list-item" (click)="setInputValues(el, i)" [ngClass]="{ active: activeElement === i }">{{ el.createdAt | date : 'dd/MM/yyyy hh:mm' }}</span>
                    <i class="aico-noUpload cross" (click)="deleteInputResults(i)"></i>
                </li>
            </ul>
        </div>
        <div class="test-main-right">
            <div class="test-main-calibration" *ngIf="currentState !== 'init'">
                <div class="calibration-container">
                    <div class="calibration-container-left">
                        <p class="">OD</p>
                        <p class="">LUX Value</p>
                        <p class="">OS</p>
                        <p class="">LUX Value</p>
                    </div>
                    <div class="calibration-container-right">
                        <form [formGroup]="form">
                            <div class="flex" formArrayName="eyePairOD">
                                <div *ngFor="let eyePairForm of eyePairODControls; let i = index">
                                    <div class="lux-value-pair" (click)="activePairTarget = 0; changeField(i)">
                                        <div
                                            class="lux-value"
                                            [ngClass]="{ 'active-pair': activePairIndex === i && activePairTarget === 0 && currentState === 'start' }"
                                            [ngStyle]="{ 'background-color': getBGColor(rgbValues.od[i].r), color: getColor(rgbValues.od[i].r) }"
                                        >
                                            {{ rgbValues.od[i].r }}
                                        </div>
                                        <div
                                            class="lux-input-container"
                                            [formGroup]="eyePairForm"
                                            [ngClass]="{ 'active-pair': activePairIndex === i && activePairTarget === 0 && currentState === 'start' }"
                                            (click)="activePairIndex = i && (activePairTarget = 0)"
                                        >
                                            <input
                                                #luxValueOD
                                                class="smallInput lux-input"
                                                formControlName="lux"
                                                (keyup.arrowleft)="move(-1)"
                                                (keyup.arrowright)="move()"
                                                (click)="changeField(i)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="flex" formArrayName="eyePairOS">
                                <div *ngFor="let eyePairForm of eyePairOSControls; let i = index">
                                    <div class="lux-value-pair" (click)="activePairTarget = 1; changeField(i)">
                                        <div
                                            class="lux-value"
                                            [ngClass]="{ 'active-pair': activePairIndex === i && activePairTarget === 1 && currentState === 'start' }"
                                            [ngStyle]="{ 'background-color': getBGColor(rgbValues.os[i].r), color: getColor(rgbValues.os[i].r) }"
                                        >
                                            {{ rgbValues.os[i].r }}
                                        </div>
                                        <div
                                            class="lux-input-container"
                                            [formGroup]="eyePairForm"
                                            [ngClass]="{ 'active-pair': activePairIndex === i && activePairTarget === 1 && currentState === 'start' }"
                                            (click)="activePairIndex = i && (activePairTarget = 1)"
                                        >
                                            <input
                                                #luxValueOS
                                                class="smallInput lux-input"
                                                formControlName="lux"
                                                (keyup.arrowleft)="move(-1)"
                                                (keyup.arrowright)="move()"
                                                (click)="changeField(i)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="test-main-buttons" *ngIf="currentState !== 'init'">
                <button type="button" class="normalButton primaryButton" [disabled]="currentState !== stateList.Start" (click)="saveResults()">{{ 'Save' }}</button>
            </div>
            <div class="test-main-charts">
                <svg #svgChart></svg>
            </div>
        </div>
    </div>

    <button type="button" class="normalButton primaryButton" (click)="isTableOpen = !isTableOpen">{{ isTableOpen ? 'Close Table' : 'Open Table' }}</button>
    <div class="test-table" *ngIf="isTableOpen">
        <table *ngIf="tableData.length">
            <thead>
                <tr>
                    <th>RGB</th>
                    <th>OD Interpolated Lux</th>
                    <th>OS Interpolated Lux</th>
                    <th>OD Entered Lux</th>
                    <th>OS Entered Lux</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let rowData of tableData; index as i">
                    <td>{{ rowData.RGB }}</td>
                    <td>{{ rowData.odInterLux }}</td>
                    <td>{{ rowData.osInterLux }}</td>
                    <td>{{ rowData.odEnteredLux }}</td>
                    <td>{{ rowData.osEnteredLux }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="test-buttons" [ngClass]="isTableOpen ? 'test-buttons-lux' : ''">
        <button type="button" class="normalButton primaryButton" (click)="configService.gotoPreviousCalibrationTest(calibrationType)" [disabled]="isTestRunning">
            {{ 'Previous test' }}
        </button>
        <button type="button" class="normalButton secondaryButton" (click)="configService.cancelReturnFromCalibrationTest()">{{ 'Cancel / Return' }}</button>
        <button type="button" class="normalButton primaryButton" (click)="configService.gotoNextCalibrationTest(calibrationType)" [disabled]="isTestRunning">
            {{ 'Next test' }}
        </button>
    </div>
</div>
