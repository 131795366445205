// Import necessary modules and classes
import axios from 'axios';

class SmsService {
    public async sendSms(code: number, phone: string) {
        try {
            const apiUrl = 'https://n-eu.linkmobility.io/sms/send';

            const smsData = {
                source: 'Bulbitech',
                destination: phone,
                userData: code,
                platformId: 'SMSC',
                platformPartnerId: 6421,
                useDeliveryReport: false,
            };
            //     gateId: 'JBBdg3Rb',

            const auth = {
                username: 'J8AAy3fF',
                password: '$6?Ma@nO8$E*X9us',
            };

            const headers = {
                'Content-Type': 'application/json',
            };

            try {
                const response = await axios.post(apiUrl, smsData, { headers, auth });
                // console.log('SMS sent successfully:', response.data);
            } catch (error: any) {
                console.error('Error sending SMS:', error);
            }
        } catch (error) {
            throw error;
        }
    }
}

// Create an instance of the SmsAuthService class
export const smsService: SmsService = new SmsService();
