import { Component } from '@angular/core';

@Component({
    selector: '<not-found></not-found>',
    template: `
        <h2 style="font-size:50px">{{ 'page not found' }}</h2>
    `,
})
export class PageNotFoundComponent {
    constructor() {}
}
