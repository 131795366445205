// Import necessary modules and interfaces
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { ScreenshotFeedbackFrontend } from '../../classes/screenshotFeedbackFrontend.class';

@Component({
    selector: 'issue',
    templateUrl: 'issue.component.html',
    styleUrls: ['issue.component.scss'],
})
export class IssueComponent implements OnInit, OnDestroy {
    public form: FormGroup;
    // Define arrays for radiobutton options in form group
    private subscription: Subscription[] = [];
    public issue: ScreenshotFeedbackFrontend;

    constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router) {}

    ngOnInit() {
        this.issue = this.route.snapshot.data['issue'];
        this.form = this.formBuilder.group({
            BCUID: [{ value: this.issue.BCUID, disabled: true }],
            issueDescription: [{ value: this.issue.issueDescription, disabled: true }],
            images: [{ value: this.issue.images }],
            HUBversion: [{ value: this.issue.HUBversion, disabled: true }],
            CAMversion: [{ value: this.issue.CAMversion, disabled: true }],
            logs: [{ value: this.issue.logs, disabled: true }],
            user: [{ value: `${this.issue.user.roleProperties.firstName} ${this.issue.user.roleProperties.lastName}`, disabled: true }],
        });
    }

    ngOnDestroy() {
        // Unsubscribe all subscriptions on component destruction
        this.subscription.map((s) => s.unsubscribe());
    }

    public saveProtocol(i: number) {
        const protocol = this.issue.protocol[i];
        let blob = new Blob([protocol.content], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, protocol.filename);
    }

    public goBack() {
        this.router.navigate(['/dashboard-manager/issues-list'], { queryParams: { selected: this.issue._id } });
    }
}
