import { Component, Inject, OnInit } from '@angular/core';
import { CalibrationTestComponent } from '../calibration-test-component';
import { CALIBRATION_COMMAND, CALIBRATION_TEST, ICalibrationResult, IScreenCalibration } from 'common-lib';
import { CommonBulbicamService } from '../../../../services/bulbiCam.service';
import { CommonSocketService } from '../../../../services/socket.service';
import { CommonConfigService } from '../../../../services/config.service';
import { ScreenCalibrationFrontend } from '../../../../classes/calibration-tests/screen-calibration.model';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';

@Component({
    selector: 'screen-calibration',
    templateUrl: 'screen-calibration.component.html',
    styleUrls: ['screen-calibration.component.scss', '../reusable-styles.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule],
})
export class ScreenCalibrationComponent extends CalibrationTestComponent implements OnInit {
    infoList: ICalibrationResult<ScreenCalibrationFrontend>[] = [];
    selectedIndex = -1;
    buttons: { id: number; text: string; isActive: boolean; disabled: boolean }[] = [
        { id: 0, text: '1m', isActive: false, disabled: false },
        { id: 1, text: '2m', isActive: false, disabled: false },
        { id: 2, text: '3m', isActive: false, disabled: false },
        { id: 3, text: '4m', isActive: false, disabled: false },
        { id: 4, text: '5m', isActive: false, disabled: false },
        { id: 5, text: '6m', isActive: false, disabled: false },
    ];

    constructor(
        @Inject('BulbicamService') private bulbicamService: CommonBulbicamService,
        @Inject('SocketService') private socketService: CommonSocketService,
        @Inject('ConfigService') public configService: CommonConfigService,
        private route: ActivatedRoute
    ) {
        super(CALIBRATION_TEST.SCREEN_CALIBRATION);
    }

    get isButtonSelected(): boolean {
        return this.buttons.some((b) => b.isActive);
    }

    ngOnInit(): void {
        const resolvedData: {
            calibrationResults: ICalibrationResult<IScreenCalibration>[];
        } = this.route.snapshot.data['calibrationResults'];
        this.handleCalibrationTestsResults(resolvedData.calibrationResults);
    }

    customOnDestroy(): void {
        if (this.configService.camState) {
            this.stopTest();
        }
    }

    async messageHandler(data: any): Promise<void> {
        if (data.message_type === CALIBRATION_COMMAND.STOP) {
            this.selectedIndex = 0;
            this.buttons.forEach((d) => {
                d.isActive = false;
                d.disabled = false;
            });
            this.socketService.socket?.off(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);
        }
    }

    private handleCalibrationTestsResults(calibrationResults: ICalibrationResult<IScreenCalibration>[]): void {
        this.infoList = calibrationResults
            .map((result) => {
                const screenCalibrationResults = new ScreenCalibrationFrontend(this.bulbicamService);
                screenCalibrationResults.setModel(result.results!);
                result.results = screenCalibrationResults;
                return result as ICalibrationResult<ScreenCalibrationFrontend>;
            })
            .sort((a, b) => b.createdAt - a.createdAt);
    }

    async deleteInputResults() {
        await firstValueFrom(this.bulbicamService.deleteCalibrationResult(this.infoList[this.selectedIndex].test, this.infoList[this.selectedIndex].createdAt));

        const calibrationResults = await firstValueFrom(this.bulbicamService.getCalibrationResults<IScreenCalibration>([this.calibrationType]));
        this.handleCalibrationTestsResults(calibrationResults);

        if (this.infoList[this.selectedIndex]) this.selectActive(this.selectedIndex);
        else if (this.infoList[this.selectedIndex - 1]) this.selectActive(this.selectedIndex - 1);
        else this.selectActive(-1);
    }

    selectButton(buttonId: number): void {
        this.buttons.forEach((d) => {
            d.disabled = true;
        });
        this.buttons[buttonId].isActive = true;

        this.socketService.socket?.on(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);
        firstValueFrom(
            this.bulbicamService.sendCalibrationCommand({
                test: this.calibrationType,
                command: CALIBRATION_COMMAND.START,
                distance: parseInt(this.buttons[buttonId].text),
            })
        );
    }

    override async saveResults(): Promise<void> {
        const screenCalibrationResults = new ScreenCalibrationFrontend(this.bulbicamService);
        screenCalibrationResults.setModel({
            distance: parseInt(this.buttons.find((b) => b.isActive)!.text),
        });
        this.infoList.unshift({
            test: this.calibrationType,
            createdAt: new Date().getTime(),
            results: screenCalibrationResults,
        });

        await this.infoList[0]!.results!.saveModel();
        const allTests = await firstValueFrom(this.bulbicamService.getCalibrationResults<IScreenCalibration>([this.calibrationType]));
        this.handleCalibrationTestsResults(allTests);
        this.stopTest();
    }

    stopTest() {
        firstValueFrom(
            this.bulbicamService.sendCalibrationCommand({
                test: this.calibrationType,
                command: CALIBRATION_COMMAND.STOP,
            })
        );

        this.buttons.forEach((d) => {
            d.isActive = false;
            d.disabled = false;
        });

        this.socketService.socket?.off(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);
    }

    selectActive(index: number): void {
        this.selectedIndex = index;
    }
}
