<div class="test-container scrollable">
    <div class="test-header">{{ name }}</div>
    <div class="test-main">
        <div class="test-main-buttons">
            <form [formGroup]="form">
                <div>
                    <label class="radiobutton type1">
                        <input type="radio" formControlName="testType" [value]="'short'" />
                        <span></span>
                        <h5>{{ 'Short Test' }}</h5>
                    </label>
                    <label class="radiobutton type1">
                        <input type="radio" formControlName="testType" [value]="'long'" />
                        <span></span>
                        <h5>{{ 'Long Test' }}</h5>
                    </label>
                </div>
            </form>
            <div class="startWrapper" tooltip [content]="!isVposResults ? 'Virtual&nbsp;position&nbsp;calibration&nbsp;test&nbsp;is&nbsp;missing' : ''">
                <button type="button" class="normalButton primaryButton" (click)="toggleTest()" [disabled]="isStartButtonDisabled()">
                    {{ isTestRunning ? 'Stop Test' : 'Start Test' }}
                </button>
            </div>

            <div class="test-main-buttons-action">
                <button type="button" class="normalButton primaryButton" [disabled]="!isUnsavedResultExists || isSavingResults || isTestRunning" (click)="saveResults()">
                    Save Results
                </button>
                <button type="button" class="normalButton primaryButton" [disabled]="(!selectedIndex && selectedIndex !== 0) || selectedIndex < 0" (click)="deleteInputResults()">
                    Delete
                </button>
            </div>
        </div>

        <div class="test-main-tips">
            <svg #svgChart></svg>
        </div>
    </div>

    <div class="test-results">
        <div class="test-results-header">Calibration History</div>
        <div class="test-results-content">
            <div
                class="test-results-content-column"
                *ngFor="let column of results; let ind = index"
                [ngClass]="{ odd: ind % 2, selected: ind === selectedIndex }"
                (click)="selectActive(ind)"
            >
                <div class="results-row">
                    <ng-container *ngIf="column?.isTemp">
                        <span style="color: #eb5757">Temporary Result</span>
                    </ng-container>
                    <ng-container *ngIf="!column?.isTemp">
                        <div class="left">
                            <div>{{ column.createdAt | date : 'yyyy.MM.dd' }}</div>
                            <div>{{ column.createdAt | date : 'hh:mm' }}</div>
                        </div>
                    </ng-container>
                    <div class="middle" [ngStyle]="{ color: yellowColor }">OS</div>
                    <div class="right" [ngStyle]="{ color: blueColor }">OD</div>
                </div>

                <div class="results-row">
                    <div class="left">Average</div>
                    <div class="middle">{{ column.results?.osAverageValue | number : '1.0-1' }}ms</div>
                    <div class="right">{{ column.results?.odAverageValue | number : '1.0-1' }}ms</div>
                </div>

                <div class="results-row">
                    <div class="left">Min</div>
                    <div class="middle">{{ column.results?.osMinValue | number : '1.0-1' }}ms</div>
                    <div class="right">{{ column.results?.odMinValue | number : '1.0-1' }}ms</div>
                </div>

                <div class="results-row">
                    <div class="left">Max</div>
                    <div class="middle">{{ column.results?.osMaxValue | number : '1.0-1' }}ms</div>
                    <div class="right">{{ column.results?.odMaxValue | number : '1.0-1' }}ms</div>
                </div>

                <div class="results-row">
                    <div class="left">Sample Size</div>
                    <div class="middle">{{ column.test === 'SHORT_TIMING_TEST' ? 100 : 1000 }}</div>
                    <div class="right">{{ column.test === 'SHORT_TIMING_TEST' ? 100 : 1000 }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="test-buttons">
        <button type="button" class="normalButton primaryButton" (click)="configService.gotoPreviousCalibrationTest(calibrationType)" [disabled]="isTestRunning">
            {{ 'Previous test' }}
        </button>
        <button type="button" class="normalButton secondaryButton" (click)="configService.cancelReturnFromCalibrationTest()">{{ 'Cancel / Return' }}</button>
        <button type="button" class="normalButton primaryButton" (click)="configService.gotoNextCalibrationTest(calibrationType)" [disabled]="isTestRunning">
            {{ 'Next test' }}
        </button>
    </div>
</div>
