import { IScreenshotFeedback, IUser } from 'common-lib';
import { ObjectId } from 'mongodb';
import { SmartImageFrontend } from 'angular-staff';
import { FileService } from '../services/file.service';

export class ScreenshotFeedbackFrontend implements IScreenshotFeedback {
    _id: string | ObjectId | undefined;
    createdAt: number;
    BCUID: string;
    user: IUser<any>;
    logs: string;
    protocol: { filename: string; content: string; fileID?: string | undefined }[];
    issueDescription: string;
    images: SmartImageFrontend[];
    HUBversion: string;
    CAMversion: string;
    set model(model: IScreenshotFeedback) {
        this._id = model._id;
        this.createdAt = model.createdAt;
        this.BCUID = model.BCUID;
        this.user = model.user;
        this.logs = model.logs;
        this.protocol = model.protocol;
        this.issueDescription = model.issueDescription;
        this.images = model.images.map((iModel) => {
            const si = new SmartImageFrontend();
            si.model = iModel;
            return si;
        });
        this.HUBversion = model.HUBversion;
        this.CAMversion = model.CAMversion;
    }
    get model(): IScreenshotFeedback {
        return {
            _id: this._id,
            createdAt: this.createdAt,
            BCUID: this.BCUID,
            user: this.user,
            logs: this.logs,
            protocol: this.protocol,
            issueDescription: this.issueDescription,
            images: this.images.map((si) => si.model),
            HUBversion: this.HUBversion,
            CAMversion: this.CAMversion,
        };
    }

    // check if heavy data is downloaded
    public isDownloaded(): boolean {
        return this.images.filter((sif) => !sif.isDownloaded()).length === 0;
    }

    // download image content from fileService
    public async download(fileService: FileService): Promise<void> {
        try {
            // if image is already downloaded or empty, return
            if (this.isDownloaded()) return;
            if (this.isEmpty()) return;

            await Promise.all(this.images.map((sif) => sif.download(fileService)));
            await Promise.all(
                this.protocol.map(async (p) => {
                    const compressedProtocol: string = await fileService.getFileContentByFileID(p.fileID!);
                    const decompressedProtocol: string = await fileService.decompress(`CAM_Protocol_${p.filename}`, compressedProtocol);
                    p.content = decompressedProtocol;
                })
            );
        } catch (error) {
            console.log(error);
        }
    }

    private isEmpty(): boolean {
        return !this.createdAt;
    }
}
