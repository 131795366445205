<form [formGroup]="filterForm">
    <singleselect [label]="'Choose time period:'" [list]="timeRanges" [inputClass]="'normalInput'" formControlName="range"> </singleselect>
    <datepicker [label]="'From:'" [placeholder]="''" [maxDate]="maxDateForStartDatePicker" [inputClass]="'normalInput'" formControlName="startDate" #startPicker></datepicker>
    <datepicker
        [label]="'to:'"
        [placeholder]="''"
        [maxDate]="maxDate"
        [minDate]="minDateForEndDatePicker"
        [inputClass]="'normalInput'"
        formControlName="endDate"
        #endPicker
    ></datepicker>
</form>
<simple-exams-chart></simple-exams-chart>
