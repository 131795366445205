import { ObjectId } from 'mongodb';
import { ROLE } from '../enum/role';
import { IUser } from '../interfaces/user';

export abstract class User<T> implements IUser<T> {
    public _id: string | ObjectId;
    public role: ROLE;
    public username: string;
    public roleProperties: T;
    constructor() {
        this.username = '';
        this.roleProperties = {} as T;
    }
    public abstract setModel(model: IUser<T>): void;
    public abstract getModel(): IUser<T>;
    /**
     * The function for loading user's model by JWT. Working for both backend (Node JS environment) and frontend (browser envoronment)
     * Since decoding data from JWT is different for Node JS and Javascript
     * Buffer is absent in browser environment and attempt to use it will throw an error.
     * if an error will be thrown in "try" block - that means that currently code executing in browser and data from JWT will be taken by approach shown in "catch" block
     * Block "finally" will set taken user model.
     * @param token JWT
     */
    public loadWithJWT(token: string): void {
        const base64Url = token.split('.')[1],
            base64 = base64Url.replace('-', '+').replace('_', '/');
        let model!: IUser<T>;
        try {
            // for Node JS environment
            model = JSON.parse(Buffer.from(base64, 'base64').toString('binary'));
        } catch (e) {
            // for browser environment
            model = JSON.parse(
                decodeURIComponent(
                    atob(base64)
                        .split('')
                        .map(function (c) {
                            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                        })
                        .join('')
                )
            );
        } finally {
            this.setModel(model);
        }
    }
}
