import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ExaminationRepresentativeFrontend } from 'marketplace/frontend/src/app/classes/examinationRepresentativeFrontend';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';
import { TestHepler, ROLE, IExaminationRepresentative, IBulbiPointRoleProperties, ICustomerRoleProperties, IUser } from 'common-lib';
import { IDoctorExaminationsSearchTerms } from '../../../../../common/interfaces/doctorExaminationsSearchTerms';
import { DoctorPaginatorComponent } from '../doctor-paginator/doctor-paginator.component';

@Component({
    selector: 'dashboard-doctor',
    templateUrl: 'dashboard-doctor.component.html',
    styleUrls: ['dashboard-doctor.component.scss'],
})
export class DashboardDoctorComponent implements AfterViewInit, OnDestroy {
    @ViewChild(DoctorPaginatorComponent) public paginatorComponent: DoctorPaginatorComponent;
    public _examinations: Subject<ExaminationRepresentativeFrontend[]>;
    private _subscriptions: Array<Subscription>;
    private _customers: BehaviorSubject<IUser<ICustomerRoleProperties>[]>;
    private _HUBs: BehaviorSubject<IUser<IBulbiPointRoleProperties>[]>;
    public tests: (TestHepler & { count: number })[] = [];
    constructor(public communicationService: ServerCommunicationService) {
        this._examinations = new Subject<ExaminationRepresentativeFrontend[]>();
        this._subscriptions = [];
        this._customers = new BehaviorSubject<IUser<ICustomerRoleProperties>[]>([]);
        this._HUBs = new BehaviorSubject<IUser<IBulbiPointRoleProperties>[]>([]);
    }
    async ngAfterViewInit(): Promise<void> {
        this._customers.next(await this.communicationService.sockets.UserSocket.getUsers(ROLE.CUSTOMER));
        this._HUBs.next(await this.communicationService.sockets.UserSocket.getUsers(ROLE.REMOTEPOINT));
        combineLatest([this.paginatorComponent.page])
            .pipe(
                switchMap((page) => {
                    const terms: IDoctorExaminationsSearchTerms = {
                        itemsPerPage: this.paginatorComponent.itemsPerPage,
                        page: page[0],
                    };
                    return this.communicationService.sockets.ExaminationSocket.doctorsSearch(terms);
                }),
                map((searchResult: { filteredExams: IExaminationRepresentative[]; totalCnt: number }): ExaminationRepresentativeFrontend[] => {
                    this.paginatorComponent.totalItemsCnt.next(searchResult.totalCnt);
                    const exams: ExaminationRepresentativeFrontend[] = searchResult.filteredExams.map((model) => {
                        const exam: ExaminationRepresentativeFrontend = new ExaminationRepresentativeFrontend();
                        exam.model = model;
                        const customer: IUser<ICustomerRoleProperties> = this._customers.value.find((c) => c._id === exam.customerID)!;
                        exam.customerName = customer.roleProperties.firstName + ' ' + customer.roleProperties.lastName;
                        const source: IUser<IBulbiPointRoleProperties> = this._HUBs.value.find((m) => m._id === exam.sourceID)!;
                        exam.sourceName = source.username;
                        return exam;
                    });
                    return exams;
                })
            )
            .subscribe((filteredExams: ExaminationRepresentativeFrontend[]) => {
                this._examinations.next(filteredExams);
            });
        this._examinations.subscribe((exams) => {
            if (exams && exams.length > 0) {
                exams.forEach((exam) => {
                    exam.completedTests.forEach((test) => {
                        if (!this.tests.some((t) => t.type === test.type && t.device === test.device)) {
                            this.tests.push({
                                device: test.device,
                                name: test.name,
                                type: test.type,
                                stage: test.stage,
                                count: test.count,
                            });
                        }
                    });
                });
            }
            this.tests.sort((t1, t2) => {
                return (
                    ExaminationRepresentativeFrontend.deviceSorter(t1, t2) ||
                    ExaminationRepresentativeFrontend.sorterByStage(t1, t2) ||
                    ExaminationRepresentativeFrontend.customSorter(t1, t2)
                );
            });
        });
        // this._subscriptions.push(
        //     this.communicationService.sockets.ExaminationSocket.updateExaminationsRequestSource.subscribe(() => {
        //         this.paginatorComponent.page.next(this.paginatorComponent.page.value);
        //     })
        // );
    }
    ngOnDestroy(): void {
        this._subscriptions.forEach((s) => s.unsubscribe());
    }
    private getTest(test: TestHepler, examination: ExaminationRepresentativeFrontend): TestHepler & { count: number } {
        return examination.completedTests.find((t) => t.type === test.type && t.device === test.device)!;
    }
}
