import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { axisBottom, axisLeft, scaleLinear, scaleTime, select, selectAll, zoom as z } from 'd3';
import moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';
import { ROLE, SORTING, IExaminationRepresentative, IBulbiPointRoleProperties, ICustomerRoleProperties, IUser } from 'common-lib';
import { IExaminationsSearchTerms } from '../../../../../common/interfaces/examinationsSearchTerms';

@Component({
    selector: 'simple-exams-chart',
    templateUrl: 'simple-exams-chart.component.html',
    styleUrls: ['simple-exams-chart.component.scss'],
})
export class SimpleExamsChartComponent implements OnInit, OnDestroy {
    private dataByDays: {
        date: Date;
        exams: IExaminationRepresentative[];
    }[] = [];
    private readonly padding: { top: number; right: number; bottom: number; left: number } = { top: 40, right: 40, bottom: 80, left: 80 };
    private readonly minDaysCount: number = 7; // min cnt bars in chart
    private readonly barMargin: number = 10;
    private readonly minBarWidth: number = 40;
    private height: number;
    private width: number;
    private xAxisWidth: number;
    private yAxisHeigth: number;
    private barWidth: number;
    private svg: any;
    private xScale: any;
    private yScale: any;
    private gX: any;
    private gY: any;
    private xAxis: any;
    private yAxis: any;
    private canvas: any;

    private customers: IUser<ICustomerRoleProperties>[];
    public customersList: string[];
    private HUBs: IUser<IBulbiPointRoleProperties>[];
    public hubsList: string[];
    public _searchQuery: BehaviorSubject<IExaminationsSearchTerms | null>;
    public customersSelect: FormControl;
    public hubsSelect: FormControl;
    public yearsSelect: FormControl;

    private subscriptions: Subscription[];
    constructor(private elRef: ElementRef, private communicationService: ServerCommunicationService) {
        this.subscriptions = [];
        this.customers = [];
        this.customersList = [];
        this.HUBs = [];
        this.hubsList = [];
        this.customersSelect = new FormControl('All');
        this.hubsSelect = new FormControl({ value: '', disabled: true });
        this.yearsSelect = new FormControl();
        this._searchQuery = new BehaviorSubject<IExaminationsSearchTerms | null>(null);
    }
    async ngOnInit(): Promise<void> {
        this.customers = await this.communicationService.sockets.UserSocket.getUsers(ROLE.CUSTOMER);
        this.customersList = ['All'].concat(this.customers.map((m) => m.roleProperties.firstName + ' ' + m.roleProperties.lastName));
        this.HUBs = await this.communicationService.sockets.UserSocket.getUsers(ROLE.REMOTEPOINT);
        this.subscriptions.push(
            this.yearsSelect.valueChanges.subscribe(async (v: string) => {
                const year = moment().set('year', +v),
                    terms = {
                        itemsPerPage: undefined,
                        page: undefined,
                        orderBy: 'createdAt',
                        orderDirection: SORTING.ASC,
                        customerID: this.customers.find((c) => c.roleProperties.firstName + ' ' + c.roleProperties.lastName === this.customersSelect.value)?._id as string,
                        sourceID: this.HUBs.find((m) => m.username === this.hubsSelect.value)?._id as string,
                        createdAt: {
                            startRange: year.startOf('year').valueOf(),
                            endRange: year.endOf('year').valueOf(),
                        },
                    };
                this._searchQuery.next(terms);
            })
        );
        this.subscriptions.push(
            this.customersSelect.valueChanges.subscribe(async (v: string) => {
                if (v === 'All') {
                    this.hubsSelect.setValue('', { emitEvent: false });
                    this.hubsSelect.disable();
                } else {
                    this.HUBs = await this.communicationService.sockets.UserSocket.getPoints(
                        this.customers.find((m) => m.roleProperties.firstName + ' ' + m.roleProperties.lastName === v)?._id as string
                    );
                    this.hubsList = this.HUBs.map((m) => m.username);
                    this.hubsSelect.enable();
                    this.hubsSelect.setValue('All');
                }
            })
        );
        this.subscriptions.push(
            this.hubsSelect.valueChanges.subscribe((v: string) => {
                const searchQuery = this._searchQuery.value as IExaminationsSearchTerms;
                if (v === '') {
                    searchQuery.customerID = undefined;
                    searchQuery.sourceID = undefined;
                } else if (v === 'All') {
                    searchQuery.customerID = this.customers.find((c) => c.roleProperties.firstName + ' ' + c.roleProperties.lastName === this.customersSelect.value)!._id as string;
                    searchQuery.sourceID = undefined;
                } else {
                    searchQuery.customerID = this.customers.find((c) => c.roleProperties.firstName + ' ' + c.roleProperties.lastName === this.customersSelect.value)!._id as string;
                    searchQuery.sourceID = this.HUBs.find((m) => m.username === v)!._id as string;
                }
                this._searchQuery.next(searchQuery);
            })
        );
        this.subscriptions.push(
            this._searchQuery.pipe(filter((v) => !!v)).subscribe(async (terms) => {
                const res: {
                    filteredExams: IExaminationRepresentative[];
                    totalCnt: number;
                } = await this.communicationService.sockets.ExaminationSocket.search(terms as IExaminationsSearchTerms);

                let currDate = moment(terms!.createdAt.startRange),
                    lastDate = moment(terms!.createdAt.endRange);
                this.dataByDays = [];
                do {
                    let exams: IExaminationRepresentative[] = [];
                    res.filteredExams.forEach((e) => {
                        if (moment(e.createdAt).isSame(currDate, 'day')) exams.push(e);
                    });
                    let day = {
                        date: currDate.clone().toDate(),
                        exams: exams,
                    };
                    if (exams.length > 0) this.dataByDays.push(day);
                } while (currDate.add(1, 'days').diff(lastDate) < 0);

                this.xScale = scaleTime()
                    .domain([new Date(terms!.createdAt.startRange as number), new Date(terms!.createdAt.endRange as number)])
                    .range([0, +this.canvas.attr('width')]);

                this.xAxis = axisBottom(this.xScale);

                this.gX.call(this.xAxis);

                this.barWidth = this.xScale(new Date('2016-01-02')) - this.xScale(new Date('2016-01-01'));

                let bars = this.canvas
                    .selectAll('rect.bar')
                    .data(this.dataByDays)
                    .attr('class', 'bar')
                    .attr('clip-path', 'url(#clip)')
                    .attr('x', (d: { date: string | number | Date }) => {
                        return this.xScale(new Date(d.date)) - this.barWidth * 0.5;
                    })
                    .attr('width', this.barWidth)
                    .attr('height', (d: { exams: string | any[] }) => {
                        return this.yAxisHeigth - this.yScale(d.exams.length);
                    })
                    .attr('y', (d: { exams: string | any[] }) => {
                        return this.yScale(d.exams.length);
                    })
                    .style('fill', 'steelblue')
                    .style('stroke', 'blue')
                    .style('stroke-width', '1px');
                bars.enter()
                    .append('rect')
                    .attr('class', 'bar')
                    .attr('clip-path', 'url(#clip)')
                    .attr('x', (d: { date: string | number | Date }) => {
                        return this.xScale(new Date(d.date)) - this.barWidth * 0.5;
                    })
                    .attr('width', this.barWidth)
                    .attr('height', (d: { exams: string | any[] }) => {
                        return this.yAxisHeigth - this.yScale(d.exams.length);
                    })
                    .attr('y', (d: { exams: string | any[] }) => {
                        return this.yScale(d.exams.length);
                    })
                    .style('fill', 'steelblue')
                    .style('stroke', 'blue')
                    .style('stroke-width', '1px');
                bars.exit().remove();
            })
        );

        this.height = this.elRef.nativeElement.clientHeight - 10;
        this.width = this.elRef.nativeElement.clientWidth;
        this.xAxisWidth = this.width - (this.padding.left + this.padding.right);
        this.yAxisHeigth = this.height - (this.padding.top + this.padding.bottom);
        this.svg = select(this.elRef.nativeElement).append('svg').attr('width', this.width).attr('height', this.height);
        this.canvas = this.svg
            .append('g')
            .attr('id', 'canvas')
            .attr('width', this.xAxisWidth)
            .attr('height', this.yAxisHeigth)
            .attr('transform', 'translate(' + this.padding.left + ',' + this.padding.top + ')');
        this.xScale = scaleTime()
            .domain([moment().startOf('year').toDate(), moment().endOf('year').toDate()])
            .range([0, +this.canvas.attr('width')]);
        this.yScale = scaleLinear()
            .domain([20, 0])
            .range([0, +this.canvas.attr('height')]);
        let zoom = z()
            .scaleExtent([1, 50])
            .extent([
                [this.padding.left, 0],
                [this.width - this.padding.right, this.height],
            ])
            .translateExtent([
                [0, -Infinity],
                [this.width, Infinity],
            ])
            .on('zoom', this.zoomed.bind(this));
        this.xAxis = axisBottom(this.xScale);
        this.yAxis = axisLeft(this.yScale).ticks(20);
        this.canvas.append('clipPath').attr('id', 'clip').append('rect').attr('width', this.xAxisWidth).attr('height', this.yAxisHeigth);
        this.gX = this.canvas
            .append('g')
            .attr('transform', 'translate(0,' + +this.canvas.attr('height') + ')')
            .attr('class', 'axis axis--x')
            .attr('color', 'white')
            .call(this.xAxis);
        this.gY = this.canvas.append('g').attr('class', 'axis axis--y').attr('color', 'white').call(this.yAxis);
        selectAll('.axis--y > g.tick > line').attr('x2', this.xAxisWidth).style('stroke', 'lightgrey');
        this.canvas
            .append('g')
            .attr('transform', 'translate(' + -40 + ',' + +this.canvas.attr('height') / 2 + ') rotate(270)')
            .append('text')
            .attr('fill', 'white')
            .attr('font-size', '20')
            .attr('text-anchor', 'middle')
            .text('Number of examinations');
        this.svg.call(zoom);

        this.yearsSelect.setValue('2021');
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
    private zoomed(event: { transform: { rescaleX: (arg0: any) => any } }) {
        this.gX.call(this.xAxis.scale(event.transform.rescaleX(this.xScale)));

        let new_x = event.transform.rescaleX(this.xScale);

        this.barWidth = new_x(new Date('2016-01-02')) - new_x(new Date('2016-01-01'));
        this.canvas
            .selectAll('rect.bar')
            .data(this.dataByDays)
            .attr('x', (d: { date: any }) => {
                return new_x(d.date) - this.barWidth * 0.5;
            })
            .attr('width', this.barWidth);
    }
}
