import { Component, OnInit } from '@angular/core';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';
import { ICamMessage, TEST_TYPE } from 'common-lib';

@Component({
    selector: 'dummy-chart-data-overview',
    templateUrl: './dummy-chart-data-overview.component.html',
    styleUrls: ['./dummy-chart-data-overview.component.scss'],
})
export class DymmyChartDataOverviewComponent implements OnInit {
    public existingData: { testType: TEST_TYPE; createdAt: number[] }[];
    private reader: FileReader;
    public isButtonDisabled: boolean = false;
    constructor(private communicationService: ServerCommunicationService) {}
    async ngOnInit() {
        this.existingData = await this.communicationService.sockets.RemotePointSocket.showExistingChartData();
        this.reader = new FileReader();
        this.reader.addEventListener(
            'load',
            async () => {
                try {
                    const rawContent: string = this.reader.result as string;
                    let parsedContent: string = rawContent
                        .replace(/<SP>.{8}/gm, '')
                        .replace(/(\r\n|\n|\r)/gm, ',')
                        .slice(0, -1);
                    parsedContent = '{"data":[' + parsedContent + ']}';
                    const messagesArray: { data: ICamMessage[] } = JSON.parse(parsedContent),
                        fileName: string = `${messagesArray.data[0].chartTypeString}-${messagesArray.data[0].timestamp.toString().slice(0, -4)}.chartdata`;
                    this.isButtonDisabled = true;
                    let responce = await this.communicationService.sockets.RemotePointSocket.uploadChartData(fileName, parsedContent);
                    console.log(responce);
                    this.existingData = await this.communicationService.sockets.RemotePointSocket.showExistingChartData();
                    this.isButtonDisabled = false;
                } catch (error) {
                    console.log('Error parsing log from CAM.', error);
                }
            },
            false
        );
    }
    public async delete(testType: TEST_TYPE, chart: number): Promise<void> {
        await this.communicationService.sockets.RemotePointSocket.deleteChartData(testType, chart);
        this.existingData = await this.communicationService.sockets.RemotePointSocket.showExistingChartData();
    }

    public onFileSelected(event: any) {
        const file: File = event.target.files[0];
        if (file) {
            this.reader.readAsText(file);
        }
    }
}
