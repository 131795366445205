<form [formGroup]="form" novalidate>
    <div class="inputWrapper">
        <label>{{ 'username (email)' }}*</label>
        <input class="smallInput" type="email" formControlName="email" />
    </div>
    <label class="error" *ngIf="form.get('email')?.errors?.['required']">Email is required.</label>
    <label class="error" *ngIf="form.get('email')?.errors?.['email']">Please enter a valid email address.</label>
    <div>
        <button class="normalButton primaryButton" (click)="goBack()">{{ 'go back' }}</button>
        <button class="normalButton primaryButton" (click)="restore()" [disabled]="form.pristine || form.invalid || form.pending">{{ 'send invitation' }}</button>
    </div>
</form>
<div class="logo"></div>
